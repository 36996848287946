import React from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import styles from './FormInput.module.scss';

const FormInput = (props) => {

    const { name, label, value, events, selectable, onBlur, validation, validated } = props;

    const {
        input,
        input__label,
        input__label__error
    } = styles;

    const indentifier = label.replace(/['\s]/g, '');

    const input__label__classes = [input__label];

    if(validation && !validated) input__label__classes.push(input__label__error);

    return (
        <FormControl className={input}>
            <InputLabel className={String(input__label__classes).split(',').join(' ')} htmlFor={`${indentifier}-input`}>
                {label || ''}
            </InputLabel>
            <Input
                name={name || ''}
                error={validation && !validated}
                id={name || `${indentifier}-input`}
                value={value || ''}
                onChange={events}
                onBlur={onBlur || events}
                disabled={selectable}
                onClick={(e) => e.stopPropagation()}
            />
        </FormControl>
    );
}

export default FormInput;