import types from '../actions/types';

const initialState = {
    isLoading: false,
    list: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.payment.START_LOADING:
            return {
                ...state,
                isLoading: true
            }

        case types.payment.END_LOADING:
            return {
                ...state,
                isLoading: false
            }

        case types.plan.FETCH_PLANS:
            return {
                ...state,
                list: action.payload.data
            }

        case types.plan.SIGNOUT_USER:
            return initialState;

        default:
            return state;
    }
}