import types from "../actions/types";
import { store } from "../store";

const initialState = {
  list: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.message.SHOW_MESSAGE:
      setTimeout(() => {
        store.dispatch({ type: "CLEAN_MESSAGS" });
      }, 2000);
      return {
        list: [...state.list, action.payload.data],
      };
    case "CLEAN_MESSAGS":
      return initialState;

    case types.message.SIGNOUT_USER:
      return initialState;

    default:
      return state;
  }
};
