import types from '../actions/types';

const initialState = {
    count: 0,
    limit: 5,
    offset: 0,
    isLoading: false,
    list: [],
    card: {},
    searchResults: {
        count: 0,
        limit: 5,
        offset: 0,
        list: []
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.payment.START_LOADING:
            return {
                ...state,
                isLoading: true
            }

        case types.payment.END_LOADING:
            return {
                ...state,
                isLoading: false
            }

        case types.payment.CREATE_PAYMENT_CARD:
            return {
                ...state,
                card: action.payload.data
            };

        case types.payment.FETCH_PAYMENT_CRAD:
            return {
                ...state,
                card: {
                    ...action.payload.data
                }
            };

        case types.payment.FETCH_PAYMENTS_HISTORY:
            return {
                ...state,
                count: action.payload.data.count,
                limit: action.payload.data.limit,
                offset: action.payload.data.offset,
                searchResults: initialState.searchResults,
                list: [
                    ...state.list,
                    ...action.payload.data.list
                ]
            }

        case types.payment.FETCH_SEARCH_RESULTS:
            return {
                ...state,
                searchResults: {
                    count: action.payload.data.count,
                    limit: action.payload.data.limit,
                    offset: action.payload.data.offset,
                    list: [
                        ...state.searchResults.list,
                        ...action.payload.data.list
                    ]
                }
            }

        case types.payment.DELETE_SEARCH_RESULTS:
            return {
                ...state,
                searchResults: initialState.searchResults
            }

        case types.payment.SIGNOUT_USER:
            return initialState;

        default:
            return state;
    }
}