import React, { Fragment, Component } from 'react';
import { Grid, MenuItem, IconButton, Menu } from '@material-ui/core';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state/index';
import { Link, withRouter } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import KeyboardArrowRightOutlined from '@material-ui/icons/KeyboardArrowRightOutlined';
import CheckCircleOutlined from '@material-ui/icons/CheckCircleOutlined';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { businessFetcher, currentBusinessFetcher } from '../../../actions/businessActions';
import { Preloader } from '../index';
import history from '../../../helpers/history';
import styles from './Pathbar.module.scss';

class Pathbar extends Component {

    componentDidMount = () => {
        this.initialBusinessHandler();
    }

    businessSelector = (popupState, id) => {
        this.props.currentBusinessFetcher(id);
        popupState.close();
        history.push(`/${id}/requests/`);
    }

    initialBusinessHandler = () => {
        const pathName = this.props.history.location.pathname;
        if (pathName !== '/businesses' && pathName !== '/businesses/') {
            const limit = 10;
            this.props.businessFetcher(limit, 0);
        }
    }

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop <= Number(e.target.clientHeight);
        this.handleRequests(bottom);
    }

    handleRequests = (bottom = true) => {
        const limit = 10;
        const nextOffset = this.props.offset + this.props.limit;
        const remaining = this.props.count - this.props.businesses.length;
        if (bottom && !this.props.isLoading && remaining > 0) {
            this.props.businessFetcher(limit, nextOffset);
        }
    }

    renderBusinesses = (popupState, currentBusiness, businesses) => {
        const {
            dropdown__item,
            dropdown__link,
            dropdown__checked
        } = styles;

        if (businesses.length) {
            return businesses.map(business => {
                return <MenuItem key={business.id + business.name} onClick={() => this.businessSelector(popupState, business.id)} className={dropdown__item}>
                    <div className={dropdown__link}>
                        {business.id === currentBusiness.id ? <CheckCircleOutlined className={dropdown__checked} /> : null}
                        {business.name}
                    </div>
                </MenuItem>;
            })
        }
    }

    render() {

        const {
            bread__home,
            bread__dropdown,
            bread__icon,
            dropdown,
            dropdown__preloader
        } = styles;

        const { isLoading, businesses, currentBusiness } = this.props;

        return (

            <Grid>
                <Link className={bread__home} to="/businesses/">
                    <HomeIcon className={bread__icon} />
                    Businesses
                    {Object.keys(currentBusiness).length ? <KeyboardArrowRightOutlined className={bread__icon} /> : null}
                </Link>
                {Object.keys(currentBusiness).length ?
                    <PopupState variant="popover">
                        {popupState => (
                            <Fragment>
                                <IconButton {...bindTrigger(popupState)} className={bread__dropdown}>
                                    {Object.keys(currentBusiness).length ? currentBusiness.name : 'Select a business'}
                                </IconButton>
                                <Menu {...bindMenu(popupState)} className={dropdown} onScroll={this.handleScroll}>
                                    {this.renderBusinesses(popupState, currentBusiness, businesses)}
                                    {isLoading ?
                                        <MenuItem className={dropdown__preloader}>
                                            <Preloader size="20px" />
                                        </MenuItem> : ''}
                                </Menu>
                            </Fragment>
                        )}
                    </PopupState> : null}
            </Grid>
        )
    }
}

const mapStateToProps = ({ businesses }) => {
    return {
        isLoading: businesses.isLoading,
        count: businesses.count,
        limit: businesses.limit,
        offset: businesses.offset,
        businesses: businesses.list,
        currentBusiness: businesses.currentBusiness,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        businessFetcher,
        currentBusinessFetcher
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Pathbar));