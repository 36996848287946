import React, { Fragment } from 'react';
import styles from './AgentsTable.module.scss';
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Paper, Tooltip, IconButton, Menu, MenuItem, RootRef } from '@material-ui/core';
import { AgentTableEditRow, Preloader, Modal, LoadMoreButton } from '../index';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state/index';
import MoreHorizOutlined from '@material-ui/icons/MoreHorizOutlined';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { agentUpdater, agentRemover } from '../../../actions/agentActions';
import { messageFetcher } from '../../../actions/messageActions';
import { renderLoadMoreButton } from '../../../helpers/tableHelpers';

let counter = 0;
function createData(firstName, lastName, email) {
	counter += 1;
	return { id: counter, firstName, lastName, email };
}

function desc(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function stableSort(array, cmp) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
	{ id: 'firstName', numeric: false, label: 'First Name' },
	{ id: 'lastName', numeric: false, label: 'Last Name' },
	{ id: 'email', numeric: false, label: 'Email' }
];

class EnhancedTableHead extends React.Component {
	createSortHandler = property => event => {
		this.props.onRequestSort(event, property);
	};

	render() {
		const { order, orderBy } = this.props;

		const { table__th } = styles;

		return (
			<TableHead>
				<TableRow>
					{rows.map(
						row => (
							<TableCell
								key={row.id}
								align={row.numeric ? 'right' : 'left'}
								padding={row.disablePadding ? 'none' : 'default'}
								sortDirection={orderBy === row.id ? order : false}
								className={table__th}
							>
								<Tooltip
									title="Sort"
									placement={row.numeric ? 'bottom-end' : 'bottom-start'}
									enterDelay={300}
								>
									<TableSortLabel
										active={orderBy === row.id}
										direction={order}
										onClick={this.createSortHandler(row.id)}
									>
										{row.label}
									</TableSortLabel>
								</Tooltip>
							</TableCell>
						),
						this,
					)}
					<TableCell></TableCell>
				</TableRow>
			</TableHead>
		);
	}
}

class AgentsTable extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			currentAgent: '',
			editable: '',
			deletable: false,
			order: 'asc',
			orderBy: 'amount',
			selected: [],
			page: 0,
			rowsPerPage: 0,
		}
		window.addEventListener('scroll', this.handleScroll);
		this.agentRow = React.createRef();
	}

	componentDidMount = () => {
		this._isMounted = true;
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount = () => {
		window.removeEventListener('scroll', this.handleScroll);
		this._isMounted = false;
	}

	handleScroll = () => {
		if (this.agentRow.current) {
			const bottom = this.agentRow.current.lastChild.getBoundingClientRect().bottom - 70 <= window.innerHeight;
			this.props.scrollCalculator(bottom);
		}
	}

	menuActionsHandler = (type = '', popupState = '', currentAgent = '') => {
		switch (type) {
			case 'edit':
				this.setState({
					editable: currentAgent.id
				})
				break;

			case 'deletable':
				this.setState({
					currentAgent,
					deletable: !this.state.deletable
				});
				break;

			default:
				this.setState({
					editable: ''
				})
		}
		if (popupState) popupState.close();
	}

	agentUpdateHandler = (businessId, agent, agents, data) => {
		const customerIndex = agents.indexOf(agent);
		const restAgents = agents.filter(item => item.id !== agent.id);
		this.props.agentUpdater(businessId, agent.id, restAgents, customerIndex, data);
	}

	agentRemoveHandler = (businessId, agentId, agents) => {
		const restAgents = agents.filter(item => item.id !== agentId);
		this.props.agentRemover(businessId, agentId, restAgents, this.props.count - 1);
		this.setState({
			deletable: !this.state.deletable
		})
	}

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = 'desc';

		if (this.state.orderBy === property && this.state.order === 'desc') {
			order = 'asc';
		}

		this.setState({ order, orderBy });
	};

	handleClick = (event, id) => {
		const { selected } = this.state;
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		this.setState({ selected: newSelected });
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value });
	};

	isSelected = id => this.state.selected.indexOf(id) !== -1;

	render() {

		const { currentAgent, deletable, order, orderBy, selected, rowsPerPage, page } = this.state;

		const { isLoading, agents, searchResults, count, searchResultsCount, currentBusiness, inSearch, validSearchTerm, messageFetcher } = this.props;

		const { table, table__row, table__td, table__dropdown__icon, table__dropdown__item } = styles;

		let agentsToShow = agents;

		if (inSearch) agentsToShow = searchResults;

		const emptyRows = rowsPerPage - Math.min(rowsPerPage, agentsToShow.length - page * rowsPerPage);

		return (
			<Paper className={table}>
				<PopupState variant="popover">
					{popupState => (
						<Modal
							open={deletable}
							title="Agent Deletion"
							text={`Deleting a agent means you can not bring it back! Are you sure you want to delete agent "${currentAgent.first_name} ${currentAgent.last_name}"?`}
							confirm="Yes, Delete Agent"
							cancel="No, Get back"
							confirmAction={() => this.agentRemoveHandler(currentBusiness.id, currentAgent.id, agents)}
							cancelAction={() => this.menuActionsHandler('deletable', popupState)} />
					)}
				</PopupState>
				<div >
					<Table aria-labelledby="tableTitle">
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={this.handleSelectAllClick}
							onRequestSort={this.handleRequestSort}
							rowCount={agentsToShow.length}
						/>
						<TableBody>
							{stableSort(agentsToShow, getSorting(order, orderBy))
								// This slice function is for using rowsPerPage state to limit the results
								// I commented for the future
								// .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map(agent => {
									const isSelected = this.isSelected(agent.id);
									return (
										<Fragment key={agent.id}>
											{Number(this.state.editable) !== Number(agent.id) ?
												<RootRef rootRef={this.agentRow}>
													<TableRow
														hover
														onClick={event => this.handleClick(event, agent.id)}
														aria-checked={isSelected}
														tabIndex={-1}
														selected={isSelected}
														className={table__row}>
														<TableCell className={table__td} scope="row">
															{agent.first_name}
														</TableCell>
														<TableCell className={table__td}>
															{agent.last_name}
														</TableCell>
														<TableCell className={table__td}>
															{agent.email}
														</TableCell>
														<TableCell className={table__td}>
															<PopupState variant="popover">
																{popupState => (
																	<Fragment>
																		<IconButton {...bindTrigger(popupState)} className={table__dropdown__icon}>
																			<MoreHorizOutlined />
																		</IconButton>
																		<Menu {...bindMenu(popupState)}>
																			<MenuItem className={table__dropdown__item} onClick={() => this.menuActionsHandler('edit', popupState, agent)}>
																				Edit
																		</MenuItem>
																			{/* <MenuItem className={table__dropdown__item} onClick={() => this.menuActionsHandler('deletable', popupState, agent)}>
																				Delete
                                                                    </MenuItem> */}
																		</Menu>
																	</Fragment>
																)}
															</PopupState>
														</TableCell>
													</TableRow>
												</RootRef> :
												<AgentTableEditRow
													first_name={agent.first_name}
													last_name={agent.last_name}
													email={agent.email}
													messageFetcher={messageFetcher}
													close={() => this.menuActionsHandler(PopupState)}
													update={(data) => this.agentUpdateHandler(currentBusiness.id, agent, agents, data)}
												/>}
										</Fragment>
									);
								})}
							{isLoading ? <TableRow>
								<TableCell className={table__td} scope="row" colSpan={4}>
									<Preloader float />
								</TableCell>
							</TableRow> : null}
							{!isLoading && !agentsToShow.length ? <TableRow>
								<TableCell className={table__td} scope="row" colSpan={4}>
									{inSearch ? (validSearchTerm ? 'No agent(s) found for this search.' : 'Please enter 3 characters at least to start searcing...') : 'You have no agents added for your business.'}
								</TableCell>
							</TableRow> : null}
							{emptyRows > 0 && (
								<TableRow style={{ height: 49 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
							{renderLoadMoreButton(agents, searchResults, count, searchResultsCount, inSearch, validSearchTerm, isLoading, isLoading,
								<TableRow className={table__row}>
									<TableCell className={table__td} scope="row" colSpan={5}>
										<LoadMoreButton clickHandler={this.props.scrollCalculator} isTable={true} />
									</TableCell>
								</TableRow>)}
						</TableBody>
					</Table>
				</div>
			</Paper>
		);
	}
}

const mapStateToProps = ({ agents }) => {
	return {
		count: agents.count,
		searchResultsCount: agents.searchResults.count,
		isLoading: agents.isLoading
	}
}

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		agentUpdater,
		agentRemover,
		messageFetcher
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentsTable);