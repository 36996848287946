import React, { Component } from 'react';
import { Grid, Paper, Tooltip, Zoom, Button, IconButton } from '@material-ui/core';
import { FormInput } from '../../common';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { customerCreator } from "../../../actions/customerActions";
import { messageFetcher } from '../../../actions/messageActions';
import styles from './RequestSingleContactZone.module.scss';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import CheckCircleOutlined from '@material-ui/icons/CheckCircleOutlined';

class SingleContactZone extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                first_name: '',
                last_name: '',
                email: ''
            },
            hasError: []
        }
    }

    inputHandler = (fieldName, fieldLabel, e, validationType) => {
        const inputValue = e.target ? e.target.value : e;
        this.setState({
            data: {
                ...this.state.data,
                [fieldName]: inputValue
            }
        }, () => {
            this.inputErrorHandler(fieldName, fieldLabel, inputValue, validationType)
        })
    }

    inputErrorHandler = (fieldName, fieldLabel, inputValue, validationType) => {
        const { messageFetcher } = this.props;
        const hasError = [];
        validationType.map(validationItem => {
            switch (validationItem.type) {
                case 'required':
                    if(!inputValue.length) {
                        messageFetcher('warning', `${fieldLabel} Could not be empty!`);
                        hasError.push({ type: validationItem.type, field: fieldName });
                    }
                    break;

                    case 'phone':
                        const numberRegex = /[+]?(\d{1,4}(\s|-)?)?(((\(\d{2,4}\)|\d{2,4})(\s|-)?){3}|(\d{5,10}))/;
                        if (!numberRegex.test(inputValue)) {
                            messageFetcher('warning', `Please enter a valid ${fieldLabel}!`);
                            hasError.push({ type: validationItem.type, field: fieldName });
                        }
                        break;

                    case 'email':
                        const emailRegex = /^[a-zA-Z0-9.!#$%&’+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/;
                        if(!emailRegex.test(inputValue)) {
                            hasError.push({ type: validationItem.type, field: fieldName });
                        }
                        break;

                    case 'minLength':
                        if(inputValue.length < validationItem.value) {
                            messageFetcher('warning', `Min characters for ${fieldLabel} is ${validationItem.value}`);
                            hasError.push({ type: validationItem.type, field: fieldName });
                        } 
                        break;

                    case 'maxLength':
                        if(inputValue.length > validationItem.value) {
                            messageFetcher('warning', `Max characters for ${fieldLabel} is ${validationItem.value}`);
                            hasError.push({ type: validationItem.type, field: fieldName });
                        } 
                        break;          
            
                default:
                    break;
            }
        })
        const oldHasError = this.state.hasError.filter(errorItem => errorItem.field !== fieldName)
        this.setState({ hasError: [...oldHasError, ...hasError] })
    }

    customerCreatorHandler = (currentBusiness) => {
        const {
            first_name,
            last_name,
            email
        } = this.state.data;

        const data = { first_name, last_name, email }
        this.setState({
            ...this.state,
            data: {
                first_name: '',
                last_name: '',
                email: ''
            }
        }, () => {
            this.props.customerCreator(currentBusiness.id, data, this.props.count + 1)
            this.props.addContactHandler(false, 'addContactMode')
        })
    }

    render() {

        const { card__iconsContainer, card__icon, card__icon__save, card__icon__disabled, closeIcon } = styles;

        const { active, events, isAgent, addContactMode, addContactHandler, currentBusiness, customers } = this.props;

        const { first_name, last_name, email } = this.state.data;

        const { hasError } = this.state;

        return (
            <div className={styles.listZoneBase}>
                <Paper
                    className={active ? [styles.listZonePaper, styles.active].join(' ') : styles.listZonePaper}>
                    <Grid container spacing={8} className={styles.listZone}>
                        {addContactMode ? <Grid item md={12} className={card__iconsContainer}>
                            <IconButton
                                className={card__icon}
                                onClick={() => addContactHandler(false, 'addContactMode')}>
                                <CloseOutlined className={closeIcon} />
                            </IconButton>
                            <IconButton
                                className={(first_name.length && last_name.length && email.length) && !hasError.length ? card__icon : String([card__icon, card__icon__disabled]).split(',').join(' ')}
                                disabled={(!first_name.length || !last_name.length || !email.length) || hasError.length ? true : false}
                                onClick={() => this.customerCreatorHandler(currentBusiness)}>
                                <CheckCircleOutlined className={card__icon__save} />
                            </IconButton>
                        </Grid> : null}
                        <Grid item md={6}>
                            <FormInput
                                label="First Name"
                                value={first_name}
                                validation
                                validated={hasError.find(item => item.field == 'first_name') ? false : true}
                                events={(e) => this.inputHandler('first_name', 'First Name', e, [{ type: 'required' }, { type: 'maxLength', value: 30 }])} />
                        </Grid>
                        <Grid item md={6}>
                            <FormInput
                                label="Last Name"
                                value={last_name}
                                validation
                                validated={hasError.find(item => item.field == 'last_name') ? false : true}
                                events={(e) => this.inputHandler('last_name', 'Last Name', e, [{ type: 'required' }, { type: 'maxLength', value: 150 }])} />
                        </Grid>
                        {/* <Grid item md={12}>
                            <Tooltip TransitionComponent={Zoom} placement="top" title="This feature is disabled for now, we will add it in the future.">
                                <FormInput
                                    label="Mobile"
                                    value={mobile}
                                    selectable={true}
                                    events={(e) => this.inputHandler(e, 'mobile')} />
                                </Tooltip>
                        </Grid> */}
                        {!isAgent ?
                            <Grid item md={12}>
                                <FormInput
                                    label="Email"
                                    value={email}
                                    validation
                                    validated={hasError.find(item => item.field == 'email') ? false : true}
                                    onBlur={() => hasError.find(item => item.field == 'email') ? this.props.messageFetcher('warning', `Please enter a valid Email!`) : null}
                                    events={(e) => this.inputHandler('email', 'Email', e, [{ type: 'required' }, { type: 'email' }, { type: 'maxLength', value: 254 }])} />
                            </Grid> : ''}
                    </Grid>
                </Paper>
            </div>
        );
    }
}

const mapStateToProps = ({ businesses, customers }) => {
    return {
        currentBusiness: businesses.currentBusiness,
        customers: customers.list,
        count: customers.count
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        customerCreator,
        messageFetcher
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleContactZone);