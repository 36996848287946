import * as Cookies from "js-cookie";
import axios from "axios";
import history from "../helpers/history";

export const apiValues = {
  keys: {
    // development keys
    // STRIPE_KEY: "pk_test_PYiHOlZDJHblf6i3zpxhauDr",
    // GOOGLE_CLIENT_ID:
    //   "119388317487-81nntojquq0a1e514k21c040f9h19803.apps.googleusercontent.com",
    // GOOGLE_MAP_KEY: "AIzaSyCFbc2u28GDV4RBG_lBGp9EUlN5YQf90Bc",
    // GOOGLE_PLACES_KEY: "AIzaSyAyphEg6Ezze8PuQwfcVAn9e8S56BlEQM8",

    // production keys
    STRIPE_KEY: "pk_live_EKrizbiNpl76GqbTIqGkK2V0",
    GOOGLE_CLIENT_ID:
      "119388317487-2gl06u2qv6spibufed8ra3kq9tv9tq2g.apps.googleusercontent.com",
    GOOGLE_MAP_KEY: "AIzaSyBO8NlLAsSNpLhzttPkCnFmy3sy8FDwCn4",
    GOOGLE_PLACES_KEY: "AIzaSyA-TGRpE-22D9V1XT2siSP-3ZFvQ4jqyFI"
  },
  url: {
    // API_URL: "https://staging.upperly.net/api", // development api url
    API_URL: "https://panel.upperly.net/api", // production api url
    AUTH_TOKEN: "/auth/google-verify/",
    PROFILE_URL: "/profile/",
    PLANS_URL: "/plans/",
    BUSINESSES_URL: "/businesses/",
    BUSINESS_UPDATE_URL: "/info/",
    PAYMENT_INFO: "/payment-method/",
    PAYMENTS: "/payments/",
    CUSTOMERS: "/customers/",
    CUSTOMERS_BULK: "/customers-bulk/",
    REQUESTS: "/requests/",
    AGENTS: "/agents/",
    FEEDBACKS: "/feedback/"
  },
  cookies: {
    AUTH_COOKIE_METHOD: "JWT",
    AUTH_COOKIE: "upperly_auth"
  }
};

class api {
  // This function gets the app auth cookie
  // from the API server
  static getApiCookie = () => {
    return `${apiValues.cookies.AUTH_COOKIE_METHOD} ${Cookies.get(
      apiValues.cookies.AUTH_COOKIE
    )}`;
  };

  // this function sets the auth cookies
  static setApiCookie = cookie => {
    Cookies.set(apiValues.cookies.AUTH_COOKIE, cookie);
  };

  // This function redirects user if API status is 200
  static redirect = url => {
    history.push(url);
  };

  // This function is for returning Promises from API
  // and used in app api calls.
  // static fetchUrl = (url, options) => {
  //     return new Promise((resolve, reject) => {
  //         axios.request(options)
  //             .then(response => response.json())
  //             .then(data => {
  //                 console.log('data', data)
  //                 resolve(data);
  //                 if (Number(data.status) === 402) history.push('/payment/add/')
  //                 if (Number(data.status) === 401) history.push('/');
  //                 if (Number(data.status) >= 400) reject(data);
  //                 // console.log('resolved data:', data)
  //             })
  //             .catch(err => {
  //                 reject(err);
  //                 console.log('error:', err)
  //             });
  //     });
  // }
  static fetchUrl = options => {
    return new Promise((resolve, reject) => {
      axios
        .request(options)
        .then(res => {
          resolve(res);
          // if (Number(data.status) === 402) history.push('/payment/add/')
          // if (Number(data.status) >= 400) reject(data);
          // console.log('resolved data:', data)
          if (Number(res.data.status) === 401) history.push("/");
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  static deleteFetchUrl = options => {
    return new Promise((resolve, reject) => {
      axios
        .request(options)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          // console.log('error:', err)
          reject(err);
        });
    });
  };

  // This function posts to API with given data
  static postAuth = (url, data) => {
    const options = {
      method: "POST",
      url: url,
      data: data,
      mode: "cors",
      credentials: "same-origin",
      validateStatus: status => {
        return status >= 200 && status < 500;
      },
      headers: {
        "Content-Type": "application/json"
      }
    };
    return this.fetchUrl(options);
  };

  // This function posts to API with given data
  static post = (url, data, onUploadProgress) => {
    const options = {
      method: "POST",
      url: url,
      data: data,
      mode: "cors",
      credentials: "same-origin",
      validateStatus: status => {
        return status >= 200 && status < 500;
      },
      onUploadProgress: progress => {
        if (onUploadProgress) onUploadProgress(progress);
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: this.getApiCookie()
      }
    };
    return this.fetchUrl(options);
  };

  // This function fetches data from API with the given url
  static get = url => {
    const options = {
      method: "GET",
      url: url,
      mode: "cors",
      credentials: "same-origin",
      validateStatus: status => {
        return status >= 200 && status < 500;
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: this.getApiCookie()
      }
    };
    return this.fetchUrl(options);
  };

  static getUnauth = url => {
    const options = {
      method: "GET",
      url: url,
      mode: "cors",
      credentials: "same-origin",
      validateStatus: status => {
        return status >= 200 && status < 500;
      },
      headers: {
        "Content-Type": "application/json"
      }
    };
    return this.fetchUrl(options);
  };

  // This function patches to API with given data
  static patch = (url, data) => {
    const options = {
      method: "PATCH",
      url: url,
      data: data,
      mode: "cors",
      credentials: "same-origin",
      validateStatus: status => {
        return status >= 200 && status < 500;
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: this.getApiCookie()
      }
    };
    return this.fetchUrl(options);
  };

  // This function patches to API with given data
  static delete = url => {
    const options = {
      method: "DELETE",
      url: url,
      mode: "cors",
      credentials: "same-origin",
      validateStatus: status => {
        return status >= 200 && status < 500;
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: this.getApiCookie()
      }
    };
    return this.deleteFetchUrl(options);
  };
}

export default api;
