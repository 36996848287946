
import Dropzone from 'react-dropzone';
import React, { Component } from 'react';
import { ButtonBase, Paper, Typography } from '@material-ui/core';
import { UploadProgress, Preloader } from '../index';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { customerCreator, customerPreloaderStarter, customerPreloaderFinisher } from "../../../actions/customerActions";
import { messageFetcher } from "../../../actions/messageActions";
import styles from './ContactDropZone.module.scss';
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import CheckCircleOutlined from '@material-ui/icons/CheckCircleOutlined';
import * as Papa from 'papaparse';
import api, { apiValues } from '../../../actions/api';
import CSV from '../../../helpers/csvHelpers';

class ContactDropZone extends Component {

	constructor(props) {
		super(props);

		this.onDrop = (files) => {
			this.setState({ files })
			this.csvParseHandler(files);
		}

		this.state = {
			files: [],
			uploadSteps: 0
		}
	}

	componentWillReceiveProps = (props) => {
		if (this.props.uploadSteps) {
			this.setState({
				uploadSteps: this.props.uploadSteps
			});
		}
	}


	onUploadProgress = (progress) => {
		this.setState({
			uploadSteps: Math.round((progress.loaded * 100) / progress.total)
		});
	}

	csvUploader = (csvList) => {
		if (!this.props.confirmable) {
			this.props.customerPreloaderStarter();
			api.post(apiValues.url.API_URL + apiValues.url.BUSINESSES_URL + this.props.currentBusiness.id + apiValues.url.CUSTOMERS_BULK, csvList, this.onUploadProgress)
				.then(res => {
					try {
						const customersId = [];
						res.data.content.map(customer => {
							customersId.push(customer.id);
						});
						this.props.events(customersId);
						this.props.customerPreloaderFinisher();
					} catch {
						this.props.customerPreloaderFinisher();
						this.props.messageFetcher(res.data.status, 'Error happend!')
					}
				})
				.catch(err => {
					this.props.customerPreloaderFinisher();
					this.props.messageFetcher('error', 'Error happend!')
				});

		} else {
			this.props.events(csvList);
		}
	}

	csvParseHandler = (file) => {
		const csvList = [];
		return Papa.parse(file[0], {
			step: (results) => {
				const user = results.data[0];
				return CSV.validation(user, 3, csvList);
			},
			complete: async () => {
				await this.csvUploader(csvList);
				this.props.messageFetcher(csvList.length ? 'info' : 'warning', `${csvList.length || `No`} ${csvList.length > 1 ? `Customers` : `Customer`} exported from your CSV file.`)
			}
		});
	}

	render() {

		const { active, customersIsPosting } = this.props;

		const { uploadSteps } = this.state;

		const files = this.state.files.map(file => (
			<li key={file.name}>
				<b>File:</b> {file.name} - {file.size} bytes
			</li>
		));

		const { dropZonePaper, dropzone__paper__success, dropZoneBase, dropZone, dropZonePaperText, dropZonePaperList,
			dropZonePaperIcon, dropzone__icon__sucess } = styles;

		const dropzone__paper_classes = [dropZonePaper];

		if (uploadSteps === 100 && !customersIsPosting) dropzone__paper_classes.push(dropzone__paper__success);

		return (
			<Dropzone onDrop={this.onDrop}>
				{({ getRootProps, getInputProps }) => (
					<ButtonBase className={active ? [dropZoneBase, styles.active].join(' ') : dropZoneBase}>
						<Paper
							className={String(dropzone__paper_classes).replace(',', ' ')}>
							<div {...getRootProps()} className={dropZone}>
								{active ? <input {...getInputProps()} /> : ''}
								<div className={dropZonePaperText}>
									{active && customersIsPosting ? <Preloader float size="33px" /> : (uploadSteps !== 100 ? <CloudUploadOutlined className={dropZonePaperIcon} /> : <CheckCircleOutlined className={dropzone__icon__sucess} />)}
									{uploadSteps === 0 ? `Drag a file here or Click to upload` : (uploadSteps === 100 && !customersIsPosting ? `Done!` : `${uploadSteps}% In progress...`)}
								</div>
								<ul className={dropZonePaperList}>
									{files}
								</ul>
								{active && customersIsPosting ? <UploadProgress steps={this.props.uploadSteps || uploadSteps} /> : ''}
							</div>
						</Paper>
					</ButtonBase>
				)}
			</Dropzone>
		);
	}
}

const mapStateToProps = ({ businesses, customers }) => {
	return {
		currentBusiness: businesses.currentBusiness,
		customers: customers.list,
		customersIsLoading: customers.isLoading,
		customersIsPosting: customers.isPosting
	}
}

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		customerCreator,
		customerPreloaderStarter,
		customerPreloaderFinisher,
		messageFetcher
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactDropZone);