import React, { Fragment } from 'react';
import { Button, Badge, Card, CardContent, Grid, Typography } from '@material-ui/core';
import styles from './PlanCard.module.scss';

const PlanCard = (props) => {

    const renderLimits = (limit) => {
        return limit.map((limitItem, i) => {
            return (
                <Typography key={limitItem.id} className={cardParagraph}>
                    {limitItem.name}
                </Typography>
            )
        })
    }

    const submitHandler = (e) => {
        e.stopPropagation();
        props.events();
    }

    const { limits, name, price, term, currentPlan, onSelect, selected, otherPlan, trial, events } = props;

    const {
        planCard,
        cardHeader,
        cardBadge,
        cardTitle,
        cardMenuIcon,
        cardDropDownItem,
        cardPrice,
        cardDiscount,
        cardPeriod,
        card__trial,
        cardDescription,
        cardParagraph,
        card__paragraph__big,
        active,
        deactive,
        current,
        card__button,
        card__button__trial
    } = styles;

    const planClasses = [planCard];

    const cardButtonClasses = [card__button];

    if (trial) cardButtonClasses.push(card__button__trial);

    if (selected) planClasses.push(active);

    if (otherPlan && !selected && !currentPlan) planClasses.push(deactive);

    if (trial && currentPlan) planClasses.push(deactive);

    if (currentPlan) planClasses.push(current);

    if (trial) planClasses.push(card__trial);

    return (
        <Card className={String(planClasses).split(',').join(' ')} onClick={onSelect}>
            {!trial ?
                <CardContent className={cardHeader}>
                    <Grid item>
                        <Grid>
                            {currentPlan ?
                                (<Badge className={cardBadge} badgeContent="Current Plan" color="primary">
                                    <Typography className={cardTitle}>
                                        {name}
                                    </Typography>
                                </Badge>)
                                : <Badge className={cardBadge}>
                                    <Typography className={cardTitle}>
                                        {name}
                                    </Typography>
                                </Badge>}
                        </Grid>
                        <Typography className={cardPrice}>
                            {Number(price) === 0 ? 'Free' : price}
                        </Typography>
                        <Typography className={cardPeriod}>
                            {Number(price) === 0 ? null : `/ ${term}`}
                        </Typography>
                    </Grid>
                </CardContent> : null}
            <CardContent className={cardDescription}>
                {!trial ? renderLimits(limits) :
                    <Typography variant="h3" className={card__paragraph__big}>
                        {currentPlan ? <Fragment>You have used your <b>14 days trial</b> on this business.</Fragment>
                            : <Fragment>Try all premium features for <b>14 days</b> for <b>FREE</b>.</Fragment>}
                    </Typography>}
                <Button
                    disabled={!selected}
                    className={String(cardButtonClasses).split(',').join(' ')}
                    onClick={submitHandler}
                    variant="contained"
                    size="medium"
                    color="primary">
                    {price == 0 ?
                        'Start Plan' :
                        (
                            !trial ?
                                'Buy Plan' :
                                'Start Trial'
                        )}
                </Button>
            </CardContent>
        </Card>
    )
}

export default PlanCard;
