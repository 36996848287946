import React from 'react';
import {
    Businesses,
    NewBusiness,
    NewRequest,
    Plans,
    RequestReports,
    RequestReport,
    PaymentHistory,
    Customers,
    NewContact,
    Agents,
    NewAgent,
    Dashboard,
    NotFound,
    Login,
    Profile,
    AddCard
} from '../components/containers';
import LibraryBooksOutlined from '@material-ui/icons/LibraryBooksOutlined';
import RateReviewOutlined from '@material-ui/icons/RateReviewOutlined';
import PersonPinOutlined from '@material-ui/icons/PersonPinOutlined';
import QueueOutlined from '@material-ui/icons/QueueOutlined';
import PeopleOutlined from '@material-ui/icons/PeopleOutlined';
import PersonAddOutlined from '@material-ui/icons/PersonAddOutlined';

export const paths = [
    {
        name: "business-dashboard",
        url: "/:id(\\d+)/dashboard/",
        protected: true,
        sideBar: true,
        component: () => <Dashboard title="Dashboard" />,
        paths: []
    },
    {
        name: "profile",
        url: "/profile/",
        protected: true,
        sideBar: false,
        component: () => <Profile title="Account" />,
        paths: []
    },
    {
        name: "businesses",
        url: "/businesses/",
        protected: true,
        sideBar: false,
        component: () => <Businesses title="Businesses" />,
        paths: [
            {
                name: "business-add",
                url: "add/",
                protected: true,
                sideBar: false,
                component: () => <NewBusiness title="Add New Business" />
            },

            {
                name: "business-id",
                url: ":id(\\d+)/",
                protected: true,
                sideBar: false,
                component: () => <Plans title="Change Plan" />
            },
            {
                name: "select-business-plan",
                url: "plans/",
                protected: true,
                sideBar: false,
                component: () => <Plans title="Choose a Plan" />
            }
        ]
    },
    {
        name: "payment",
        url: "/payment/",
        protected: true,
        sideBar: false,
        component: () => <PaymentHistory title="Payment History" />,
        paths: [
            {
                name: "add-payment-card",
                url: "add/",
                protected: true,
                sideBar: false,
                component: () => <AddCard title="Add Payment Card" />
            }
        ]
    },
    {
        name: "requests",
        url: "/:id(\\d+)/requests/",
        protected: true,
        sideBar: true,
        component: () => <RequestReports title="Request List" />,
        paths: [
            {
                name: "requests-add",
                url: "add/",
                protected: true,
                sideBar: true,
                component: () => <NewRequest title="Add New Request" />,
            },
            {
                name: "requests-id",
                url: ":id(\\d+)/",
                protected: true,
                sideBar: true,
                component: () => <RequestReport title="Request" />
            },
        ]
    },
    {
        name: "customers",
        url: "/:id(\\d+)/customers/",
        protected: true,
        sideBar: true,
        component: () => <Customers title="Customer List" />,
        paths: [
            {
                name: "customers-add",
                url: "add/",
                protected: true,
                sideBar: true,
                component: () => <NewContact title="Add New Customer" />
            }
        ]
    },
    {
        name: "agents",
        url: "/:id(\\d+)/agents/",
        protected: true,
        sideBar: true,
        component: () => <Agents title="Agent List" />,
        paths: [
            {
                name: "agents-add",
                url: "add/",
                protected: true,
                sideBar: true,
                component: () => <NewAgent title="Add New Agent" />
            }
        ]
    },
    {
        name: "login",
        url: "/",
        protected: false,
        sideBar: false,
        component: () => <Login />,
        paths: []
    },
    {
        name: "notfound",
        url: "*",
        protected: true,
        sideBar: false,
        component: () => <NotFound title="Page Not Found" />,
        paths: []
    },
];

export const sidebarItems = [
    {
        name: "Review Requests",
        url: "",
        icon: () => <RateReviewOutlined />,
        subItems: [
            {
                name: "Request List",
                url: "/requests/",
                icon: () => <LibraryBooksOutlined fontSize="small" />
            },
            {
                name: "Add New Request",
                url: "/requests/add/",
                icon: () => <QueueOutlined fontSize="small" />
            }
        ]
    },
    {
        name: "Customers",
        url: "",
        icon: () => <PersonPinOutlined />,
        subItems: [
            {
                name: "Customer List",
                url: "/customers/",
                icon: () => <LibraryBooksOutlined fontSize="small" />
            },
            {
                name: "Add New Customer",
                url: "/customers/add/",
                icon: () => <PersonAddOutlined fontSize="small" />
            }
        ]
    },
    {
        name: "Agents",
        url: "",
        icon: () => <PeopleOutlined />,
        subItems: [
            {
                name: "Agent List",
                url: "/agents/",
                icon: () => <LibraryBooksOutlined fontSize="small" />
            },
            {
                name: "Add New Agent",
                url: "/agents/add/",
                icon: () => <PersonAddOutlined fontSize="small" />
            }
        ]
    }
];