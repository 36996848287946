import types from './types';

export const messageFetcher = (type, text) => {
    return {
        type: types.message.SHOW_MESSAGE,
        payload: {
            data: {
                type: type,
                text: text
            }
        }
    }
}

export const messageSignouter = () => {
    return dispatch => {
        dispatch({
            type: types.message.SIGNOUT_USER
        })
    }
}