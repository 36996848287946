import types from "./types";
import api, { apiValues } from "./api";
import { messages } from "./messages";
import { messageText } from "../helpers/messages";

// This function authorize the user on login
export const userAuth = token => {
  return dispatch => {
    dispatch({ type: types.user.START_LOADING });
    api
      .postAuth(apiValues.url.API_URL + apiValues.url.AUTH_TOKEN, {
        access_token: token,
      })
      .then(res => {
        api.setApiCookie(res.data.content.access_token);
        api.get(apiValues.url.API_URL + apiValues.url.PROFILE_URL).then(res => {
          api.redirect("/businesses/");
          dispatch({
            type: types.user.FETCH_USER,
            payload: {
              data: {
                isAuthenticated: Object.keys(res.data.content).length !== 0,
                profile: res.data.content,
              },
            },
          });
          dispatch({ type: types.user.END_LOADING });
          if (res.data.status === 201) {
            dispatch({
              type: types.message.SHOW_MESSAGE,
              payload: {
                data: {
                  type: "success",
                  text: messages.success.USER_LOGIN_SUCCESS,
                },
              },
            });
          }
        });
      })
      .catch(err => {
        dispatch({ type: types.user.END_LOADING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "error",
              text: messages.error.USER_LOGIN_ERROR,
            },
          },
        });
      });
  };
};

// This function fetches all user profile information from the API
export const userFetcher = () => {
  return dispatch => {
    dispatch({ type: types.user.START_LOADING });
    api
      .get(apiValues.url.API_URL + apiValues.url.PROFILE_URL)
      .then(res => {
        dispatch({
          type: types.user.FETCH_USER,
          payload: {
            data: {
              isAuthenticated: Object.keys(res.data.content).length !== 0,
              profile: res.data.content,
            },
          },
        });
        dispatch({ type: types.user.END_LOADING });
      })
      .catch(err => {
        dispatch({ type: types.user.END_LOADING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "error",
              text: messages.error.USER_FETCH_ERROR,
            },
          },
        });
      });
  };
};

// This function updates the user profile to API with the given data
export const userUpdater = data => {
  return dispatch => {
    dispatch({ type: types.user.START_LOADING });
    api
      .patch(apiValues.url.API_URL + apiValues.url.PROFILE_URL, data)
      .then(res => {
        switch (res.data.status) {
          case 200:
            dispatch({
              type: types.user.UPDATE_USER_STATE,
              payload: {
                data: {
                  profile: res.data.content,
                },
              },
            });

            break;

          default:
            break;
        }
        dispatch({ type: types.user.END_LOADING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: res.data.status,
              text: messageText("user", "update"),
            },
          },
        });
      })
      .catch(err => {
        dispatch({ type: types.user.END_LOADING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "error",
              text: messages.error.USER_UPDATE_ERROR,
            },
          },
        });
      });
  };
};

// This function updates a piece of state of the user proile reducer
export const userStateUpdater = (name, value, user) => {
  return dispatch => {
    dispatch({
      type: types.user.UPDATE_USER_STATE,
      payload: {
        data: {
          profile: {
            ...user,
            [name]: value,
          },
        },
      },
    }).catch(res => {
      dispatch({
        type: types.message.SHOW_MESSAGE,
        payload: {
          data: {
            type: res.data.status,
            text: res.data.message,
          },
        },
      });
    });
  };
};

export const userSignouter = () => {
  return dispatch => {
    dispatch({
      type: types.user.SIGNOUT_USER,
    });
  };
};
