import types from '../actions/types';

const initialState = {
    count: 0,
    limit: 10,
    offset: 0,
    isLoading: false,
    isFetching: false,
    isPosting: false,
    isUpdating: false,
    isDeleting: false,
    list: [],
    searchResults: {
        count: 0,
        limit: 10,
        offset: 0,
        list: []
    }
}

export default (state = initialState, action) => {
    switch (action.type) {

        case types.customer.START_LOADING:
            return {
                ...state,
                isLoading: true
            }

        case types.customer.END_LOADING:
            return {
                ...state,
                isLoading: false
            }

        case types.customer.START_FETCHING:
            return {
                ...state,
                isFetching: true
            }

        case types.customer.END_FETCHING:
            return {
                ...state,
                isFetching: false
            }

        case types.customer.START_POSTING:
            return {
                ...state,
                isPosting: true
            }

        case types.customer.END_POSTING:
            return {
                ...state,
                isPosting: false
            }

        case types.customer.START_UPDATING:
            return {
                ...state,
                isUpdating: true
            }

        case types.customer.END_UPDATING:
            return {
                ...state,
                isUpdating: false
            }

        case types.customer.START_DELETING:
            return {
                ...state,
                isDeleting: true
            }

        case types.customer.END_DELETING:
            return {
                ...state,
                isDeleting: false
            }

        case types.customer.CREATE_SINGLE_CUSTOMER:
            return {
                ...state,
                count: action.payload.data.count,
                list: [
                    ...state.list,
                    action.payload.data.list
                ]
            }

        case types.customer.CREATE_CUSTOMERS:
            return {
                ...state,
                count: action.payload.data.count,
                list: [
                    ...state.list,
                    action.payload.data.list
                ]
            }

        case types.customer.DELETE_CUSTOMERS:
            return initialState;

        case types.customer.FETCH_CUSTOMERS:
            return {
                ...state,
                count: action.payload.data.count,
                limit: action.payload.data.limit,
                offset: action.payload.data.offset,
                searchResults: initialState.searchResults,
                list: [
                    ...state.list,
                    ...action.payload.data.list
                ]
            }

        case types.customer.FETCH_SEARCH_RESULTS:
            return {
                ...state,
                searchResults: {
                    count: action.payload.data.count,
                    limit: action.payload.data.limit,
                    offset: action.payload.data.offset,
                    list: [
                        ...state.searchResults.list,
                        ...action.payload.data.list
                    ]
                }
            }

        case types.customer.DELETE_SEARCH_RESULTS:
            return {
                ...state,
                searchResults: initialState.searchResults
            }

        case types.customer.UPDATE_CUSTOMERS:
            return {
                ...state,
                list: action.payload.data.list
            }

        case types.customer.DELETE_SINGLE_CUSTOMER:
            return {
                ...state,
                count: action.payload.data.count,
                list: action.payload.data.list
            }

        case types.customer.CREATE_CURERNT_BUSINESS_STATE:
            return initialState;

        case types.customer.SIGNOUT_USER:
            return initialState;

        default:
            return state;
    }
}
