import React, { Component } from 'react';
import { CssBaseline } from '@material-ui/core';
import { Sidebar, Navbar, NavbarResponsive, Alert, Preloader } from '../../common/index';
import { Route, Switch, withRouter } from 'react-router-dom';
import './Layout.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { currentBusinessStateCreator, currentBusinessFetcher, newBusinessStateRemover } from '../../../actions/businessActions';
import { plansFetcher } from '../../../actions/planActions';
import { paths } from '../../../paths';

class Layout extends Component {

	componentDidMount = () => {
		this.currentBusinessChecker();
	}

	renderPaths = () => {
		let routes = [];
		paths.map((path, i) => {
			let Component = path.component;
			routes.push(
				<Route exact path={path.url} render={Component} key={path.name + i} />
			);
			path.paths.map((subPath, i) => {
				Component = subPath.component;
				routes.push(
					<Route exact path={path.url + subPath.url} render={Component} key={subPath.name + i} />
				);
			});
		});
		return routes;
	}

	renderSidebar = (pathName) => {
		let currentPath = paths.filter(value => value.url === pathName || value.url === pathName + '/');
		let withSubPaths = paths.map(path => {
			return path.paths.find(subPath => {
				if (path.url + subPath.url === pathName || path.url + subPath.url === pathName + '/') {
					return subPath.sideBar;
				}
			});
		});
		const pathId = pathName.match(RegExp('/\\d+/'));

		if (pathId !== null) {
			currentPath = paths.filter(path => {
				const pathUrlWithId = path.url.replace(':id(\\d+)/', String(pathId).split('/').join('') + '/');
				return path.url === pathName || path.url === pathName + '/' || pathUrlWithId === pathName || pathUrlWithId === pathName + '/'
			});

			withSubPaths = paths.map(path => {
				const pathUrlWithId = path.url.replace(':id(\\d+)/', String(pathId).split('/').join('') + '/');
				const subPathId = String(pathName).split('/').filter(item => item.length !== 0)[2] + '/';
				return path.paths.find(subPath => {
					if (path.url + subPath.url === pathName ||
						path.url + subPath.url === pathName + '/' ||
						pathUrlWithId + subPath.url === pathName ||
						pathUrlWithId + subPath.url === pathName + '/' ||
						pathUrlWithId + subPathId === pathName ||
						pathUrlWithId + subPathId === pathName + '/') {
						return subPath.sideBar;
					}
				});
			});
		}

		const subPath = withSubPaths.filter(item => item);

		if (currentPath.length !== 0) {
			if (currentPath[0].sideBar) {
				return <Sidebar />
			}
		}
		if (subPath.length !== 0) {
			if (subPath[0].sideBar) {
				return <Sidebar />
			}
		}
	}

	currentBusinessChecker = () => {
		const pathName = this.props.location.pathname;
		const pathId = pathName.match(RegExp('^/\\d+/'));
		const id = Number(String(pathId).split('/').join(''));
		const currentBusiness = this.props.currentBusiness;
		if (pathId !== null && Object.keys(currentBusiness).length === 0) {
			this.props.currentBusinessFetcher(id);
			this.props.plansFetcher();
		}
	}

	renderMessages = (messages) => {
		return messages.map((item, i) => {
			const lastIndex = messages.length - 1;
			if (i === lastIndex) {
				return (
					<Alert
						className="alert"
						open={true}
						key={i++}
						variant={item.type}
						message={item.text} />
				);
			}
		});
	}

	renderNavbar = (currentBusiness) => {
		if (window.innerWidth > 768) {
			return <Navbar currentBusiness={currentBusiness} />;
		} else {
			return <NavbarResponsive currentBusiness={currentBusiness} />;
		}
	}

	render() {

		const { user } = this.props;

		const pathName = this.props.location.pathname;

		const pathId = pathName.match(RegExp('/\\d+/'));

		const currentBusiness = this.props.currentBusiness;

		if (pathId !== null && Object.keys(currentBusiness).length === 0) return <Preloader fullScreen={true} />;

		return (
			<div className="layout">
				<CssBaseline />
				{user.isAuthenticated ? this.renderNavbar(currentBusiness) : ''}
				{user.isAuthenticated ? this.renderSidebar(pathName) : ''}
				<main className={pathName === "/" ? "layoutContent" : "loggedLayoutContent"}>
					<Switch>
						{this.renderPaths()}
					</Switch>
				</main>
				<div className="alerts">
					{user.isAuthenticated && this.props.messages && this.props.messages.length ? this.renderMessages(this.props.messages) : ''}
				</div>
			</div>
		)
	}
}

const mapStateToProps = ({ ui, messages, user, plans, businesses }) => {
	return {
		messages: messages.list,
		user,
		plans,
		userIsLoading: user.isLoading,
		businesses: businesses.list,
		currentBusiness: businesses.currentBusiness
	}
}

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		currentBusinessFetcher,
		currentBusinessStateCreator,
		plansFetcher,
		newBusinessStateRemover
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
