import React, { Component, Fragment } from 'react';
import { Grid, MenuItem, IconButton, Menu } from '@material-ui/core';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state/index';
import KeyboardArrowDownOutlined from '@material-ui/icons/KeyboardArrowDownOutlined';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { userSignouter } from '../../../actions/userActions';
import { businessSignouter } from '../../../actions/businessActions';
import { messageSignouter } from '../../../actions/messageActions';
import { Preloader } from '../index';
import styles from './Userbar.module.scss';
import * as Cookies from 'js-cookie';

class Userbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            avatar: this.props.user.avatar
        }
    }

    logoutHandler = (popupState) => {
        popupState.close();
        this.props.userSignouter();
        this.props.businessSignouter();
        this.props.messageSignouter();
        Cookies.remove('upperly_auth');
    }

    upgradeHandler = (currentBusiness) => {
        const { userbar__link } = styles;
        const id = currentBusiness.id;
        if (Object.keys(currentBusiness).length && currentBusiness.is_basic) {
            return (
                <Link className={userbar__link} to={`/businesses/${id}/`}>
                    Upgrade Business
                </Link>
            )
        }
    }

    avatarHandler = () => {
        this.setState({
            avatar: `${process.env.PUBLIC_URL}/static/panel/img/upperly_default_avatar.svg`
        });
    }

    render() {

        const {
            userbar__dropdown,
            userbar__icon,
            userbar__profileImg,
            userbar__preloaderContainer,
            dropdown,
            dropdown__item,
            dropdown__link,
            dropdown__header
        } = styles;

        const { first_name, last_name, email } = this.props.user;

        const { currentBusiness } = this.props;

        const { avatar } = this.state;

        return (
            <Grid>
                <PopupState variant="popover">
                    {popupState => (
                        <Fragment>
                            <IconButton {...bindTrigger(popupState)} className={userbar__dropdown}>
                                <div className={userbar__profileImg}>
                                    {avatar ? <img src={avatar} alt={`Welcome, ${first_name}`}
                                        onError={this.avatarHandler}
                                    /> :
                                        <div className={userbar__preloaderContainer}>
                                            <Preloader float size="16px" />
                                        </div>}
                                </div>
                                <KeyboardArrowDownOutlined className={userbar__icon} />
                            </IconButton>
                            <Menu {...bindMenu(popupState)} className={dropdown}>
                                <MenuItem disableRipple className={dropdown__item}>
                                    <span className={dropdown__header}>
                                        {`${first_name} ${last_name}`}
                                        <small>
                                            {email}
                                        </small>
                                    </span>
                                </MenuItem>
                                <MenuItem onClick={popupState.close} className={dropdown__item}>
                                    <Link className={dropdown__link} to="/payment/">
                                        Payment History
                                </Link>
                                </MenuItem>
                                <MenuItem onClick={popupState.close} className={dropdown__item}>
                                    <Link className={dropdown__link} to="/profile/">
                                        Account
                                </Link>
                                </MenuItem>
                                {/* <MenuItem onClick={popupState.close} className={dropdown__item}>
                                    <Link className={dropdown__link} to="/help/">
                                        Help
                                </Link>
                                </MenuItem> */}
                                <MenuItem onClick={() => this.logoutHandler(popupState)} className={dropdown__item}>
                                    <Link className={dropdown__link} to="/">
                                        Signout
                                </Link>
                                </MenuItem>
                            </Menu>
                            {this.upgradeHandler(currentBusiness)}
                        </Fragment>
                    )}
                </PopupState>
            </Grid>
        )
    }
}

const mapStateToProps = ({ user, businesses }) => {
    return {
        user: user.profile,
        currentBusiness: businesses.currentBusiness
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        userSignouter,
        businessSignouter,
        messageSignouter
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Userbar));