import React, { Component } from 'react';
import { PageHeader, PlanCard, Preloader } from '../../common';
import { Grid, Button, Typography, CssBaseline } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { plansFetcher } from '../../../actions/planActions';
import { withRouter } from 'react-router-dom';
import { businessCreator, newBusinessStateCreator, businessPlanUpdater } from '../../../actions/businessActions';
import { messageFetcher } from '../../../actions/messageActions';
import history from '../../../helpers/history';
import { messages } from '../../../actions/messages';
import styles from './Plans.module.scss';

// This component used for "/businesses/:id" route component
class Plans extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPlan: ''
        }
        document.title = `${global.appName} | ${this.props.title}`;
    }

    componentWillReceiveProps = (props) => {
        document.title = `${global.appName} | ${this.props.title}`;
    }

    componentWillMount = () => {
        // Fetches all plans from API
        this.props.plansFetcher();
    }

    componentDidMount = () => {
        this.redirectHandlder();
    }

    redirectHandlder = () => {
        const pathName = this.props.location.pathname;
        if (pathName.includes('plans') && !Object.keys(this.props.newBusiness).length) {
            return history.push("/businesses/add/");
        }
        if (!pathName.includes('plans') && !Object.keys(this.props.currentBusiness).length) {
            return history.push("/businesses/")
        }
    }

    planUpdaterHandler = (plan, currentBusiness, businesses, user) => {
        const currentBusinessData = {
            ...currentBusiness,
            plan: plan.id,
            request_trial: !user.trial_is_used ? plan.is_trial : false
        }
        const businessIndex = businesses.findIndex(item => item.id === currentBusiness.id);
        const restBusinesses = businesses.filter(item => item.id !== currentBusinessData.id);
        this.props.businessPlanUpdater(currentBusinessData, restBusinesses, businessIndex);
    }

    businessCreatorHandler = async (plan, user) => {
        if (plan.length !== 0) {
            try {
                await this.props.newBusinessStateCreator('request_trial', (!user.trial_is_used ? plan.is_trial : false), this.props.newBusiness)
                await this.props.newBusinessStateCreator('plan', Number(plan.id), this.props.newBusiness)
                this.props.businessCreator(this.props.newBusiness);
            } catch {
                this.props.messageFetcher('error', messages.error.BUSINESS_CREATE_ERROR);
            }
        }
    }

    // This fucntion Checks if selectedPlan state
    // is not empty, then returns a button with name of the plan.
    // Button checks between trial and non-trial plans and returns
    // multiple labels.
    selectedPlanHandler = (selectedPlan, currentBusiness, businesses, user) => {
        if (selectedPlan !== '') {
            if (!Object.keys(currentBusiness).length) {
                this.businessCreatorHandler(selectedPlan, user)
            } else {
                this.planUpdaterHandler(selectedPlan, currentBusiness, businesses, user)
            }
        }
        return null;
    }

    // This funtion renders defferent plans cards.
    // This set the selectedPlan state by clicking on the card
    // to catch the current selected plan.
    renderPlanCards = (plans, selectedPlan, user, currentBusiness, businesses) => {
        const noTrialPlans = plans.map(plan => {
            return { ...plan, is_trial: false }
        });
        if (noTrialPlans.length) {
            return (
                noTrialPlans.map(plan => {
                    return (
                        <Grid key={plan.id} item md={4}>
                            <PlanCard
                                name={plan.name}
                                price={plan.price}
                                term={plan.term}
                                limits={plan.limits}
                                trial={false}
                                selected={(plan.id === selectedPlan.id) && (plan.is_trial === selectedPlan.is_trial)}
                                currentPlan={!currentBusiness.trial_is_active && currentBusiness.plan === plan.id}
                                otherPlan={Object.values(this.state.selectedPlan).length > 0}
                                onSelect={() => {
                                    if (currentBusiness.trial_is_active) {
                                        this.setState({
                                            selectedPlan: plan
                                        })
                                    } else if (currentBusiness.plan !== plan.id) {
                                        this.setState({
                                            selectedPlan: plan
                                        })
                                    }
                                }}
                                events={() => this.selectedPlanHandler(selectedPlan, currentBusiness, businesses, user)}
                            />
                        </Grid>
                    )
                })
            )
        }
    }

    renderTrialCard = (plans, selectedPlan, user, currentBusiness, businesses) => {
        const { card__grid__trial } = styles;
        if (plans.length && Object.keys(user).length) {
            const trialPlan = plans.filter(plan => {
                if (plan.is_trial) {
                    if (Object.keys(currentBusiness).length && currentBusiness.trial_is_active) {
                        return plan;
                    } else if (!user.trial_is_used) {
                        return plan;
                    } else {
                        return null;
                    }
                }
            })[0];
            if (trialPlan) {
                return (
                    <Grid item md={12} className={card__grid__trial} >
                        <Grid key={trialPlan.id} item md={8}>
                            <PlanCard
                                name={trialPlan.name}
                                price={trialPlan.price}
                                term={trialPlan.term}
                                limits={trialPlan.limits}
                                trial={true}
                                selected={(trialPlan.id === selectedPlan.id) && (trialPlan.is_trial === selectedPlan.is_trial)}
                                currentPlan={currentBusiness.trial_is_active}
                                otherPlan={Object.values(this.state.selectedPlan).length > 0}
                                onSelect={() => {
                                    if (currentBusiness.plan !== trialPlan.id) this.setState({ selectedPlan: trialPlan })
                                }}
                                events={() => this.selectedPlanHandler(selectedPlan, currentBusiness, businesses, user)}
                            />
                        </Grid>
                    </Grid>
                );
            }
        }
    }

    render() {


        const { selectedPlan } = this.state;

        const { plansIsLoading, plans, user, businesses, currentBusiness } = this.props;

        const pathName = this.props.location.pathname;

        if (plansIsLoading) return <Preloader inWindow />;

        return (
            <Grid container spacing={16} >
                <Grid item md={12}>
                    <PageHeader
                        title={this.props.title}
                        description={pathName.includes('plans') ? `Choose the plan that fits your business.` : `Change to the plan that fits your business.`} />
                    <CssBaseline />
                </Grid>
                {this.renderTrialCard(plans, selectedPlan, user, currentBusiness, businesses)}
                {this.renderPlanCards(plans, selectedPlan, user, currentBusiness, businesses)}
            </Grid>
        )
    }
}

const mapStateToProps = ({ user, plans, businesses }) => {
    return {
        user: user.profile,
        plans: plans.list,
        plansIsLoading: plans.isLoading,
        businesses: businesses.list,
        newBusiness: businesses.newBusiness,
        currentBusiness: businesses.currentBusiness
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        plansFetcher,
        businessCreator,
        newBusinessStateCreator,
        businessPlanUpdater,
        messageFetcher
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Plans));