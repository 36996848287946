import React, { Component, createRef } from 'react';
import { Grid, Card, CardContent, IconButton, Button, RootRef } from '@material-ui/core';
import { FormInput, Preloader } from '../index';
import ArrowForward from '@material-ui/icons/ArrowForward';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { businessFetcher, newBusinessStateCreator, newBusinessStateRemover } from '../../../actions/businessActions';
import { messageFetcher } from '../../../actions/messageActions';
import history from '../../../helpers/history';
import styles from './NewBusinessCard.module.scss';

class NewBusinessCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            shouldKeepState: false,
        }
        this.fieldToFocus = React.createRef();
        this.setFormFocus = this.setFormFocus.bind(this);
    }

    componentWillUnmount = () => {
        this.onExitNewBusinessRemover();
    }

    componentDidMount = () => {
        // this.props.setFormFocus(this.fieldToFocus.current.children[1].children[0]);
        this.props.focusFunction(this.setFormFocus)
    }

    onExitNewBusinessRemover = () => {
        if (!this.state.shouldKeepState) {
            this.props.newBusinessStateRemover({});
        }
    }

    setFormFocus() {
        this.fieldToFocus.current.children[1].children[0].focus();
    }

    // This function updates current business reducer with input
    // new data as user enters
    inputHandler = async (name, e, newBusiness) => {
        if (e.target.value.length) {
            this.props.newBusinessStateCreator(name, e.target.value, newBusiness);
        }
        else {
            await delete newBusiness[name];
            this.props.newBusinessStateRemover(newBusiness);
        }
    }

    // This function fetches businesses from API
    // then closes the edit card by refrencing "closeAction" prop
    closeHandler = (url) => {
        if (url) history.push(url)
    }

    planSelector = () => {
        this.setState({ shouldKeepState: true }, () => {
            history.push("/businesses/plans");
        })
    }

    render() {

        const {
            closeButtonPath,
            selectable,
            isLoading,
            messageFetcher
        } = this.props;

        const {
            card,
            card__header,
            card__icon__left,
            card__icon__right,
            card__icon__disabled,
            card__icon__save,
            card__icon__back,
            card__icon__text,
            card__content,
            card__input
        } = styles;

        const newBusiness = this.props.newBusiness;

        const {
            name,
            sender_name,
            place_id,
            phone,
            website,
            contact_name
        } = this.props.newBusiness;

        const hasNewBusiness = Object.values(newBusiness).length && (newBusiness.name && newBusiness.place_id);

        return (
            <Card className={card}>
                <form onSubmit={hasNewBusiness ? this.planSelector : null}>
                    <CardContent className={card__header}>
                        <Grid item>
                            <IconButton className={String([card__icon__left]).replace(',', ' ')} onClick={() => this.closeHandler('/businesses/')}>
                                <ArrowBack className={card__icon__back} />
                            </IconButton>
                            <Button type="submit" className={hasNewBusiness ? card__icon__right : String([card__icon__right, card__icon__disabled]).replace(',', ' ')}>
                                <span className={card__icon__text}>Next</span>
                                <ArrowForward className={card__icon__save} />
                            </Button>
                        </Grid>
                    </CardContent>
                    <CardContent className={`ripple ${card__content}`}>
                        {isLoading ? <Preloader absolute transparent /> : null}
                        <Grid className={card__input}>
                        <RootRef rootRef={this.fieldToFocus}>
                            <FormInput
                                label="Business Name *"
                                value={name}
                                name="name"
                                selectable={place_id === undefined}
                                events={(e) => this.inputHandler('name', e, newBusiness)} />
                        </RootRef>
                        </Grid>
                        <Grid className={card__input}>
                            <FormInput
                                label="Place ID *"
                                name="place_id"
                                value={place_id}
                                selectable={selectable}
                                events={(e) => this.inputHandler('place_id', e, newBusiness)} />
                        </Grid>
                        <Grid className={card__input}>
                            <FormInput
                                label="Send From"
                                name="sender_name"
                                value={sender_name}
                                events={(e) => this.inputHandler('sender_name', e, newBusiness)} />
                        </Grid>
                        <Grid className={card__input}>
                            <FormInput
                                label="Phone"
                                name="phone"
                                value={phone}
                                events={(e) => this.inputHandler('phone', e, newBusiness)} />
                        </Grid>
                        <Grid className={card__input}>
                            <FormInput
                                label="Website"
                                name="website"
                                value={website}
                                events={(e) => this.inputHandler('website', e, newBusiness, hasNewBusiness)} />
                        </Grid>
                        <Grid className={card__input}>
                            <FormInput
                                label="Contact Name"
                                name="contact_name"
                                value={contact_name}
                                events={(e) => this.inputHandler('contact_name', e, newBusiness, hasNewBusiness)} />
                        </Grid>
                    </CardContent>
                </form>
            </Card>
        )
    }
}

const mapStateToProps = ({ user, businesses }) => {
    return {
        newBusiness: businesses.newBusiness,
        isLoading: businesses.isLoading
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        messageFetcher,
        businessFetcher,
        newBusinessStateCreator,
        newBusinessStateRemover
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewBusinessCard));