import types from "./types";
import api, { apiValues } from "./api";
import { messages } from "./messages";
import { messageText } from "../helpers/messages";

export const cardFetcher = () => {
  return dispatch => {
    dispatch({ type: types.ui.START_LOADING });
    api
      .get(apiValues.url.API_URL + apiValues.url.PAYMENT_INFO)
      .then(res => {
        dispatch({
          type: types.payment.FETCH_PAYMENT_CRAD,
          payload: {
            data: res.data.content,
          },
        });
        dispatch({ type: types.ui.END_LOADING });
      })
      .catch(err => {
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "error",
              text: messages.error.PAYMENT_FETCH_ERROR,
            },
          },
        });
      });
  };
};

export const cardCreator = (data, createBusinessFn) => {
  return dispatch => {
    dispatch({ type: types.payment.START_LOADING });
    api
      .patch(apiValues.url.API_URL + apiValues.url.PAYMENT_INFO, data)
      .then(res => {
        createBusinessFn();
        dispatch({ type: types.payment.END_LOADING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: res.data.status,
              text: messageText("credit card", "create"),
            },
          },
        });
      })
      .catch(err => {
        dispatch({ type: types.payment.END_LOADING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "error",
              text: messages.error.PAYMENT_CREATE_ERROR,
            },
          },
        });
      });
  };
};

export const cardUpdater = (data, cardFetcherFn) => {
  return dispatch => {
    // dispatch({ type: types.payment.START_LOADING });
    api
      .patch(apiValues.url.API_URL + apiValues.url.PAYMENT_INFO, data)
      .then(res => {
        dispatch({
          type: types.payment.FETCH_PAYMENT_CRAD,
          payload: {
            data: res.data.content,
          },
        });
        dispatch({ type: types.payment.END_LOADING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: res.data.status,
              text: messageText("card", "update"),
            },
          },
        });
      })
      .catch(err => {
        dispatch({ type: types.payment.END_LOADING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "error",
              text: messages.error.PAYMENT_CREATE_ERROR,
            },
          },
        });
      });
  };
};

export const paymentFetcher = (limit = 10, offset = 0) => {
  return dispatch => {
    dispatch({ type: types.payment.START_LOADING });
    api
      .get(
        apiValues.url.API_URL +
          apiValues.url.PAYMENTS +
          `?limit=${limit}&offset=${offset}`
      )
      .then(res => {
        dispatch({
          type: types.payment.FETCH_PAYMENTS_HISTORY,
          payload: {
            data: {
              limit,
              offset,
              count: res.data.content.count,
              list: res.data.content.results,
            },
          },
        });
        dispatch({ type: types.payment.END_LOADING });
      })
      .catch(err => {
        dispatch({ type: types.payment.END_LOADING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "error",
              text: messages.error.PAYMENTS_HISTORY_FETCH_ERROR,
            },
          },
        });
      });
  };
};

export const paymentSearchFetcher = (
  limit = 10,
  offset = 0,
  searchTerm,
  clearLastSearch = false
) => {
  return dispatch => {
    dispatch({ type: types.payment.START_LOADING });
    api
      .get(
        apiValues.url.API_URL +
          apiValues.url.PAYMENTS +
          `?search=${searchTerm}&limit=${limit}&offset=${offset}`
      )
      .then(res => {
        if (clearLastSearch) {
          dispatch({ type: types.payment.DELETE_SEARCH_RESULTS });
        }
        dispatch({
          type: types.payment.FETCH_SEARCH_RESULTS,
          payload: {
            data: {
              limit,
              offset,
              count: res.data.content.count,
              list: res.data.content.results,
            },
          },
        });
        dispatch({ type: types.payment.END_LOADING });
      })
      .catch(err => {
        dispatch({ type: types.payment.END_LOADING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "warning",
              text: messages.error.PAYMENTS_HISTORY_FETCH_ERROR,
            },
          },
        });
      });
  };
};

export const paymentSearchRemover = () => {
  return dispatch => {
    dispatch({ type: types.payment.START_LOADING });
    dispatch({ type: types.payment.DELETE_SEARCH_RESULTS });
    dispatch({ type: types.payment.END_LOADING });
  };
};

export const paymentPreloaderStarter = () => {
  return dispatch => {
    dispatch({ type: types.payment.START_LOADING });
  };
};

export const paymentPreloaderFinisher = () => {
  return dispatch => {
    dispatch({ type: types.payment.END_LOADING });
  };
};
