import React, { Component, Fragment } from 'react';
import { Grid, Card, CardContent, Button, AppBar, Tabs, Tab, Typography } from '@material-ui/core';
import { PageHeader, FormInput, TimeSearchableFilter, Preloader, EditPaymentForm } from '../../common';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { userFetcher, userUpdater } from '../../../actions/userActions';
import { currentBusinessStateCreator } from '../../../actions/businessActions';
import { cardFetcher } from '../../../actions/paymentActions';
import { messageFetcher } from '../../../actions/messageActions';
import SwipeableViews from 'react-swipeable-views';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { apiValues } from '../../../actions/api';
import styles from './Profile.module.scss';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                first_name: '',
                last_name: '',
                mobile: '',
                timezone: ''
            },
            tabValue: 0,
            stateChanged: false,
            hasError: []
        }
        document.title = `${global.appName} | ${this.props.title}`;
        // This is for return an empty object for the current business
        this.props.currentBusinessStateCreator();
    }

    componentDidMount = () => {
        this.props.cardFetcher();
    }

    componentWillReceiveProps = (props) => {
        document.title = `${global.appName} | ${this.props.title}`;
        this.setState({
            ...this.state,
            data: {
                first_name: this.props.user.first_name,
                last_name: this.props.user.last_name,
                mobile: this.props.user.mobile,
                timezone: this.props.user.timezone
            }
        });
    }

    // This function updates the user information to API
    // when user clicks on "Update profile" button
    profileUpdateHandler = (user) => {
        this.props.userUpdater(user);
    }

    inputHandler = (fieldName, fieldLabel, e, validationType) => {
        const inputValue = e.target ? e.target.value : e;
        this.setState({
            data: {
                ...this.state.data,
                [fieldName]: inputValue
            }
        }, () => this.setState({ stateChanged: (this.state.data[fieldName] !== this.props.user[fieldName]) }, () => {
            this.inputErrorHandler(fieldName, fieldLabel, inputValue, validationType)
        }))
    }


    inputErrorHandler = (fieldName, fieldLabel, inputValue, validationType) => {
        const { messageFetcher } = this.props;
        const hasError = [];
        validationType.map(validationItem => {
            switch (validationItem.type) {
                case 'required':
                    if(!inputValue.length) {
                        messageFetcher('warning', `${fieldLabel} Could not be empty!`);
                        hasError.push({ type: validationItem.type, field: fieldName });
                    }
                    break;

                    case 'phone':
                        const numberRegex = /[+]?(\d{1,4}(\s|-)?)?(((\(\d{2,4}\)|\d{2,4})(\s|-)?){3}|(\d{5,10}))/;
                        if (!numberRegex.test(inputValue)) {
                            messageFetcher('warning', `Please enter a valid ${fieldLabel}!`);
                            hasError.push({ type: validationItem.type, field: fieldName });
                        }
                        break;

                        case 'minLength':
                            if(inputValue.length < validationItem.value) {
                                messageFetcher('warning', `Min characters for ${fieldLabel} is ${validationItem.value}`);
                                hasError.push({ type: validationItem.type, field: fieldName });
                            } 
                            break;

                        case 'maxLength':
                            if(inputValue.length > validationItem.value) {
                                messageFetcher('warning', `Max characters for ${fieldLabel} is ${validationItem.value}`);
                                hasError.push({ type: validationItem.type, field: fieldName });
                            } 
                            break;          
            
                default:
                    break;
            }
        })
        const oldHasError = this.state.hasError.filter(errorItem => errorItem.field !== fieldName)
        this.setState({ hasError: [...oldHasError, ...hasError] })
    }

    tabHandler = (event, tabValue) => {
        this.setState({
            ...this.state,
            tabValue
        })
    }

    tabHandlerIndex = index => {
        this.setState({
            ...this.state,
            tabValue: index
        })
    }

    render() {
        const { profile__card, card__content, card__input, card__filter } = styles;

        const { stateChanged, hasError } = this.state;

        const { first_name, last_name, mobile, timezone } = this.state.data;

        const { userIsLoading, user, paymentIsLoading, paymentCard } = this.props;

        const stripe = window.Stripe;

        console.log('hasError', hasError)

        return (
            <Grid container spacing={16} >
                <Grid item md={12}>
                    <PageHeader
                        title="Account"
                        description="Here you can view & edit your account settings." />
                </Grid>
                <Grid item md={6}>
                    <Card className={profile__card}>
                        <AppBar position="static" color="default">
                            <Tabs
                                value={this.state.tabValue}
                                onChange={this.tabHandler}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth" >
                                <Tab
                                    className={styles.tabLabel}
                                    onClick={() => this.setState({ tabValue: 0 })}
                                    label={
                                        <Typography className={styles.tabLabelHelp}>
                                            Profile
                                    </Typography>
                                    } />
                                <Tab
                                    className={styles.tabLabel}
                                    onClick={() => this.setState({ tabValue: 1 })}
                                    label={
                                        <Typography className={styles.tabLabelHelp}>
                                            Payment Method
                                        </Typography>
                                    } />
                            </Tabs>
                        </AppBar>
                        <SwipeableViews
                            axis={'x'}
                            index={this.state.tabValue}
                            onChangeIndex={this.tabHandlerIndex}
                            className={styles.tabsContainer}>
                            <CardContent className={`ripple ${card__content}`}>
                                {userIsLoading || !Object.keys(user).length ? <Preloader absolute transparent /> : null}
                                <Grid className={card__input}>
                                    <FormInput
                                        label="First Name"
                                        value={first_name}
                                        validation
                                        validated={hasError.find(item => item.field == 'first_name') ? false : true}
                                        events={(e) => this.inputHandler('first_name', 'First Name', e, [{ type: 'required' }, { type: 'maxLength', value: 30 }])} />
                                </Grid>
                                <Grid className={card__input}>
                                    <FormInput
                                        label="Last Name"
                                        value={last_name}
                                        validation
                                        validated={hasError.find(item => item.field == 'last_name') ? false : true}
                                        events={(e) => this.inputHandler('last_name', 'Last Name', e, [{ type: 'required' }, { type: 'maxLength', value: 150 }])} />
                                </Grid>
                                <Grid className={card__input}>
                                    <FormInput
                                        label="Mobile"
                                        value={mobile}
                                        validation
                                        validated={hasError.find(item => item.field == 'mobile') ? false : true}
                                        events={(e) => this.inputHandler('mobile', 'Mobile Number', e, [{type: 'phone'}, { type: 'minLength', value: 7}, { type: 'maxLength', value: 15}])} />
                                </Grid>
                                <Grid className={card__input}>
                                    <TimeSearchableFilter
                                        label="Timezone"
                                        value={timezone}
                                        width={card__filter}
                                        events={(e) => this.inputHandler('timezone', 'Timezone',e)}
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={hasError.length ? true : (!stateChanged ? true : false)}
                                        onClick={() => this.profileUpdateHandler(this.state.data)}>
                                        Update Profile
                                    </Button>
                                </Grid>
                            </CardContent>
                            <CardContent className={`ripple ${card__content}`}>
                                {paymentIsLoading || !Object.keys(paymentCard).length ? <Preloader absolute transparent /> : null}
                                {stripe ?
                                    <StripeProvider apiKey={apiValues.keys.STRIPE_KEY}>
                                        <Elements>
                                            <EditPaymentForm />
                                        </Elements>
                                    </StripeProvider>
                                    :
                                    <Grid item md={12}>
                                        <Card>
                                            <CardContent>
                                                <Typography>
                                                    Unfortunately, Stripe not loaded currectly. <br />
                                                    Please check your connection and Try again. <br />
                                                    If this happend in the future again, please contact us.
                                    </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                }
                            </CardContent>
                        </SwipeableViews>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = ({ user, plans, payment }) => {
    return {
        userIsLoading: user.isLoading,
        user: user.profile,
        paymentIsLoading: payment.isLoading,
        paymentCard: payment.card,
        plans
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        userFetcher,
        userUpdater,
        cardFetcher,
        currentBusinessStateCreator,
        messageFetcher
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);