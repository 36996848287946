// @flow
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { bindActionCreators } from 'redux';
import { newBusinessStateCreator, businessPreloaderStarter, businessPreloaderFinisher } from '../../../actions/businessActions';
import { messageFetcher } from '../../../actions/messageActions';
import { SearchBox } from 'react-google-maps/lib/components/places/SearchBox';
import styles from './Map.module.scss';

class Map extends Component {

    render() {

        const googleMap = window.google.maps;

        const {
            map__input
        } = styles;

        return (
            <Fragment>
                <GoogleMap
                    ref={this.props.onMapMounted}
                    defaultZoom={15}
                    center={this.props.center}
                    onBoundsChanged={this.props.onBoundsChanged} >
                    <SearchBox
                        ref={this.props.onSearchBoxMounted}
                        bounds={this.props.bounds}
                        controlPosition={googleMap.ControlPosition.TOP_LEFT}
                        onPlacesChanged={this.props.onPlacesChanged}>
                        <input
                            type="text"
                            placeholder="Search business by name..."
                            className={map__input}
                        />
                    </SearchBox>
                    {this.props.markers.map((marker, index) =>
                        <Marker key={index} position={marker.position} />
                    )}
                </GoogleMap>
            </Fragment>
        )
    }
}

const mapStateToProps = ({ user, businesses }) => {
    return {
        user,
        newBusiness: businesses.newBusiness
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        newBusinessStateCreator,
        businessPreloaderStarter,
        businessPreloaderFinisher,
        messageFetcher
    }, dispatch)
}

export default (
    compose(
        connect(
            mapStateToProps,
            mapDispatchToProps
        ),
        lifecycle({
            componentWillMount() {
                const refs = {}

                this.setState({
                    bounds: null,
                    center: {
                        lat: 41.9, lng: -87.624
                    },
                    markers: [],
                    onMapMounted: ref => {
                        refs.map = ref;
                    },
                    onBoundsChanged: () => {
                        this.setState({
                            bounds: refs.map.getBounds(),
                            center: refs.map.getCenter(),
                        })
                    },
                    onSearchBoxMounted: ref => {
                        refs.searchBox = ref;
                    },
                    onPlacesChanged: () => {
                        const places = refs.searchBox.getPlaces();
                        const bounds = new window.google.maps.LatLngBounds();

                        places.forEach(place => {
                            if (place.geometry.viewport) {
                                bounds.union(place.geometry.viewport)
                            } else {
                                bounds.extend(place.geometry.location)
                            }
                        });

                        const nextMarkers = places.map(place => ({
                            position: place.geometry.location,
                        }));

                        let nextCenter = this.state.center;

                        if (nextMarkers.length) {
                            this.props.businessPreloaderStarter();
                            setTimeout(() => {
                                nextCenter = nextMarkers[0].position;
                                this.setState({
                                    center: nextCenter,
                                    markers: nextMarkers,
                                });

                                refs.map.fitBounds(bounds);

                                this.props.newBusinessStateCreator('name', places[0].name, this.props.newBusiness);
                                this.props.newBusinessStateCreator('place_id', places[0].place_id, this.props.newBusiness);
                                this.props.newBusinessStateCreator('sender_name', this.props.user.profile.first_name, this.props.newBusiness);
                                this.props.newBusinessStateCreator('contact_name', this.props.user.profile.first_name, this.props.newBusiness);
                                this.props.focusFunction();

                                if (places[0].website) {
                                    this.props.newBusinessStateCreator(
                                        'website',
                                        places[0].website,
                                        this.props.newBusiness
                                    )
                                }
                                if (places[0].international_phone_number) {
                                    this.props.newBusinessStateCreator(
                                        'phone',
                                        places[0].international_phone_number,
                                        this.props.newBusiness
                                    )
                                }
                                this.props.businessPreloaderFinisher();
                            }, 400)
                        } else {
                            this.props.messageFetcher('warning', 'This place has no Place ID, please try another.')
                        }
                    },
                })
            },
        }),
        withScriptjs,
        withGoogleMap
    )(Map));