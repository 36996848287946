import React, { Component } from 'react';
import { PageHeader, Auth, Logo, Preloader } from '../../common';
import { Grid, Card, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import history from '../../../helpers/history';
import styles from './Login.module.scss';

class Login extends Component {

    // This function redirects "Continue to Upperly" button
    // to businesses page
    redirectHandler = () => {
        history.push("/businesses/");
    }

    render() {

        const { page__container, page__header, page__vector, page__button } = styles;

        const { isAuthenticated, isLoading } = this.props;

        const text = {
            loggedInTitle: "You are loggedin!",
            notLoggedInTitle: "Login now and Start Maximizing Your Business!",
            loggedInDesc: "Click on the continue button to add your business and get authentic customer reviews, turn happy customers to five stars and stand out from the competitions",
            notLoggedInDesc: "Turn happy customers into five stars, stand out from the competition and collect user feedback.",
        }

        if (isLoading) return <Preloader inWindow />;

        return (
            <Grid container className={page__container} spacing={16} >
                <Grid item md={4}>
                    <Card className={page__header}>
                        <Grid item md={12}>
                            <Logo name="Upperly" variant="dark" height="60" />
                            <PageHeader
                                title={isAuthenticated ? text.loggedInTitle : text.notLoggedInTitle}
                                description={isAuthenticated ? text.loggedInDesc : text.notLoggedInDesc}
                                vCentered={false} />
                        </Grid>
                        <Grid item md={12}>
                            {isAuthenticated ?
                                <Button
                                    className={page__button}
                                    variant="contained"
                                    color="primary"
                                    onClick={this.redirectHandler} >
                                    Continue to
                                    <Logo name="Upperly" variant="white" height="25" />
                                </Button>
                                : <Auth buttonText="Login with Google" />}
                        </Grid>
                    </Card>
                </Grid>
                <Grid item md={8}>
                    <img
                        className={page__vector}
                        src={`${process.env.PUBLIC_URL}/static/panel/img/vector_login_page.svg`} alt="Upperly" />
                </Grid>
            </Grid>
        )
    }

}

const mapStateToProps = ({ user }) => {
    return {
        isAuthenticated: user.isAuthenticated,
        isLoading: user.isLoading
    }
}

export default connect(mapStateToProps)(withRouter(Login));