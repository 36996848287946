import React, { Component, Fragment } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { AppBar, Tabs, Tab, FormControlLabel, Radio, Typography } from '@material-ui/core';
import { SingleRequestTab, CampaignTab, Preloader } from '../index';
import styles from './NewRequestTab.module.scss';

class NewRequestTab extends Component {

    state = {
        tabValue: 0
    }

    tabHandler = (event, tabValue) => {
        this.setState({
            tabValue
        }, () => this.props.events({}))
    }

    tabHandlerIndex = index => {
        this.setState({
            tabValue: index
        })
    }

    render() {

        const values = {
            campaign: 0,
            single: 1
        }

        const { events, isLoading } = this.props;

        return (
            <Fragment>
                <AppBar position="static" color="default">
                    <Tabs
                        value={this.state.tabValue}
                        onChange={this.tabHandler}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth" >
                        <Tab className={styles.tabLabel}
                            label={
                                <Fragment>
                                    <FormControlLabel
                                        value={String(values.campaign)}
                                        control={<Radio color="primary" />}
                                        label="Campaign"
                                        labelPlacement="end"
                                        checked={this.state.tabValue === values.campaign}
                                    />
                                    <Typography className={styles.tabLabelHelp}>
                                        A bunch of requests by previously added contacts or CSV upload
                                        </Typography>
                                </Fragment>
                            }
                        />
                        <Tab className={styles.tabLabel}
                            label={
                                <Fragment>
                                    <FormControlLabel
                                        value={String(values.campaign)}
                                        control={<Radio color="primary" />}
                                        label="Single Request"
                                        labelPlacement="end"
                                        checked={this.state.tabValue === values.single}
                                    />
                                    <Typography className={styles.tabLabelHelp}>
                                        Select the customer and send feedback request.
                                    </Typography>
                                </Fragment>
                            }
                        />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={'x'}
                    index={this.state.tabValue}
                    onChangeIndex={this.tabHandlerIndex}
                    className={styles.tabsContainer}>
                    <CampaignTab
                        isLoading={isLoading}
                        dir="ltr"
                        tabHandler={() => this.tabHandlerIndex(0)}
                        active={this.state.tabValue === values.campaign}
                        events={(data) => events(data)} />
                    <SingleRequestTab
                        isLoading={isLoading}
                        dir="ltr"
                        tabHandler={() => this.tabHandlerIndex(1)}
                        active={this.state.tabValue === values.single}
                        events={(data) => events(data)} />
                </SwipeableViews>
            </Fragment>
        )
    }
}

export default NewRequestTab;
