import React, { Component, Fragment } from 'react';
import { StripeElementWrapper } from '../../common';
import { Grid, Card, CardContent, Button, Chip } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { businessCreator } from '../../../actions/businessActions';
import { messageFetcher } from '../../../actions/messageActions';
import { cardCreator } from '../../../actions/paymentActions';
import history from '../../../helpers/history';
import styles from './PaymentForm.module.scss';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    injectStripe,
} from 'react-stripe-elements';
import Preloader from '../Preloader/Preloader';

// This component used for "/businesses/:id" route component
class _PaymentForm extends Component {

    businessCreatorHandler = () => {
        const { newBusiness, currentBusiness, paymentIsLoading } = this.props;
        if (Object.keys(newBusiness).length !== 0) {
            this.props.businessCreator(newBusiness);
        } else if (!paymentIsLoading && Object.keys(currentBusiness).length) {
            history.push(`/businesses/`);
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.props.stripe) {
            this.props.stripe.createToken()
                .then(res => {
                    if (res.error) {
                        this.props.messageFetcher(
                            'error', `Stripe error: ${res.error.message}`
                        );
                    }

                    if (res.token) {
                        this.props.messageFetcher(
                            'success', `Your ${res.token.card.funding} ${res.token.card.brand} is added.`
                        );

                        this.props.cardCreator({
                            stripe_token: res.token.id
                        }, this.businessCreatorHandler);
                    }
                })
                .catch(err => {
                    this.props.messageFetcher(
                        'error', 'An error occurred. Please try again or contact the administrator!'
                    );
                });
        }
    }

    handleFieldError = (err) => {
        if (err) {
            this.props.messageFetcher('warning', err);
        }
    }

    getCardLogoByNameHandler = (paymentCard) => {
        if (Object.keys(paymentCard.last4).length) {
            let logoImg = ''
            if (paymentCard.card_brand !== 'Unknown') {
                logoImg = <img
                    width="50"
                    src={`${process.env.PUBLIC_URL}/static/panel/img/payment_card_logos/icon_card_${String(paymentCard.card_brand).replace(' ', '').toLowerCase()}.svg`}
                    alt={paymentCard.card_brand} />;
            }
            return (
                <Fragment>
                    {logoImg}
                    Your payment method is&nbsp;<b>{paymentCard.card_brand}</b>, end with&nbsp;<b>{paymentCard.last4}</b>.
                </Fragment>
            )
        } else {
            return 'You have not any payment method. To add one, please enter you card information.'
        }
    }

    render() {

        const {
            card__container,
            card,
            card__provider,
            card__content,
            button__cancel,
            button__container,
            card__input,
            card__chip
        } = styles;

        const { paymentCard, paymentIsLoading, businessesIsPosting } = this.props;

        return (
            <form onSubmit={this.handleSubmit}>
                <Grid item md={12} className={card__container}>
                    <Card className={card}>
                        {paymentIsLoading || businessesIsPosting || !Object.keys(paymentCard).length ?
                            <Preloader height="287px" /> :
                            <CardContent className={card__content}>
                                <Grid item md={12}>
                                    <Chip
                                        className={card__chip}
                                        label={this.getCardLogoByNameHandler(paymentCard)}
                                        variant="outlined" />
                                </Grid>
                                <Grid className={card__input}>
                                    <StripeElementWrapper
                                        label="Card Number"
                                        component={CardNumberElement}
                                        fieldMessage={this.handleFieldError}
                                    />
                                </Grid>
                                <Grid className={card__input}>
                                    <StripeElementWrapper
                                        label="Expiry Date"
                                        component={CardExpiryElement}
                                        fieldMessage={this.handleFieldError} />
                                </Grid>
                                <Grid className={card__input}>
                                    <StripeElementWrapper
                                        label="CVC"
                                        component={CardCVCElement}
                                        fieldMessage={this.handleFieldError} />
                                </Grid>
                                <a className={card__provider} href="https://stripe.com/">
                                    <img width="119" height="26" src={`${process.env.PUBLIC_URL}/static/panel/img/powered_by_stripe.svg`} alt="Powered by Stripe" />
                                </a>
                            </CardContent>}
                    </Card>
                </Grid>
                <Grid item md={12} className={button__container}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSubmit}>
                        Add Card
                    </Button>
                </Grid>
            </form>
        )
    }
}

const mapStateToProps = ({ messages, businesses, plans, payment }) => {
    return {
        messages,
        businesses: businesses.list,
        businessesIsPosting: businesses.isPosting,
        newBusiness: businesses.newBusiness,
        currentBusiness: businesses.currentBusiness,
        paymentCard: payment.card,
        paymentIsLoading: payment.isLoading
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        businessCreator,
        messageFetcher,
        cardCreator
    }, dispatch)
}

const PaymentForm = injectStripe(connect(mapStateToProps, mapDispatchToProps)(_PaymentForm))

export default PaymentForm;