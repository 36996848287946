import React, { Component } from 'react';
import { PageHeader, NewRequestTab, Preloader } from '../../common/index';
import { Grid, Card, CardContent, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { messageFetcher } from '../../../actions/messageActions';
import { requestCreator } from '../../../actions/requestActions';
import styles from './NewRequest.module.scss';

class NewRequest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {}
        }
        document.title = `${global.appName} | ${this.props.title}`;
    }

    componentWillReceiveProps = (props) => {
        document.title = `${global.appName} | ${this.props.title}`;
    }

    requestDataHandler = (data) => {
        this.setState({ data });
    }

    requestSubmitHandler = (currentBusiness, data) => {
        if (Object.keys(data).length) {
            const newData = {
                ...data,
                agent: Array.isArray(data.agent) ? data.agent.map(item => item.id)[0] : data.agent
            };
            this.props.requestCreator(currentBusiness.id, newData);
        } else {
            this.props.messageFetcher('warning', 'You must choose at least one customer to sbumit a request!');
        }
    }

    render() {

        const { grid, card, card__content, button } = styles;

        const { data } = this.state;

        const { currentBusiness, isLoading } = this.props;

        if (!Object.keys(currentBusiness).length) return <Preloader />;

        return (
            <Grid container spacing={16} className={grid} >
                <Grid item md={12}>
                    <PageHeader title="Add New Request" description="Please select you request type. Here you can choose to have a single request or a campaign." />
                </Grid>
                <Grid item md={12}>
                    <Card className={card}>
                        <CardContent className={card__content}>
                            <NewRequestTab
                                isLoading={isLoading}
                                events={(data) => this.requestDataHandler(data)} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={12}>
                    <Button
                        className={button}
                        variant="contained"
                        color="primary"
                        disabled={isLoading}
                        onClick={() => this.requestSubmitHandler(currentBusiness, data)}>
                        {isLoading ? <Preloader float height="15px" size="15px" /> : 'Submit Request'}
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = ({ businesses, requests }) => {
    return {
        isLoading: requests.isLoading,
        currentBusiness: businesses.currentBusiness,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        messageFetcher,
        requestCreator
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(NewRequest);