import types from '../actions/types';

const initialState = {
    isAuthenticated: false,
    isLoading: false,
    profile: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.user.START_LOADING:
            return {
                ...state,
                isLoading: true
            }

        case types.user.END_LOADING:
            return {
                ...state,
                isLoading: false
            }

        case types.user.FETCH_USER:
            return {
                isAuthenticated: action.payload.data.isAuthenticated,
                profile: action.payload.data.profile
            };

        case types.user.UPDATE_USER_STATE:
            return {
                ...state,
                profile: action.payload.data.profile
            };

        case types.user.SIGNOUT_USER:
            return initialState;

        default:
            return state;
    }
}