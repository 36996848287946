import types from "./types";
import api, { apiValues } from "./api";
import { messages } from "./messages";
import { messageText } from "../helpers/messages";

export const requestFetcher = (businessId, limit = 10, offset = 0) => {
  return dispatch => {
    if (offset === 0) {
      dispatch({ type: types.request.DELETE_REQUESTS });
    }
    dispatch({ type: types.request.START_LOADING });
    api
      .get(
        apiValues.url.API_URL +
          apiValues.url.BUSINESSES_URL +
          businessId +
          apiValues.url.REQUESTS +
          `?limit=${limit}&offset=${offset}`
      )
      .then(res => {
        dispatch({
          type: types.request.FETCH_REQUESTS,
          payload: {
            data: {
              limit,
              offset,
              count: res.data.content.count,
              list: res.data.content.results,
            },
          },
        });
        dispatch({ type: types.request.END_LOADING });
      })
      .catch(err => {
        dispatch({ type: types.business.END_LOADING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "error",
              text: messages.error.REQUEST_FETCH_ERROR,
            },
          },
        });
      });
  };
};

export const singleRequestFetcher = (
  businessId,
  requestId,
  feedbacksLoader
) => {
  return dispatch => {
    dispatch({ type: types.request.START_LOADING });
    api
      .get(
        apiValues.url.API_URL +
          apiValues.url.BUSINESSES_URL +
          businessId +
          apiValues.url.REQUESTS +
          requestId +
          "/"
      )
      .then(res => {
        dispatch({
          type: types.request.FETCH_SINGLE_REQUEST,
          payload: {
            data: res.data.content,
          },
        });
        dispatch({ type: types.request.END_LOADING });
        if (res.data.status === 200) feedbacksLoader(businessId, requestId);
        if (res.data.status === 404)
          api.redirect("/" + businessId + apiValues.url.REQUESTS);
      })
      .catch(err => {
        dispatch({ type: types.request.END_LOADING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "warning",
              text: messages.error.SINGLE_REQUEST_FETCH_ERROR,
            },
          },
        });
      });
  };
};

export const requestSearchFetcher = (
  businessId = null,
  limit = 10,
  offset = 0,
  searchTerm,
  clearLastSearch = false,
  sentMethod = "",
  type = "",
  feedbackMethod = ""
) => {
  return dispatch => {
    dispatch({ type: types.request.START_LOADING });
    api
      .get(
        apiValues.url.API_URL +
          apiValues.url.BUSINESSES_URL +
          businessId +
          apiValues.url.REQUESTS +
          `?search=${searchTerm}&limit=${limit}&offset=${offset}` +
          sentMethod +
          type +
          feedbackMethod
      )
      .then(res => {
        if (clearLastSearch) {
          dispatch({ type: types.request.DELETE_SEARCH_RESULTS });
        }
        dispatch({
          type: types.request.FETCH_SEARCH_RESULTS,
          payload: {
            data: {
              limit,
              offset,
              count: res.data.content.count,
              list: res.data.content.results,
            },
          },
        });
        dispatch({ type: types.request.END_LOADING });
      })
      .catch(err => {
        dispatch({ type: types.request.END_LOADING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "warning",
              text: messages.error.REQUEST_FETCH_ERROR,
            },
          },
        });
      });
  };
};

export const requestSearchRemover = () => {
  return dispatch => {
    dispatch({ type: types.request.START_LOADING });
    dispatch({ type: types.request.DELETE_SEARCH_RESULTS });
    dispatch({ type: types.request.END_LOADING });
  };
};

export const requestPreloaderStarter = () => {
  return dispatch => {
    dispatch({ type: types.request.START_LOADING });
  };
};

export const requestPreloaderFinisher = () => {
  return dispatch => {
    dispatch({ type: types.request.END_LOADING });
  };
};

export const requestCreator = (businessId, data) => {
  return dispatch => {
    dispatch({ type: types.request.START_LOADING });
    api
      .post(
        apiValues.url.API_URL +
          apiValues.url.BUSINESSES_URL +
          businessId +
          apiValues.url.REQUESTS,
        data
      )
      .then(res => {
        switch (res.data.status) {
          case 201:
            dispatch({
              type: types.request.CREATE_REQUESTS,
              payload: {
                data: res.data.content,
              },
            });
            dispatch({
              type: types.message.SHOW_MESSAGE,
              payload: {
                data: {
                  type: res.data.status,
                  text: messageText("request", "create"),
                },
              },
            });
            dispatch({ type: types.request.END_LOADING });
            return api.redirect(`/${businessId}/requests/`);

          case 400:
            dispatch({
              type: types.message.SHOW_MESSAGE,
              payload: {
                data: {
                  type: res.data.status,
                  text: res.data.message,
                },
              },
            });
            dispatch({ type: types.request.END_LOADING });
            break;

          default:
            break;
        }
      })
      .catch(err => {
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "error",
              text: messages.error.REQUEST_CREATE_ERROR,
            },
          },
        });
        dispatch({ type: types.request.END_LOADING });
      });
  };
};
