import React, { Component } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, withMobileDialog, IconButton } from '@material-ui/core';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import styles from './ImageModa.scss';

class ImageModal extends Component {

  render() {

    const {
      title,
      open,
      cancelAction,
      imageUrl,
      fullScreen
    } = this.props;

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="responsive-dialog-title">
          <DialogTitle id="email-template-modal-title">
          <span>{title}</span>
            <IconButton onClick={cancelAction}>
                <CloseOutlined />
            </IconButton>
            </DialogTitle>
          <DialogContent>
            <img alt={title} src={imageUrl} />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(ImageModal);
