
import React, { Component } from 'react';
import {
    FormControl,
    TextField
} from '@material-ui/core';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import styles from './SearchInput.module.scss';

class SearchInput extends Component {

    render() {

        const { placeholder, events, searchTerm } = this.props;

        const label = <div>{placeholder} <SearchOutlined className={styles.searchIcon} /></div>

        return (
            <div className={styles.searchBase}>
                <div className={styles.searchZone}>
                    <FormControl className={styles.searchFormControl}>
                        <TextField
                            className={styles.searchControlInput}
                            id="search-input"
                            value={searchTerm || ''}
                            label={label}
                            onChange={(e) => events(e, 'searchTerm')}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </FormControl>
                </div>
            </div>
        );
    }
}

export default SearchInput;