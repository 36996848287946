import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Drawer,
    Paper,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    ButtonBase
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { sidebarItems } from '../../../paths/index';
import './Sidebar.scss';

const styles = theme => ({
    root: {
        display: 'flex',
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        flexDirection: 'column'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    content: {
        flexGrow: 1
    },
    nested: {
        paddingLeft: theme.spacing.unit * 2.5
    },
    toolbar: theme.mixins.toolbar,
    primary: {
        fontSize: 10
    },
    secondary: {
        fontSize: 10,
        paddingLeft: 0
    }
});

class Sidebar extends Component {

    state = {
        open: sidebarItems
    }

    handleClick = (item) => {
        this.setState({
            open: item
        });
    }

    renderSidebar = (classes, currentBusiness) => {
        return sidebarItems.map((item, i) => {
            let Icon = item.icon;
            return (
                <ExpansionPanel className="sidebar-panel" key={item.name + i}>
                    <ExpansionPanelSummary className="list-header" expandIcon={<ExpandMore />}>
                        <ButtonBase
                            focusRipple
                            focusVisibleClassName={classes.focusVisible}>
                            <div className="sidebar-icon">
                                <Icon />
                            </div>
                            {item.name}
                        </ButtonBase>
                    </ExpansionPanelSummary>
                    {
                        item.subItems.length ?
                            <ExpansionPanelDetails className="sidebar-collapse">
                                {item.subItems.map(subItem => {
                                    Icon = subItem.icon;
                                    return (
                                        <ButtonBase
                                            key={subItem.name + i}
                                            focusRipple
                                            focusVisibleClassName={classes.focusVisible}>
                                            <Link key={subItem.name + i} className="sidebar-item" to={{
                                                    pathname: `/${currentBusiness.id + subItem.url}`,
                                                }} >
                                                <div className="sidebar-icon">
                                                    <Icon />
                                                </div>
                                                    {subItem.name}
                                            </Link>
                                        </ButtonBase>
                                    )
                                })} </ExpansionPanelDetails> : ''
                        }
                    </ExpansionPanel>
            );
        });
    }

    render() {

        const { classes } = this.props;

        const currentBusiness = this.props.currentBusiness;

        return (
            <Drawer className="sidebar" variant="permanent">
                <Paper className="sidebar-paper">
                    <div className={classes.toolbar} />
                    {this.renderSidebar(classes, currentBusiness)}
                </Paper>
            </Drawer>
        )
    }
}

const mapStateToProps = ({ businesses }) => {
    return {
        currentBusiness: businesses.currentBusiness,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(Sidebar));