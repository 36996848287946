import React from 'react';
import styles from './PaymentTable.module.scss';
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Paper, Tooltip, RootRef } from '@material-ui/core';
import { Preloader } from '../index';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { paymentFetcher } from '../../../actions/paymentActions';

let counter = 0;
function createData(name, plan, date, amount, description) {
	counter += 1;
	return { id: counter, name, plan, date, amount, description };
}

function desc(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function stableSort(array, cmp) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
	{ id: 'name', numeric: false, label: 'Business' },
	{ id: 'plan', numeric: false, label: 'Plan' },
	{ id: 'date', numeric: false, label: 'Date' },
	{ id: 'amount', numeric: true, label: 'Amount' },
	{ id: 'description', numeric: false, label: 'Description' }
];

class EnhancedTableHead extends React.Component {
	createSortHandler = property => event => {
		this.props.onRequestSort(event, property);
	};

	render() {
		const { order, orderBy } = this.props;

		const { table__th } = styles;

		return (
			<TableHead>
				<TableRow>
					{rows.map(
						row => (
							<TableCell
								key={row.id}
								align={row.numeric ? 'right' : 'left'}
								padding={row.disablePadding ? 'none' : 'default'}
								sortDirection={orderBy === row.id ? order : false}
								className={table__th}
							>
								<Tooltip
									title="Sort"
									placement={row.numeric ? 'bottom-end' : 'bottom-start'}
									enterDelay={300}
								>
									<TableSortLabel
										active={orderBy === row.id}
										direction={order}
										onClick={this.createSortHandler(row.id)}
									>
										{row.label}
									</TableSortLabel>
								</Tooltip>
							</TableCell>
						),
						this,
					)}
				</TableRow>
			</TableHead>
		);
	}
}

class PaymentTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentCustomer: '',
			editable: '',
			deletable: false,
			order: 'asc',
			orderBy: 'amount',
			selected: [],
			page: 0,
			rowsPerPage: 0,
		}
		window.addEventListener('scroll', this.handleScroll);
		this.paymentRow = React.createRef();
	}

	componentDidMount = () => {
		this._isMounted = true;
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount = () => {
		window.removeEventListener('scroll', this.handleScroll);
		this._isMounted = false;
	}

	handleScroll = () => {
		if (this.paymentRow.current) {
			const bottom = this.paymentRow.current.lastChild.getBoundingClientRect().bottom - 70 <= window.innerHeight;
			this.props.scrollCalculator(bottom);
		}
	}

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = 'desc';

		if (this.state.orderBy === property && this.state.order === 'desc') {
			order = 'asc';
		}

		this.setState({ order, orderBy });
	};

	handleClick = (event, id) => {
		const { selected } = this.state;
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		this.setState({ selected: newSelected });
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value });
	};

	isSelected = id => this.state.selected.indexOf(id) !== -1;


	render() {
		const { order, orderBy, selected, rowsPerPage, page } = this.state;

		const { isLoading, payments, searchResults, currentBusiness, inSearch, validSearchTerm } = this.props;

		const emptyRows = rowsPerPage - Math.min(rowsPerPage, payments.length - page * rowsPerPage);

		const { table, table__row, table__td, table__td__success, table__td__unsuccess } = styles;

		let paymentsToShow = payments;

		if (inSearch) paymentsToShow = searchResults;

		return (
			<Paper className={table}>
				<div >
					<Table aria-labelledby="tableTitle">
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={this.handleSelectAllClick}
							onRequestSort={this.handleRequestSort}
							rowCount={paymentsToShow.length}
						/>
						<TableBody>
							{stableSort(paymentsToShow, getSorting(order, orderBy))
								/* .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */
								.map(payment => {
									const isSelected = this.isSelected(payment.id);
									return (
										<RootRef rootRef={this.paymentRow}
											key={payment.id}>
											<TableRow
												hover
												onClick={event => this.handleClick(event, payment.id)}
												aria-checked={isSelected}
												tabIndex={-1}
												selected={isSelected}
												className={table__row}>
												<TableCell className={table__td} scope="row">
													{payment.business_name}
												</TableCell>
												<TableCell className={table__td} scope="row">
													{payment.plan_name}
												</TableCell>
												<TableCell className={table__td}>
													{payment.created_on}
												</TableCell>
												<TableCell className={table__td}>
													${payment.amount}
												</TableCell>
												<TableCell className={table__td}>
													{payment.description.length ? payment.description : '-'}
													{/* <span className={table__td__success}>Successful</span>
														: <span className={table__td__unsuccess}>Unsuccessful</span>} */}
												</TableCell>
											</TableRow>
										</RootRef>
									);
								})}
							{isLoading ? <TableRow>
								<TableCell className={table__td} scope="row" colSpan={5}>
									<Preloader float />
								</TableCell>
							</TableRow> : null}
							{!isLoading && !paymentsToShow.length ? <TableRow>
								<TableCell className={table__td} scope="row" colSpan={5}>
									{inSearch ? (validSearchTerm ? 'No payment(s) found for this search.' : 'Please enter 3 characters at least to start searcing...') : 'No payment(s) added to this business yet.'}
								</TableCell>
							</TableRow> : null}
							{emptyRows > 0 && (
								<TableRow style={{ height: 49 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</div>
			</Paper>
		);
	}
}

const mapStateToProps = ({ payment }) => {
	return {
		isLoading: payment.isLoading
	}
}

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({

	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTable);
