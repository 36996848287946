import React, { Component } from "react";
import {
  Preloader,
  PageHeader,
  SingleContactZone,
  ContactDropZone,
} from "../../common/index";
import {
  Grid,
  Card,
  CardContent,
  FormControlLabel,
  Typography,
  Radio,
  Button,
  Tooltip,
  Zoom,
  Link,
} from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { agentCreator } from "../../../actions/agentActions";
import { messageFetcher } from "../../../actions/messageActions";
import styles from "./NewAgent.module.scss";

class NewAgent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        first_name: "",
        last_name: "",
        email: "",
      },
      contactType: "single-contacts",
      hasError: [],
    };
    document.title = `${global.appName} | ${this.props.title}`;
  }

  componentWillReceiveProps = props => {
    document.title = `${global.appName} | ${this.props.title}`;
  };

  contactHandler = e => {
    this.setState({
      contactType: e.target.value,
    });
  };

  tabHandler = (event, tabValue) => {
    this.setState({
      tabValue,
    });
  };

  inputHandler = (fieldName, fieldLabel, e, validationType) => {
    const inputValue = e.target ? e.target.value : e;
    this.setState(
      {
        ...this.state,
        data: {
          ...this.state.data,
          [fieldName]: inputValue,
        },
      },
      () =>
        this.inputErrorHandler(
          fieldName,
          fieldLabel,
          inputValue,
          validationType
        )
    );
  };

  inputErrorHandler = (fieldName, fieldLabel, inputValue, validationType) => {
    const { messageFetcher } = this.props;
    const hasError = [];
    validationType.map(validationItem => {
      switch (validationItem.type) {
        case "required":
          if (!inputValue.length) {
            messageFetcher("warning", `${fieldLabel} Could not be empty!`);
            hasError.push({ type: validationItem.type, field: fieldName });
          }
          break;

        case "phone":
          const numberRegex = /[+]?(\d{1,4}(\s|-)?)?(((\(\d{2,4}\)|\d{2,4})(\s|-)?){3}|(\d{5,10}))/;
          if (!numberRegex.test(inputValue)) {
            messageFetcher("warning", `Please enter a valid ${fieldLabel}!`);
            hasError.push({ type: validationItem.type, field: fieldName });
          }
          break;

        case "email":
          const emailRegex = /^[a-zA-Z0-9.!#$%&’+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/;
          if (!emailRegex.test(inputValue)) {
            hasError.push({ type: validationItem.type, field: fieldName });
          }
          break;

        case "minLength":
          if (inputValue.length < validationItem.value) {
            messageFetcher(
              "warning",
              `Min characters for ${fieldLabel} is ${validationItem.value}`
            );
            hasError.push({ type: validationItem.type, field: fieldName });
          }
          break;

        case "maxLength":
          if (inputValue.length > validationItem.value) {
            messageFetcher(
              "warning",
              `Max characters for ${fieldLabel} is ${validationItem.value}`
            );
            hasError.push({ type: validationItem.type, field: fieldName });
          }
          break;

        default:
          break;
      }
    });
    const oldHasError = this.state.hasError.filter(
      errorItem => errorItem.field !== fieldName
    );
    this.setState({ hasError: [...oldHasError, ...hasError] });
  };

  agentCreatorHandler = (e, currentBusiness) => {
    e.preventDefault();
    if (this.state.contactType === "single-contacts") {
      const { first_name, last_name, email } = this.state.data;

      const data = { first_name, last_name, email };

      this.setState(
        {
          ...this.state,
          data: {
            first_name: "",
            last_name: "",
            email: "",
          },
        },
        this.props.agentCreator(currentBusiness.id, data, this.props.count + 1)
      );
    }
  };

  render() {
    const { section__card, section__submit, requestField__help } = styles;

    const { first_name, last_name, email } = this.state.data;

    const { hasError } = this.state;

    const { isLoading, isPosting, currentBusiness } = this.props;

    if (isLoading || !Object.keys(currentBusiness).length)
      return <Preloader inWindow />;

    return (
      <Grid container spacing={16}>
        <Grid item md={12}>
          <PageHeader
            title="Add New Agent"
            description="Please enter the information of the agent or upload a CSV file."
          />
        </Grid>
        <Grid item md={12}>
          <Card className={section__card}>
            <CardContent>
              <Grid container spacing={8}>
                <Grid item md={6}>
                  <FormControlLabel
                    value="single-contacts"
                    control={<Radio color="primary" />}
                    label="Add Agent"
                    labelPlacement="end"
                    onClick={this.contactHandler}
                    checked={this.state.contactType === "single-contacts"}
                  />
                  <Typography className={requestField__help}>
                    You can multi check the select box
                  </Typography>
                  <Grid item md={12}>
                    <SingleContactZone
                      active={this.state.contactType === "single-contacts"}
                      action={(fieldName, fieldLabel, e, validationType) =>
                        this.inputHandler(
                          fieldName,
                          fieldLabel,
                          e,
                          validationType
                        )
                      }
                      isLoading={isPosting}
                      hasError={hasError}
                      firstName={first_name}
                      lastName={last_name}
                      email={email}
                      messageFetcher={this.props.messageFetcher}
                    />
                  </Grid>
                </Grid>
                <Grid item md={6}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    placement="top"
                    title="This feature is disabled for now, we will add it in the future."
                  >
                    <FormControlLabel
                      value="upload-contacts"
                      control={<Radio color="primary" />}
                      label="Upload CSV"
                      labelPlacement="end"
                      // onClick={this.contactHandler}
                      disabled
                      checked={this.state.contactType === "Upload CSV"}
                    />
                  </Tooltip>
                  <Typography className={requestField__help}>
                    The CSV file should be exactly like
                    <Link
                      href={`${process.env.PUBLIC_URL}/static/panel/files/templates/csv-example-template.csv`}
                    >
                      Download example
                    </Link>
                    .
                  </Typography>
                  <Grid item md={12}>
                    <ContactDropZone
                      active={this.state.contactType === "upload-contacts"}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Button
                    className={section__submit}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={
                      !first_name.length ||
                      !last_name.length ||
                      !email.length ||
                      hasError.length
                        ? true
                        : false
                    }
                    onClick={e => this.agentCreatorHandler(e, currentBusiness)}
                  >
                    Add Agent
                  </Button>
                </Grid>
                <Typography className={requestField__help}>
                  * You can send a review request from
                  <Link to="#" onClick={this.tabHandler}>
                    Add New Request
                  </Link>
                </Typography>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = ({ businesses, agents }) => {
  return {
    currentBusiness: businesses.currentBusiness,
    isPosting: agents.isPosting,
    count: agents.count,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      agentCreator,
      messageFetcher,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewAgent);
