import React from 'react';
import { Card, CardContent, Typography, ButtonBase } from '@material-ui/core';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import { Link } from 'react-router-dom';
import styles from './AddBusinessCard.module.scss';

const AddBusinessCard = (props) => {
	return (
		<Link className="containerLink" to="/businesses/add">
			<Card className={styles.addBusinessCard}>
				<ButtonBase className={styles.cardEffect} component="div">
					<CardContent className={`ripple ${styles.cardContent}`}>
						<AddCircleOutline className={styles.cardIcon} />
						<Typography className={styles.cardTitle}>
							Add New Business
						</Typography>
					</CardContent>
				</ButtonBase>
			</Card>
		</Link>
	);
}

export default AddBusinessCard;