
import React, { Component } from 'react';
import { FormControl, InputLabel, Input, Select, MenuItem, Checkbox, ListItemText, TextField } from '@material-ui/core';
import { Preloader } from '../index';
import styles from './RequestFilter.module.scss';

class RequestFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedItems: []
        }
    }

    selectChangeHandler = (e, events, selectedItems, list, selectAll) => {
        if (Array.isArray(e.target.value) && e.target.value.includes(selectAll)) {
            if (selectedItems.length === list.length) {
                this.setState({
                    selectedItems: []
                }, () => events(this.state.selectedItems));
            } else {
                this.setState({
                    selectedItems: list
                }, () => events(this.state.selectedItems));
            }
        } else {
            let targetIsIncluded;
            if (!this.props.multiple) {
                targetIsIncluded = selectedItems.includes(e.target.value);
                if (targetIsIncluded) {
                    this.setState({
                        selectedItems: ''
                    }, () => events(this.state.selectedItems));
                } else {
                    this.setState({
                        selectedItems: e.target.value
                    }, () => events(this.state.selectedItems));
                }
            } else {
                targetIsIncluded = selectedItems.includes(e.target.value);
                if (targetIsIncluded) {
                    this.setState({
                        selectedItems: []
                    }, () => events(this.state.selectedItems));
                } else {
                    this.setState({
                        selectedItems: [...e.target.value]
                    }, () => events(this.state.selectedItems));
                }
            }
        }
    }

    render() {

        const { selectedItems } = this.state;

        const { label, multiple, events, list, searchable, isLoading } = this.props;

        const {
            requestFilter__formControl,
            dropdown__preloader
        } = styles;

        const selectAll = String('selectAll');

        return (
            <div className={styles.requestFilter}>
                <FormControl className={requestFilter__formControl}>
                    <InputLabel htmlFor="select-multiple-checkbox">{label}</InputLabel>
                    <Select
                        disabled={!list.length}
                        multiple={multiple}
                        value={this.state.selectedItems}
                        onChange={(e) => this.selectChangeHandler(e, events, selectedItems, list, selectAll)}
                        input={<Input id="select-multiple-checkbox" />}
                        renderValue={
                            selected => !multiple ? selected : selected.map(item => item).join(', ')} >
                        {multiple ?
                            <MenuItem value={selectAll}>
                                <Checkbox className={styles.ContactListCheckbox} checked={selectedItems.length === list.length} />
                                <div className={styles.contactRow}>
                                    <ListItemText primary={this.state.selectedItems.includes(selectAll) ? `Deselect all` : `Select all`} className={styles.contactName} />
                                </div>
                            </MenuItem> : ''}
                        {list.map(item => {
                            return (
                                item !== selectAll ? <MenuItem key={item} value={item}>
                                    <Checkbox className={styles.ContactListCheckbox} checked={selectedItems.indexOf(item) !== -1} />
                                    <div className={styles.contactRow}>
                                        <ListItemText primary={item} className={styles.contactName} />
                                    </div>
                                </MenuItem> : ''
                            )
                        })}
                        {isLoading ? <Preloader size="20px" absolute transparent /> : null}
                    </Select>
                </FormControl>
            </div>
        );
    }
}

export default RequestFilter;