import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { PageHeader, SearchInput, PaymentTable, Preloader } from '../../common';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { paymentFetcher, paymentSearchFetcher, paymentSearchRemover, paymentPreloaderStarter } from '../../../actions/paymentActions';
import LibraryBooksOutlined from '@material-ui/icons/LibraryBooksOutlined';
import EmailOutlined from '@material-ui/icons/EmailOutlined';
import styles from './PaymentHistory.module.scss';

class PaymentHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            validSearchTerm: false
        }
        document.title = `${global.appName} | ${this.props.title}`;
    }

    componentWillReceiveProps = (props) => {
        document.title = `${global.appName} | ${this.props.title}`;
    }

    componentDidMount = () => {
        this._isMounted = true;
        this.initialPaymentsHandler();
    }

    componentWillUnmount = () => {
        this.setState({
            searchTerm: '',
            validSearchTerm: false
        });
        this.props.paymentSearchRemover();
        this._isMounted = false;
    }

    initialPaymentsHandler = () => {
        const limit = 10;
        const remaining = this.props.count - this.props.payments.length;
        if (this.props.count === 0) this.props.paymentFetcher(limit, this.props.offset);
        if (remaining > 0) this.handleRequests();
    }

    initialPaymentSearchHandler = () => {
        const limit = 10;
        this.props.paymentSearchFetcher(limit, this.props.searchResultsOffset, this.state.searchTerm, true);
    }

    handleRequests = (bottom = true) => {
        const limit = 10;
        const nextOffset = this.props.offset + this.props.limit;
        const remaining = this.props.count - this.props.payments.length;
        if (bottom && !this.props.paymentsIsLoading && remaining > 0) {
            this.props.paymentFetcher(limit, nextOffset);
        }
    }

    handleSearchRequests = (bottom = true, searchTerm) => {
        const limit = 10;
        const nextOffset = this.props.searchResultsOffset + this.props.searchResultsLimit;
        const remaining = this.props.searchResultsCount - this.props.searchResults.length;
        if (bottom && !this.props.paymentsIsLoading && remaining > 0) {
            this.props.paymentSearchFetcher(limit, nextOffset, searchTerm);
        }
    }

    searchHandler = (e, name) => {
        if (e.target.value.length) {
            this.setState({
                [name]: e.target.value,
            }, () => {
                if (this.state.searchTerm.length >= 3) {
                    this.setState({
                        validSearchTerm: true
                    });
                    this.props.paymentPreloaderStarter();
                    setTimeout(() => {
                        this.initialPaymentSearchHandler();
                    }, 3000);
                } else {
                    this.setState({
                        validSearchTerm: false
                    });
                }
            });
        } else {
            this.setState({
                [name]: '',
                validSearchTerm: false
            }, () => setTimeout(() => {
                this.props.paymentSearchRemover();
            }, 3000));
        }
    }

    render() {

        const { table, search } = styles;

        const { payments, currentBusiness, searchResults } = this.props;

        const { searchTerm, validSearchTerm } = this.state;

        return (
            <Grid container spacing={16} >
                <Grid item md={12} xs={12}>
                    <PageHeader
                        title="Payment History"
                        description="Here you can view your history of payments." />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Grid container className={search}>
                        <Grid item md={5} xs={12}>
                            <SearchInput
                                events={this.searchHandler}
                                placeholder="Search..."
                                searchTerm={searchTerm} />
                        </Grid>
                    </Grid>
                    <Grid container className={table}>
                        <PaymentTable
                            payments={payments}
                            searchResults={searchResults}
                            currentBusiness={currentBusiness}
                            inSearch={searchTerm.length > 0}
                            validSearchTerm={validSearchTerm}
                            scrollCalculator={(bottom) => {
                                searchTerm.length >= 3 ? this.handleSearchRequests(bottom, searchTerm) : this.handleRequests(bottom)
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = ({ businesses, payment }) => {
    return {
        businessesIsLoading: businesses.isLoading,
        paymentsIsLoading: payment.isLoading,
        currentBusiness: businesses.currentBusiness,
        payments: payment.list,
        count: payment.count,
        offset: payment.offset,
        limit: payment.limit,
        searchResults: payment.searchResults.list,
        searchResultsCount: payment.searchResults.count,
        searchResultsOffset: payment.searchResults.offset,
        searchResultsLimit: payment.searchResults.limit,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        paymentFetcher,
        paymentSearchFetcher,
        paymentSearchRemover,
        paymentPreloaderStarter
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory);