import React, { Component, Fragment } from 'react';
import { Grid, Paper, Tooltip, Zoom } from '@material-ui/core';
import { FormInput, Preloader } from '../index';
import styles from './SingleContactZone.module.scss';

class SingleContactZone extends Component {

    render() {

        const { isLoading, active, firstName, lastName, email, action, hasError, messageFetcher } = this.props;

        const { listZoneBase, listZonePaper } = styles;

        return (
            <div className={listZoneBase}>
                <Paper
                    className={active ? String([listZonePaper, styles.active]).split(',').join(' ') : listZonePaper}>
                    <Grid container spacing={8} className={styles.listZone}>
                        {!isLoading ?
                            <Fragment>
                                <Grid item md={6}>
                                    <FormInput
                                        label="First Name"
                                        value={firstName}
                                        validation
                                        validated={hasError.find(item => item.field == 'first_name') ? false : true}
                                        events={(e) => action('first_name', 'First Name', e, [{ type: 'required' }, { type: 'maxLength', value: 30 }])} />
                                </Grid>
                                <Grid item md={6}>
                                    <FormInput
                                        label="Last Name"
                                        value={lastName}
                                        validation
                                        validated={hasError.find(item => item.field == 'last_name') ? false : true}
                                        events={(e) => action('last_name', 'Last Name', e, [{ type: 'required' }, { type: 'maxLength', value: 150 }])} />
                                </Grid>
                                <Grid item md={12}>
                                    <FormInput
                                        label="Email"
                                        value={email}
                                        validation
                                        validated={hasError.find(item => item.field == 'email') ? false : true}
                                        onBlur={() => hasError.find(item => item.field == 'email') ? messageFetcher('warning', `Please enter a valid Email!`) : null}
                                        events={(e) => action('email', 'Email', e, [{ type: 'required' }, { type: 'email' }, { type: 'maxLength', value: 254 }])} />
                                </Grid>
                            </Fragment> : (active ? <Preloader float size="33px" /> : null)}
                    </Grid>
                </Paper>
            </div>
        );
    }
}

export default SingleContactZone;