import React from 'react';

const Logo = (props) => {

    const {
        name,
        height
    } = props;

    const imgPath = `${process.env.PUBLIC_URL}/static/panel/img`;

    const imgStyles = {
        height: height + 'px'
    };

    const isBeta = global.isBeta;

    const blueLogoUrl = isBeta ? `${imgPath}/upperly_beta_logo_blue.svg` : `${imgPath}/upperly_logo_blue.svg`;

    const whiteLogoUrl = isBeta ? `${imgPath}/upperly_beta_logo.svg` : `${imgPath}/upperly_logo.svg`;

    const renderLogo = () => {
        switch (props.variant) {
            case 'dark':
                return (
                    <img
                        style={imgStyles}
                        src={blueLogoUrl} alt={name} />
                )

            case 'white':
                return (
                    <img
                        style={imgStyles}
                        src={whiteLogoUrl} alt={name} />
                )

            default:
                break;
        }
    }

    return renderLogo();
}

export default Logo;