import React, { Component } from "react";
import { PageHeader, Preloader } from "../../common";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import styles from "./Dashboard.module.scss";
import CountUp from "react-countup";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    document.title = `${global.appName} | ${
      Object.keys(this.props.currentBusiness).length
        ? this.props.currentBusiness.name
        : this.props.title
    }`;
  }

  componentWillReceiveProps = props => {
    document.title = `${global.appName} | ${
      Object.keys(this.props.currentBusiness).length
        ? this.props.currentBusiness.name
        : this.props.title
    }`;
  };

  render() {
    const {
      card,
      card__even,
      card__content,
      card__title,
      card__subtitle,
      card__number,
    } = styles;

    const { currentBusiness, isLoading } = this.props;

    const {
      nps,
      weekly_nps,
      monthly_nps,
      customer_remaining,
      agent_remaining,
    } = currentBusiness;

    if (isLoading || Object.keys(currentBusiness).length === 0)
      return <Preloader inWindow />;

    const card__even__classes = String([card, card__even])
      .split(",")
      .join(" ");

    return (
      <Grid container spacing={16}>
        <Grid item md={12}>
          <PageHeader
            title="Dashboard"
            description={`Here you have a short explanation of all activities about ${currentBusiness.name}`}
          />
        </Grid>
        <Grid item md={3}>
          <Card className={card}>
            <CardContent className={card__content}>
              <Typography className={card__number}>
                <CountUp
                  start={0}
                  end={parseFloat(
                    String(nps)
                      .split("%")
                      .join("")
                  )}
                  duration={2}
                  separator=" "
                  decimals={1}
                  decimal="."
                />
              </Typography>
              <Typography className={card__title}>Overall NPS</Typography>
              <Typography className={card__subtitle}>
                <br />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3}>
          <Card className={card__even__classes}>
            <CardContent className={card__content}>
              <Typography className={card__number}>
                <CountUp
                  start={0}
                  end={parseFloat(
                    String(weekly_nps)
                      .split("%")
                      .join("")
                  )}
                  duration={2.5}
                  separator=" "
                  decimals={1}
                  decimal="."
                />
              </Typography>
              <Typography className={card__title}>Weekly NPS</Typography>
              <Typography className={card__subtitle}>
                With 5 days delay
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3}>
          <Card className={card}>
            <CardContent className={card__content}>
              <Typography className={card__number}>
                <CountUp
                  start={0}
                  end={parseFloat(
                    String(monthly_nps)
                      .split("%")
                      .join("")
                  )}
                  duration={3}
                  separator=" "
                  decimals={1}
                  decimal="."
                />
              </Typography>
              <Typography className={card__title}>Monthly NPS</Typography>
              <Typography className={card__subtitle}>
                With 5 days delay
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3}>
          <Card className={card__even__classes}>
            <CardContent className={card__content}>
              <Typography className={card__number}>
                <CountUp
                  start={0}
                  end={customer_remaining}
                  duration={3.5}
                  separator=" "
                  decimals={0}
                  decimal=""
                />
              </Typography>
              <Typography className={card__title}>
                Customer Remaining
              </Typography>
              <Typography className={card__subtitle}>
                For this business
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = ({ businesses }) => {
  return {
    isLoading: businesses.isLoading,
    currentBusiness: businesses.currentBusiness,
  };
};

export default connect(mapStateToProps)(Dashboard);
