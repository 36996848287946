import types from './types';
import api, { apiValues } from './api';
import { messages } from './messages';

// Thie function returns all plans from the API
export const plansFetcher = () => {
    return dispatch => {
        dispatch({ type: types.plan.START_LOADING });
        api.get(apiValues.url.API_URL + apiValues.url.PLANS_URL)
            .then(res => {
                dispatch({
                    type: types.plan.FETCH_PLANS,
                    payload: {
                        data: res.data.content.results
                    }
                })
                dispatch({ type: types.plan.END_LOADING });
            })
            .catch(err => {
                dispatch({
                    type: types.message.SHOW_MESSAGE,
                    payload: {
                        data: {
                            type: 'error',
                            text: messages.error.PLAN_FETCH_ERROR
                        }
                    }
                })
            })
    }
}