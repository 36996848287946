import React, { Component } from 'react';
import { Grid, Tooltip, FormControlLabel, Radio, Typography, Zoom, Link } from '@material-ui/core';
import { FormInput, ContactDropZone, ContactListZone, Preloader, ImageModal } from '../index';
import { AgentListZone } from '../../containers';
import styles from './CampaignTab.module.scss';

class CampaignTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                name: '',
                channel: 'EMAIL',
                message_format: 'NPS',
                type: 'CAMPAIGN',
                customers: [],
                agent: '',
            },
            csvUpload: 'LIST',
            addContactMode: false,
            npsTemplateModal: false,
            yesNoTemplateModal: false,
            directTemplateModal: false
        }
    }

    componentDidMount = () => {
        this.props.events(this.state.data);
    }

    requestRadioHandler = (e, name, events, isData) => {
        if (isData) {
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    [name]: e.target.value
                }
            }, () => events ? events(this.state.data) : null);
        } else {
            this.setState({
                [name]: e.target.value
            });
        }
    }

    listDataHandler = (stateName, data, events) => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [stateName]: data,
            }
        }, () => events ? events(this.state.data) : '');
    }

    templateModalHandler = (messageFormat) => {
        const { npsTemplateModal, yesNoTemplateModal, directTemplateModal } = this.state;
        switch(messageFormat) {
            case 'npsModal':
                this.setState({ npsTemplateModal: !npsTemplateModal })
                break;

                case 'yesNoModal':
                this.setState({ yesNoTemplateModal: !yesNoTemplateModal })
                break;

                case 'directModal':
                this.setState({ directTemplateModal: !directTemplateModal })
                break;
        }
    }

    render() {

        const { tab, tab__link } = styles;

        const { tabHandler, active, events, isLoading } = this.props;

        const { addContactMode, csvUpload, npsTemplateModal, yesNoTemplateModal, directTemplateModal } = this.state;

        const { name, channel, message_format, type, agent } = this.state.data;

        return (
            <Grid container className={tab} >
                {isLoading ? <Preloader absolute transparent /> : null}
                <Grid container className={styles.requestFieldSection}>
                    <Grid item md={5}>
                        <FormInput
                            value={name}
                            label="Campaign Name"
                            events={(e) => this.requestRadioHandler(e, 'name', events, true)} />
                        <Typography className={styles.requestFieldHelp}>
                            * You must choose a name for your campaign.
                            (You can find the campaign by this name later, in the
                            Request list section.)
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={styles.requestFieldSection}>
                    <Grid item md={12}>
                        <Typography variant="h4" className={styles.requestFieldTitle}>
                            Please select the method of your request.
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Grid item md={6}>
                            <FormControlLabel
                                value="EMAIL"
                                control={<Radio color="primary" />}
                                label="Email"
                                labelPlacement="end"
                                onClick={(e) => this.requestRadioHandler(e, 'channel', events, true)}
                                checked={channel === "EMAIL" ? true : false}
                            />
                            <Typography className={styles.requestFieldHelp}>
                                Will send the request by email
                            </Typography>
                        </Grid>
                        <Grid item md={6}>
                            <Tooltip TransitionComponent={Zoom} placement="top" title="This feature is disabled for now, we will add it in the future.">
                                <FormControlLabel
                                    value="SMS"
                                    control={<Radio color="primary" />}
                                    label="SMS"
                                    labelPlacement="end"
                                    // onClick={(e) => this.requestRadioHandler(e, 'channel', events)}
                                    checked={channel === "SMS"}
                                    disabled={true}
                                />
                            </Tooltip>
                            <Typography className={styles.requestFieldHelp}>
                                Will send the request by SMS
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={styles.requestFieldSection}>
                    <Grid item md={12}>
                        <Typography variant="h4" className={styles.requestFieldTitle}>
                            Please select the type of your request.
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Grid item md={6}>
                            <FormControlLabel
                                value="NPS"
                                control={<Radio color="primary" />}
                                label="NPS"
                                labelPlacement="end"
                                onClick={(e) => this.requestRadioHandler(e, 'message_format', events, true)}
                                checked={message_format === "NPS"}
                            />
                            <Typography className={styles.requestFieldHelp}>
                                An email to ask the user to choose 1 to 10 for the referral possibility, For example
                                <Link onClick={() => this.templateModalHandler('npsModal')} className={tab__link}>View example</Link>.
                            </Typography>
                            <ImageModal
                            open={npsTemplateModal}
                            title="NPS Email Template Preview"
                            cancelAction={() => this.templateModalHandler('npsModal')}
                            imageUrl={`${process.env.PUBLIC_URL}/static/panel/img/templates/upperly_nps_email_template.png`} />
                        </Grid>
                        <Grid item md={6}>
                            <FormControlLabel
                                value="YES/NO"
                                control={<Radio color="primary" />}
                                label="Yes/No"
                                labelPlacement="end"
                                onClick={(e) => this.requestRadioHandler(e, 'message_format', events, true)}
                                checked={message_format === "YES/NO"}
                            />
                            <Typography className={styles.requestFieldHelp}>
                                An email to ask that user will suggest the business to others or not. <br />
                                <Link onClick={() => this.templateModalHandler('yesNoModal')} className={tab__link}>View example</Link>.
                            </Typography>
                            <ImageModal
                                open={yesNoTemplateModal}
                                title="Yes/No Email Template Preview"
                                cancelAction={() => this.templateModalHandler('yesNoModal')}
                                imageUrl={`${process.env.PUBLIC_URL}/static/panel/img/templates/upperly_yesno_email_template.png`} />
                        </Grid>
                        <Grid item md={6}>
                            <FormControlLabel
                                value="DIRECT"
                                control={<Radio color="primary" />}
                                label="Direct"
                                labelPlacement="end"
                                onClick={(e) => this.requestRadioHandler(e, 'message_format', events, true)}
                                checked={message_format === "DIRECT"}
                            />
                            <Typography className={styles.requestFieldHelp}>
                                An email to ask directly write a review on Google <br />
                                <Link onClick={() => this.templateModalHandler('directModal')} className={tab__link}>View example</Link>.
                            </Typography>
                            <ImageModal
                            open={directTemplateModal}
                            title="Direct Email Template Preview"
                            cancelAction={() => this.templateModalHandler('directModal')}
                            imageUrl={`${process.env.PUBLIC_URL}/static/panel/img/templates/upperly_direct_email_template.png`} />
                        </Grid>
                    </Grid>
                </Grid>
                {active ?
                    <Grid container className={styles.requestFieldSection} >
                        <Grid item md={12}>
                            <Typography variant="h4" className={styles.requestFieldTitle}>
                                Please choose contact from the list of previously added contacts or upload a CSV file.
                            </Typography>
                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item md={6}>
                                <FormControlLabel
                                    value="LIST"
                                    control={<Radio color="primary" />}
                                    label="Choose from previously contacts"
                                    labelPlacement="end"
                                    onClick={(e) => this.requestRadioHandler(e, 'csvUpload', events, false)}
                                    checked={csvUpload === "LIST"}
                                />
                                <Typography className={styles.requestFieldHelp}>
                                    You can multi check the select box
                                </Typography>
                                <Grid item md={12}>
                                    <ContactListZone
                                        addContactMode={addContactMode}
                                        addContactHandler={this.singleContactFormHandler}
                                        multiple={true}
                                        active={csvUpload === 'LIST'}
                                        method={channel}
                                        events={(data) => this.listDataHandler('customers', data, events)} />
                                </Grid>
                            </Grid>
                            <Grid item md={6}>
                                <FormControlLabel
                                    value="CSV"
                                    control={<Radio color="primary" />}
                                    label="CSV Upload"
                                    labelPlacement="end"
                                    onClick={(e) => this.requestRadioHandler(e, 'csvUpload', events, false)}
                                    checked={csvUpload === "CSV"}
                                />
                                <Typography className={styles.requestFieldHelp}>
                                    The CSV file should be exactly like
                                    <Link href={`${process.env.PUBLIC_URL}/static/panel/files/templates/csv-example-template.csv`} className={tab__link}>Download example</Link>.
                                </Typography>
                                <Grid item md={12}>
                                    <ContactDropZone
                                        confirmable={false}
                                        active={csvUpload === "CSV"}
                                        events={(data) => this.listDataHandler('customers', data, events)} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    : ''}
                <Grid container className={styles.requestFieldSection} >
                    <Grid item md={12}>
                        <Typography variant="h4" className={styles.requestFieldTitle}>
                            Please choose an agent from the list.
                                </Typography>
                    </Grid>
                    <Grid item md={5}>
                        <AgentListZone
                            multiple={false}
                            events={(data) => this.listDataHandler('agent', data, events)} />
                    </Grid>
                    <Grid item md={7}></Grid>
                    <Typography className={styles.requestFieldHelp}>
                        * If you want to add justone user, from top of the page
                                <Link to="#" onClick={tabHandler}>choose Single Request</Link>
                    </Typography>
                </Grid>
            </Grid >
        )
    }
}

export default CampaignTab;
