import React, { Component } from 'react';
import { PageHeader, PaymentForm, Preloader } from '../../common';
import { Grid, CssBaseline, Button, Card, CardContent, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { plansFetcher } from '../../../actions/planActions';
import { cardFetcher } from '../../../actions/paymentActions';
import styles from './AddCard.module.scss';
import { apiValues } from '../../../actions/api';
import { Elements, StripeProvider } from 'react-stripe-elements';

// This component used for "/businesses/:id" route component
class AddCard extends Component {

    componentWillMount = () => {
        this.props.cardFetcher();
    }

    componentWillReceiveProps = (props) => {
        document.title = `${global.appName} | ${this.props.title}`;
    }

    render() {

        const stripe = window.Stripe;

        const paymentCard = this.props.paymentCard;

        const hasPaymentCard = Object.keys(paymentCard).length !== 0;

        const { isLoading } = this.props;

        const text = {
            addCardTitle: "Add Your Payment Card",
            addCardDesc: "Please add your payment card to continue adding business.",
            changeCardTitle: "Change Payment Card",
            changeCardDesc: "You can change your payment card here."
        }

        // if (isLoading) return <Preloader inWindow />;

        return (
            <Grid container spacing={16} >
                <Grid item md={12}>
                    <PageHeader
                        title={hasPaymentCard ? text.changeCardTitle : text.addCardTitle}
                        description={hasPaymentCard ? text.changeCardDesc : text.addCardDesc} />
                    <CssBaseline />
                </Grid>
                <Grid item md={6}>
                    {stripe ?
                        <StripeProvider apiKey={apiValues.keys.STRIPE_KEY}>
                            <Elements>
                                <PaymentForm />
                            </Elements>
                        </StripeProvider>
                        :
                        <Grid item md={12}>
                            <Card>
                                <CardContent>
                                    <Typography>
                                        Unfortunately, Stripe not loaded currectly. <br />
                                        Please check your connection and Try again. <br />
                                        If this happend in the future again, please contact us.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    }
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = ({ businesses, plans, payment }) => {
    return {
        plans,
        newBusiness: businesses.newBusiness,
        isLoading: payment.isLoading,
        paymentCard: payment.card
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        plansFetcher,
        cardFetcher
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCard);