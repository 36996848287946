import React, { Component } from 'react';
import { PageHeader, Auth, Logo, Preloader } from '../../common';
import { Grid, Card, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import history from '../../../helpers/history';
import styles from './NotFound.module.scss';

class NotFound extends Component {

    render() {

        const { page__container, page__header, page__vector, page__button } = styles;

        const { isLoading } = this.props;

        if (isLoading) return <Preloader inWindow />;

        return (
            <Grid container className={page__container} spacing={16} >
                <Grid item md={6}>
                    <Card className={page__header}>
                        <Grid item md={12}>
                            <PageHeader
                                title="Uh oh!"
                                description="Looks like the page you're looking for isn't working right now. Please check the URL, try hitting refresh or check it out later."
                                vCentered={true} />
                        </Grid>
                        <Grid item md={12}>
                            <Button onClick={() => history.goBack()} className={page__button}>
                                Go Back
                            </Button>
                        </Grid>         
                    </Card>
                </Grid>
                <Grid item md={6}>
                    <img
                        className={page__vector}
                        src={`${process.env.PUBLIC_URL}/static/panel/img/vector_error_page.svg`} alt="Upperly" />
                </Grid>
            </Grid>
        )
    }

}

export default withRouter(NotFound);