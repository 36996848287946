
import React, { Component } from 'react';
import { Paper, FormControl, InputLabel, Input, Select, MenuItem, Checkbox, ListItemText, Link } from '@material-ui/core';
import { Preloader, SearchInput } from '../../common';
import styles from './AgentListZone.module.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { agentFetcher, agentsRemover, agentSearchFetcher, agentSearchRemover, agentPreloaderStarter, agentPreloaderFinisher } from '../../../actions/agentActions';

class AgentListZone extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contacts: [],
            searchTerm: '',
            validSearchTerm: false
        }
        this.agentMenuItem = React.createRef();
    }

    componentDidMount = () => {
        this._isMounted = true;
        this.initialAgentsHandler();
    }

    componentWillUnmount = () => {
        this.setState({
            searchTerm: '',
            validSearchTerm: false
        }, () => this.props.agentsRemover());
        this._isMounted = false;
    }

    initialAgentsHandler = () => {
        const { currentBusiness, agents, agentsIsFetching } = this.props;
        if (Object.keys(currentBusiness).length && !agents.length && !agentsIsFetching) {
            const limit = 10;
            const businessId = this.props.currentBusiness.id;
            this.props.agentFetcher(businessId, limit, 0);
        }
    }

    initialAgentsSearchHandler = () => {
        if (Object.keys(this.props.currentBusiness).length) {
            const limit = 10;
            const businessId = this.props.currentBusiness.id;
            // const remaining = this.props.searchResultsCount - this.props.searchResults.length;
            this.props.agentSearchFetcher(businessId, limit, this.props.searchResultsOffset, this.state.searchTerm, true);
        }
    }

    handleScroll = (e) => {
        if (e.target.children.length && e.target.children[0].childElementCount > 0) {
            const bottom = e.target.scrollHeight - e.target.scrollTop <= 3 * Number(e.target.lastChild.lastChild.getBoundingClientRect().bottom);
            if (this.state.searchTerm.length >= 3) {
                this.handleSearchRequests(this.props.currentBusiness.id, bottom, this.state.searchTerm)
            } else {
                this.handleRequests(this.props.currentBusiness.id, bottom)
            }
        }
    }

    handleRequests = (businessId, bottom = true) => {
        const limit = 10;
        const nextOffset = this.props.offset + this.props.limit;
        const remaining = this.props.count - this.props.agents.length;
        if (bottom && !this.props.businessesIsLoading && !this.props.agentsIsFetching && remaining > 0) {
            this.props.agentFetcher(businessId, limit, nextOffset);
        }
    }

    handleSearchRequests = (businessId, bottom = true, searchTerm) => {
        const limit = 10;
        const nextOffset = this.props.searchResultsOffset + this.props.searchResultsLimit;
        const remaining = this.props.searchResultsCount - this.props.searchResults.length;
        if (bottom && !this.props.businessesIsLoading && !this.props.agentsIsFetching && remaining > 0) {
            this.props.agentSearchFetcher(businessId, limit, nextOffset, searchTerm);
        }
    }

    searchHandler = (e, name) => {
        if (e.target.value.length) {
            this.setState({
                [name]: e.target.value,
            }, () => {
                if (this.state.searchTerm.length >= 3) {
                    this.setState({
                        validSearchTerm: true
                    });
                    this.props.agentPreloaderStarter();
                    setTimeout(() => {
                        this.initialAgentsSearchHandler();
                        this.props.agentPreloaderFinisher();
                    }, 3000);
                } else {
                    this.setState({
                        validSearchTerm: false
                    });
                }
            });
        } else {
            this.setState({
                [name]: '',
                validSearchTerm: false
            }, () => setTimeout(() => {
                this.props.agentSearchRemover();
            }, 3000));
        }
    }

    selectChangeHandler = (e, events, contacts, list, selectAll, none) => {
        let value = e.target.value;
        const hasNone = Array.isArray(value) ? value.indexOf(none) !== 1 : null;
        if (Array.isArray(value) && hasNone !== false) value.splice(value.indexOf(none), 1);
        if (!Array.isArray(value) && value === none) value = null;
        if (Array.isArray(value) && value.indexOf(selectAll) !== -1) {
            if (contacts.length === list.length) {
                this.setState({
                    contacts: []
                }, () => events(this.state.contacts));
            } else {
                this.setState({
                    contacts: list
                }, () => events(this.state.contacts.map(item => item.id)));
            }
        } else {
            if (value !== null) {
                let targetIsIncluded;
                if (!this.props.multiple) {
                    targetIsIncluded = contacts.indexOf(value) !== -1;
                    if (targetIsIncluded) {
                        this.setState({
                            contacts: []
                        }, () => events(this.state.contacts))
                    } else {
                        this.setState({
                            contacts: [value]
                        }, () => events(this.state.contacts));
                    }
                } else {
                    targetIsIncluded = contacts.indexOf(value) !== -1;
                    if (targetIsIncluded) {
                        this.setState({
                            contacts: this.state.filter(item => item !== value)
                        }, () => events(this.state.contacts.map(item => item.id)));
                    } else {
                        this.setState({
                            contacts: [...value]
                        }, () => events(this.state.contacts.map(item => item.id)));
                    }
                }
            }
        }
    }

    dropdownHeaderHandler = (multiple, none, styles, agents, searchTerm, validSearchTerm, searchResults, addContactHandler) => {
        let shouldShowAddButton = false;
        if (!multiple) {
            shouldShowAddButton = !searchTerm.length && !agents.length;
            shouldShowAddButton = searchTerm.length > 0 && validSearchTerm && !searchResults.length;
        }
        if (shouldShowAddButton) {
            return (
                <MenuItem value={none} className={styles.contactListformControlInput}>
                    <Link onClick={() => addContactHandler(true, 'addContactMode')}>Add an Agent</Link>
                </MenuItem>
            )
        }
    }

    render() {

        const { dropdown, dropdown__preloader, dropdown__message } = styles;

        const { contacts, searchTerm, validSearchTerm } = this.state;

        const { multiple, events, agentsIsLoading, agentsIsFetching, agentsIsPosting, agents, searchResults, addContactHandler } = this.props;

        const selectAll = String("selectAll");

        const none = String("none");

        let agentsToShow = agents;

        if (searchTerm.length > 0) agentsToShow = searchResults;

        return (
            <div className={styles.listZoneBase}>
                <FormControl className={styles.contactListformControl}>
                    <InputLabel htmlFor="select-multiple-checkbox">Choose Agent</InputLabel>
                    <Select
                        // disabled={!agents.length}
                        onScroll={this.handleScroll}
                        className={dropdown}
                        multiple={multiple}
                        value={this.state.contacts}
                        onChange={(e) => this.selectChangeHandler(e, events, contacts, agentsToShow, selectAll, none)}
                        input={<Input id="select-multiple-checkbox" />}
                        renderValue={
                            selected => !multiple ? `${selected[0].first_name} ${selected[0].last_name}` : selected.map(item => `${item.first_name} ${item.last_name}`).join(', ')} >
                        {agents.length ?
                            <MenuItem value={none} className={styles.contactListformControlInput}>
                                <SearchInput
                                    className={styles.controlInput}
                                    events={this.searchHandler}
                                    searchTerm={searchTerm}
                                    placeholder="Search..." />
                            </MenuItem> : null}
                        {!agentsIsFetching && !agentsIsLoading ?
                            this.dropdownHeaderHandler(multiple, none, styles, agents, searchTerm, validSearchTerm, searchResults, addContactHandler)
                            : null}
                        {!agentsIsFetching && !agentsIsPosting && !agentsIsLoading && !agentsToShow.length ?
                            <MenuItem value={none} className={styles.contactListformControlInput}>
                                <span className={dropdown__message}>
                                    {searchTerm.length > 0 ? (validSearchTerm ? 'No agent(s) found for this search.' : 'Please enter 3 characters at least to start searching...') : 'No agent(s) added to this business yet.'}
                                </span>
                            </MenuItem> : null}
                        {multiple && agentsToShow.length ?
                            <MenuItem value={selectAll}>
                                <Checkbox className={styles.ContactListCheckbox} checked={contacts.length === agentsToShow.length} />
                                <div className={styles.contactRow}>
                                    <ListItemText primary={this.state.contacts.includes(selectAll) ? `Deselect all Agents` : `Select all Agents`} className={styles.contactName} />
                                </div>
                            </MenuItem> : ''}
                        {agentsToShow.map(item => {
                            if (item !== selectAll) {
                                return (
                                    <MenuItem key={item.id} value={item}>
                                        <Checkbox className={styles.ContactListCheckbox} checked={contacts.indexOf(item) !== -1} />
                                        <div className={styles.contactRow}>
                                            <ListItemText primary={`${item.first_name} ${item.last_name}`} className={styles.contactName} />
                                        </div>
                                    </MenuItem>
                                )
                            } return null;
                        })}
                        {agentsIsFetching || agentsIsLoading ?
                            <MenuItem key={dropdown__preloader} className={dropdown__preloader}>
                                <Preloader size="20px" />
                            </MenuItem> : null}
                    </Select>
                </FormControl>
            </div>
        );
    }
}

const mapStateToProps = ({ businesses, agents }) => {
    return {
        businessesIsLoading: businesses.isLoading,
        currentBusiness: businesses.currentBusiness,
        agentsIsLoading: agents.isLoading,
        agentsIsFetching: agents.isFetching,
        agentsIsPosting: agents.isPosting,
        agentsIsUpdating: agents.isUpdating,
        agentsIsDeleting: agents.isDeleting,
        agents: agents.list,
        count: agents.count,
        offset: agents.offset,
        limit: agents.limit,
        searchResults: agents.searchResults.list,
        searchResultsCount: agents.searchResults.count,
        searchResultsOffset: agents.searchResults.offset,
        searchResultsLimit: agents.searchResults.limit,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        agentFetcher,
        agentsRemover,
        agentSearchFetcher,
        agentSearchRemover,
        agentPreloaderStarter,
        agentPreloaderFinisher
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentListZone);