import React, { Component } from 'react';
import { PageHeader, NewBusinessCard, Map, LocalMap, Preloader } from '../../common';
import { Grid, FormGroup, FormControlLabel, Switch, Link } from '@material-ui/core';
import styles from './GoogleReviewSwitch.module.scss';

class GoogleReviewSwitch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hasReview: this.props.hasReview
        }
    }

    googleReviewHandler = (hasReview, name, events) => {
        this.setState({
            [name]: !hasReview
        }, () => events(this.state.hasReview));
    }


    render() {

        const { checkbox__label } = styles;

        const { events, disabled } = this.props;

        const { hasReview } = this.state;

        return (
            <FormGroup row>
                <FormControlLabel
                    className={checkbox__label}
                    control={
                        <Switch
                            disabled={disabled ? disabled : false}
                            checked={hasReview}
                            onChange={() => this.googleReviewHandler(hasReview, 'hasReview', events)}
                            value={hasReview}
                            color="primary"
                        />
                    } />
            </FormGroup>
        )
    }
}

export default GoogleReviewSwitch;