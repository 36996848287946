import React from 'react';
import { Button } from '@material-ui/core';
import styles from './LoadMoreButton.module.scss'

const LoadMoreButton = ({ clickHandler, isTable }) => {
    const { button, button__table } = styles;
    const buttonClasses = [button];
    if (isTable) buttonClasses.push(button__table)
    return (
        <Button onClick={clickHandler} className={String(buttonClasses).split(',').join(' ')} variant="outlined">
            Load more...
      </Button>
    );
}

export default LoadMoreButton;