import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './LocalMap.module.scss';
import {
    Button,
    Dialog,
    ListItemText,
    ListItem,
    List,
    Divider,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Slide
} from '@material-ui/core';
import { Preloader } from '../../common';
import CloseIcon from '@material-ui/icons/Close';

function Transition(props) {
    return <Slide
        {...props}
        direction="up"
        timeout={{ enter: 700, exit: 500 }} />;
}

class FullScreenDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        }
    }

    render() {

        const {
            map__iframe,
            map__appBar,
            map__confirm
        } = styles;

        const { open, closeAction } = this.props;

        const { isLoaded } = this.state;

        return (
            <div>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={closeAction}
                    TransitionComponent={Transition} >
                    <AppBar className={map__appBar}>
                        <Toolbar>
                            <Typography variant="h6" color="inherit" className={map__confirm}>
                                Search in Google Local Businesses
                            </Typography>
                            <IconButton color="inherit" onClick={closeAction} aria-label="Close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    {!isLoaded ? <Preloader fullScreen /> : ''}
                    <iframe
                        onLoad={() => this.setState({ isLoaded: true })}
                        className={map__iframe}
                        title="Service Area Business"
                        src="https://google-developers.appspot.com/my-business/content/tools/placeid-lookup"></iframe>
                </Dialog>
            </div>
        );
    }
}

export default FullScreenDialog;
