import React from 'react';
import { Grid, AppBar, Toolbar, Typography, withStyles } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { Pathbar, Userbar } from '../';
import customStyles from './Navbar.module.scss';

const styles = theme => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	content: {
		flexGrow: 1,
		padding: '15px',
	},
	toolbar: theme.mixins.toolbar,
});

const Navbar = (props) => {

	const { classes } = props;

	const { navbar__logo } = customStyles;

	const pathName = props.location.pathname;

	const isBeta = global.isBeta;

	const logoUrl = isBeta ? `${process.env.PUBLIC_URL}/static/panel/img/upperly_beta_logo.svg` : `${process.env.PUBLIC_URL}/static/panel/img/upperly_logo.svg`;

	return (
		<AppBar position="fixed" className={classes.appBar}>
			<Toolbar>
				<Link className={navbar__logo} to="/businesses/">
					<img src={logoUrl} alt="Upperly" />
				</Link>
				<Grid item md={6}>
					{pathName !== (process.env.PUBLIC_URL + '/businesses/') ? <Pathbar /> : null}
				</Grid>
				<Grid item md={6}>
					<Userbar />
				</Grid>
			</Toolbar>
		</AppBar>
	);
}

export default withStyles(styles)(withRouter(Navbar));