import React, { Component, Fragment } from 'react';
import { StripeElementWrapper, Preloader } from '../../common';
import { Grid, Card, CardContent, Button, Typography, Chip, Avatar } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { messageFetcher } from '../../../actions/messageActions';
import { cardUpdater, cardFetcher, paymentPreloaderStarter, paymentPreloaderFinisher } from '../../../actions/paymentActions';
import styles from './EditPaymentForm.module.scss';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    injectStripe,
} from 'react-stripe-elements';

// This component used for "/businesses/:id" route component
class _EditPaymentForm extends Component {

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.props.stripe) {
            this.props.paymentPreloaderStarter();
            this.props.stripe.createToken()
                .then(res => {
                    if (res.error) {
                        this.props.messageFetcher(
                            'error', res.error.message
                        );
                    }

                    if (res.token) {
                        // this.props.messageFetcher(
                        //     'success', `Your ${res.token.card.funding} ${res.token.card.brand} is added.`
                        // );

                        this.props.cardUpdater({
                            stripe_token: res.token.id
                        }, this.props.cardFetcher());
                    }
                    // this.props.paymentPreloaderFinisher();
                })
                .catch(err => {
                    this.props.paymentPreloaderFinisher();
                    this.props.messageFetcher(
                        'error', 'An error occurred. Please try again or contact the administrator!'
                    );
                });
        }
    }

    handleFieldError = (err) => {
        if (err) {
            this.props.messageFetcher('warning', err);
        }
    }

    getCardLogoByNameHandler = (paymentCard) => {
        if (paymentCard.hasOwnProperty('last4') && paymentCard.last4.length) {
            let logoImg = ''
            if (paymentCard.card_brand !== 'Unknown') {
                logoImg = <img
                    width="50"
                    src={`${process.env.PUBLIC_URL}/static/panel/img/payment_card_logos/icon_card_${String(paymentCard.card_brand).replace(' ', '').toLowerCase()}.svg`}
                    alt={paymentCard.card_brand} />;
            }
            return (
                <Fragment>
                    {logoImg}
                    Your payment method is&nbsp;<b>{paymentCard.card_brand}</b>, end with&nbsp;<b>{paymentCard.last4}</b>.
                </Fragment>
            )
        } else {
            return 'You have not any payment method. To add one, please enter you card information.'
        }
    }


    render() {

        const {
            card__container,
            card,
            card__provider,
            card__content,
            button__cancel,
            button__container,
            card__input,
            card__chip
        } = styles;

        const { isLoading, paymentCard } = this.props;

        return (
            <form onSubmit={this.handleSubmit}><Grid item md={12}>
                {!isLoading ? <Chip
                    className={card__chip}
                    label={this.getCardLogoByNameHandler(paymentCard)}
                    variant="outlined" /> :
                    <Chip className={card__chip}
                        label="Loading..."
                        variant="outlined" />}
            </Grid>
                <Grid item md={12} className={card__container}>
                    <Grid className={card__input}>
                        <StripeElementWrapper
                            label="Card Number"
                            component={CardNumberElement}
                            fieldMessage={this.handleFieldError}
                        />
                    </Grid>
                    <Grid className={card__input}>
                        <StripeElementWrapper
                            label="Expiry Date"
                            component={CardExpiryElement}
                            fieldMessage={this.handleFieldError} />
                    </Grid>
                    <Grid className={card__input}>
                        <StripeElementWrapper
                            label="CVC"
                            component={CardCVCElement}
                            fieldMessage={this.handleFieldError} />
                    </Grid>
                    <a className={card__provider} href="https://stripe.com/">
                        <img src={`${process.env.PUBLIC_URL}/static/panel/img/powered_by_stripe.svg`} alt="Powered by Stripe" />
                    </a>
                </Grid>
                <Grid item md={12} className={button__container}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSubmit}>
                        Change Card
                    </Button>
                </Grid>
            </form>
        )
    }
}

const mapStateToProps = ({ messages, payment }) => {
    return {
        messages,
        isLoading: payment.isLoading,
        paymentCard: payment.card
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        messageFetcher,
        cardUpdater,
        cardFetcher,
        paymentPreloaderStarter,
        paymentPreloaderFinisher
    }, dispatch)
}

const EditPaymentForm = injectStripe(connect(mapStateToProps, mapDispatchToProps)(_EditPaymentForm))

export default EditPaymentForm;