export const messages = {
    success: {
        USER_LOGIN_SUCCESS: 'Welcome to Upperly! Enjoy growing your business!',
        BUSINESS_DELETE_SUCCESS: 'You have successfully deleted your business.',
        CUSTOMER_DELETE_SUCCESS: 'Your have successfully deleted your customer.',
        AGENT_DELETE_SUCCESS: 'Your have successfully deleted your agent.'
    },
    warning: {
        PAYMENT_CARD_ERROR: 'You have not added any payment card yet. Please add your payment card',
        SEARCH_LENGTH_WARNING: 'You must enter at least 3 characters to search.',
    },
    error: {
        USER_LOGIN_ERROR: 'An error occurred while logging in to your profile. Please try again.',
        USER_FETCH_ERROR: 'An error occurred while getting your profile. Please try again.',
        USER_UPDATE_ERROR: 'An error occurred while updating profile. Please try again.',
        BUSINESS_FETCH_ERROR: 'An error occurred while getting your businesses. Please try again.',
        BUSINESS_CREATE_ERROR: 'An error occurred while creating your new business. Please try again.',
        BUSINESS_UPDATE_ERROR: 'An error occurred while updating your business. Please try again.',
        BUSINESS_DELETE_ERROR: 'An error occurred while deleting your business. Please try again.',
        BUSINESS_NOT_FOUND: 'No business exists with this information!',
        PLAN_FETCH_ERROR: 'An error occurred while getting plans. Please try again.',
        PAYMENTS_HISTORY_FETCH_ERROR: 'An error occurred while getting your payments history. Please try again.',
        PAYMENT_FETCH_ERROR: 'An error occurred while getting your payment card from server. Please try again.',
        PAYMENT_CREATE_ERROR: 'An error occurred while creating your payment card. Please try again.',
        CUSTOMER_FETCH_ERROR: 'An error occurred while getting your business customers. Please try again.',
        CUSTOMER_CREATE_ERROR: 'An error occurred while adding your business customer(s). Please try again.',
        CUSTOMER_UPDATE_ERROR: 'An error occurred while updating your business customer. Please try again.',
        CUSTOMER_DELETE_ERROR: 'An error occurred while deleting your business customer. Please try again.',
        AGENT_FETCH_ERROR: 'An error occurred while getting your business agents. Please try again.',
        AGENT_CREATE_ERROR: 'An error occurred while adding your business agent(s). Please try again.',
        AGENT_UPDATE_ERROR: 'An error occurred while updating your business agent. Please try again.',
        AGENT_DELETE_ERROR: 'An error occurred while deleting your business agent. Please try again.',
        REQUEST_FETCH_ERROR: 'An error occurred while getting your review request(s). Please try again.',
        SINGLE_REQUEST_FETCH_ERROR: 'An error occurred while getting your review request. Please try again.',
        REQUEST_CREATE_ERROR: 'An error occurred while sending your review request. Please try again.',
        FEEDBACK_FETCH_ERROR: 'An error occurred while getting your request feedback(s). Please try again.',
    }
}
