
import React, { Component } from 'react';
import { Paper, FormControl, InputLabel, Input, Select, MenuItem, Checkbox, ListItemText, Link } from '@material-ui/core';
import { Preloader, SearchInput } from '../index';
import styles from './ContactListZone.module.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { customerFetcher, customersRemover, customerSearchFetcher, customerSearchRemover, customerPreloaderStarter, customerPreloaderFinisher } from '../../../actions/customerActions';

class ContactListZone extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contacts: [],
            searchTerm: '',
            validSearchTerm: false
        }
        this.customerMenuItem = React.createRef();
    }

    componentDidMount = () => {
        this._isMounted = true;
        this.initialCustomersHandler();
    }

    componentWillUnmount = () => {
        this.setState({
            searchTerm: '',
            validSearchTerm: false
        }, () => this.props.customersRemover());
        this._isMounted = false;
    }

    initialCustomersHandler = () => {
        const { currentBusiness, customers, customersIsFetching } = this.props;
        if (Object.keys(currentBusiness).length && !customers.length && !customersIsFetching) {
            const limit = 10;
            const businessId = this.props.currentBusiness.id;
            this.props.customerFetcher(businessId, limit, 0);
        }
    }

    initialCustomerSearchHandler = () => {
        const { currentBusiness, customersIsFetching } = this.props;
        if (Object.keys(currentBusiness).length && !customersIsFetching) {
            const limit = 10;
            const businessId = this.props.currentBusiness.id;
            // const remaining = this.props.searchResultsCount - this.props.searchResults.length;
            this.props.customerSearchFetcher(businessId, limit, this.props.searchResultsOffset, this.state.searchTerm, true);
        }
    }

    handleScroll = (e) => {
        if (e.target.children.length && e.target.children[0].childElementCount > 0) {
            const bottom = e.target.scrollHeight - e.target.scrollTop <= 3 * Number(e.target.lastChild.lastChild.getBoundingClientRect().bottom);
            if (this.state.searchTerm.length >= 3) {
                this.handleSearchRequests(this.props.currentBusiness.id, bottom, this.state.searchTerm)
            } else {
                this.handleRequests(this.props.currentBusiness.id, bottom)
            }
        }
    }

    handleRequests = (businessId, bottom = true) => {
        const limit = 10;
        const nextOffset = this.props.offset + this.props.limit;
        const remaining = this.props.count - this.props.customers.length;
        if (bottom && !this.props.businessesIsLoading && !this.props.customersIsFetching && remaining > 0) {
            this.props.customerFetcher(businessId, limit, nextOffset);
        }
    }

    handleSearchRequests = (businessId, bottom = true, searchTerm) => {
        const limit = 10;
        const nextOffset = this.props.searchResultsOffset + this.props.searchResultsLimit;
        const remaining = this.props.searchResultsCount - this.props.searchResults.length;
        if (bottom && !this.props.businessesIsLoading && !this.props.customersIsFetching && remaining > 0) {
            this.props.customerSearchFetcher(businessId, limit, nextOffset, searchTerm);
        }
    }

    searchHandler = (e, name) => {
        if (e.target.value.length) {
            this.setState({
                [name]: e.target.value,
            }, () => {
                if (this.state.searchTerm.length >= 3) {
                    this.setState({
                        validSearchTerm: true
                    });
                    this.props.customerPreloaderStarter();
                    setTimeout(() => {
                        this.initialCustomerSearchHandler();
                        this.props.customerPreloaderFinisher();
                    }, 3000);
                } else {
                    this.setState({
                        validSearchTerm: false
                    });
                }
            });
        } else {
            this.setState({
                [name]: '',
                validSearchTerm: false
            }, () => setTimeout(() => {
                this.props.customerSearchRemover();
            }, 3000));
        }
    }

    selectChangeHandler = (e, events, contacts, list, selectAll, none) => {
        let value = e.target.value;
        const hasNone = Array.isArray(value) ? value.includes(none) : null;
        if (Array.isArray(value) && hasNone !== false) value.splice(value.indexOf(none), 1);
        if (!Array.isArray(value) && value === none) value = null;
        if (Array.isArray(value) && value.includes(selectAll)) {
            if (contacts.length === list.length) {
                this.setState({
                    contacts: []
                }, () => events(this.state.contacts));
            } else {
                this.setState({
                    contacts: list
                }, () => events(this.state.contacts.map(item => item.id)));
            }
        } else {
            if (value !== null) {
                let targetIsIncluded;
                if (!this.props.multiple) {
                    targetIsIncluded = contacts.includes(value);
                    if (targetIsIncluded) {
                        this.setState({
                            contacts: []
                        }, () => events(this.state.contacts));
                    } else {
                        this.setState({
                            contacts: [value]
                        }, () => events(this.state.contacts));
                    }
                } else {
                    targetIsIncluded = contacts.includes(value);
                    if (targetIsIncluded) {
                        this.setState({
                            contacts: this.state.contacts.filter(item => item !== value)
                        }, () => events(this.state.contacts.map(item => item.id)));
                    } else {
                        this.setState({
                            contacts: [...value]
                        }, () => events(this.state.contacts.map(item => item.id)));
                    }
                }
            }
        }
    }

    dropdownHeaderHandler = (multiple, none, styles, customers, searchTerm, validSearchTerm, searchResults, addContactHandler) => {
        let shouldShowAddButton = false;
        if (!multiple) {
            shouldShowAddButton = (!searchTerm.length && !customers.length )|| ((searchTerm.length > 0 && validSearchTerm) && !searchResults.length);
        }
        if (shouldShowAddButton) {
            return (
                <MenuItem value={none} className={styles.contactListformControlInput}>
                    <Link onClick={() => addContactHandler(true, 'addContactMode')}>Add a Customer</Link>
                </MenuItem>
            )
        }
    }

    render() {

        const { dropdown, dropdown__preloader, dropdown__message } = styles;

        const { contacts, searchTerm, validSearchTerm } = this.state;

        const { active, multiple, method, events, customersIsLoading, customersIsFetching, customersIsPosting, customers, searchResults, addContactHandler } = this.props;

        const selectAll = String("selectAll");

        const none = String("none");

        let customersToShow = customers;

        if (searchTerm.length > 0) customersToShow = searchResults;

        return (
            <div className={styles.listZoneBase}>
                <Paper className={active ? [styles.listZonePaper, styles.active].join(' ') : styles.listZonePaper}><div className={styles.listZone}>
                    <FormControl className={styles.contactListformControl}>
                        <InputLabel htmlFor="select-multiple-checkbox">Choose Customer</InputLabel>
                        <Select
                            // disabled={!customers.length}
                            onScroll={this.handleScroll}
                            className={dropdown}
                            multiple={multiple}
                            value={this.state.contacts}
                            onChange={(e) => this.selectChangeHandler(e, events, contacts, customersToShow, selectAll, none)}
                            input={<Input id="select-multiple-checkbox" />}
                            renderValue={
                                selected => !multiple ? `${selected[0].first_name} ${selected[0].last_name}` : selected.map(item => `${item.first_name} ${item.last_name}`).join(', ')} >
                            {customers.length ?
                                <MenuItem value={none} className={styles.contactListformControlInput}>
                                    <SearchInput
                                        className={styles.controlInput}
                                        events={this.searchHandler}
                                        searchTerm={searchTerm}
                                        placeholder="Search..." />
                                </MenuItem> : null}
                            {!customersIsFetching && !customersIsLoading ?
                                this.dropdownHeaderHandler(multiple, none, styles, customers, searchTerm, validSearchTerm, searchResults, addContactHandler)
                                : null}
                            {!customersIsFetching && !customersIsPosting && !customersIsLoading && !customersToShow.length ?
                                <MenuItem value={none} className={styles.contactListformControlInput}>
                                    <span className={dropdown__message}>
                                        {searchTerm.length > 0 ? (validSearchTerm ? 'No customer(s) found for this search.' : 'Please enter 3 characters at least to start searcing...') : 'No customer(s) added to this business yet.'}
                                    </span>
                                </MenuItem> : null}
                            {multiple && customersToShow.length ?
                                <MenuItem value={selectAll}>
                                    <Checkbox className={styles.ContactListCheckbox} checked={contacts.length === customersToShow.length} />
                                    <div className={styles.contactRow}>
                                        <ListItemText primary={this.state.contacts.includes(selectAll) ? `Deselect all Contacts` : `Select all Contacts`} className={styles.contactName} />
                                    </div>
                                </MenuItem> : ''}
                            {customersToShow.map(item => {
                                if (item !== selectAll) {
                                    return (
                                        <MenuItem key={item.id} value={item}>
                                            <Checkbox className={styles.ContactListCheckbox} checked={contacts.indexOf(item) !== -1} />
                                            <div className={styles.contactRow}>
                                                <ListItemText primary={`${item.first_name} ${item.last_name}`} className={styles.contactName} />
                                                {method === "EMAIL" ? <ListItemText primary={item.email} className={styles.contactEmail} /> :
                                                    <ListItemText primary={item.mobile} className={styles.contactEmail} />}
                                            </div>
                                        </MenuItem>
                                    )
                                } return null;
                            })}
                            {customersIsFetching || customersIsLoading ?
                                <MenuItem key={dropdown__preloader} className={dropdown__preloader}>
                                    <Preloader size="20px" />
                                </MenuItem> : null}
                        </Select>
                    </FormControl>
                    {/* <ul className={styles.selectedContacts}>
                                <li>
                                    {!customers.length ? `No Customer found.` : ``}
                                    {customers.length ? !contacts.length ? `No Customer selected` : `Selected Name(s):` : ''}
                                </li>
                                {this.state.contacts.length >= 1 ? contacts.map(item => (
                                    item !== selectAll ? <li key={item.id}>{`${item.first_name} ${item.last_name}`}</li> : ''
                                )) : ''}
                            </ul> */}
                    <ul className={styles.selectedContacts}>
                        {/* {this.state.contacts.length >= 1 ? contacts.map(item => (
                            item !== selectAll ? <li key={item.id}>{`${item.first_name} ${item.last_name}`}</li> : ''
                        )) : ''} */}
                        {multiple ?
                            <li>
                                {!contacts.length ? `No Customer selected` : (
                                    contacts.length > 1 ? `${contacts.length} Customers selected` : `${contacts.length} Customer selected`
                                )}
                            </li> :
                            <li>
                                {!contacts.length ? `No Customer selected` : `Customer selected`}
                            </li>}
                    </ul>
                </div>
                </Paper>
            </div>
        );
    }
}

const mapStateToProps = ({ businesses, customers }) => {
    return {
        businessesIsLoading: businesses.isLoading,
        currentBusiness: businesses.currentBusiness,
        customersIsLoading: customers.isLoading,
        customersIsFetching: customers.isFetching,
        customersIsPosting: customers.isPosting,
        customersIsUpdating: customers.isUpdating,
        customersIsDeleting: customers.isDeleting,
        customers: customers.list,
        count: customers.count,
        offset: customers.offset,
        limit: customers.limit,
        searchResults: customers.searchResults.list,
        searchResultsCount: customers.searchResults.count,
        searchResultsOffset: customers.searchResults.offset,
        searchResultsLimit: customers.searchResults.limit,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        customerFetcher,
        customersRemover,
        customerSearchFetcher,
        customerSearchRemover,
        customerPreloaderStarter,
        customerPreloaderFinisher
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactListZone);