import types from "./types";
import api, { apiValues } from "./api";
import { messages } from "./messages";
import { messageText } from "../helpers/messages";

export const customerFetcher = (businessId, limit = 10, offset = 0) => {
  return dispatch => {
    if (offset === 0) {
      dispatch({ type: types.customer.DELETE_CUSTOMERS });
    }
    dispatch({ type: types.customer.START_FETCHING });
    api
      .get(
        apiValues.url.API_URL +
          apiValues.url.BUSINESSES_URL +
          businessId +
          apiValues.url.CUSTOMERS +
          `?limit=${limit}&offset=${offset}`
      )
      .then(res => {
        dispatch({
          type: types.customer.FETCH_CUSTOMERS,
          payload: {
            data: {
              limit,
              offset,
              count: res.data.content.count,
              list: res.data.content.results,
            },
          },
        });
        dispatch({ type: types.customer.END_FETCHING });
      })
      .catch(err => {
        dispatch({ type: types.customer.END_FETCHING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "error",
              text: messages.error.CUSTOMER_FETCH_ERROR,
            },
          },
        });
      });
  };
};

export const customerSearchFetcher = (
  businessId = null,
  limit = 10,
  offset = 0,
  searchTerm,
  clearLastSearch = false
) => {
  return dispatch => {
    dispatch({ type: types.customer.START_FETCHING });
    api
      .get(
        apiValues.url.API_URL +
          apiValues.url.BUSINESSES_URL +
          businessId +
          apiValues.url.CUSTOMERS +
          `?search=${searchTerm}&limit=${limit}&offset=${offset}`
      )
      .then(res => {
        if (clearLastSearch) {
          dispatch({ type: types.customer.DELETE_SEARCH_RESULTS });
        }
        dispatch({
          type: types.customer.FETCH_SEARCH_RESULTS,
          payload: {
            data: {
              limit,
              offset,
              count: res.data.content.count,
              list: res.data.content.results,
            },
          },
        });
        dispatch({ type: types.customer.END_FETCHING });
      })
      .catch(err => {
        dispatch({ type: types.customer.END_FETCHING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "warning",
              text: messages.error.CUSTOMER_FETCH_ERROR,
            },
          },
        });
      });
  };
};

export const customerSearchRemover = () => {
  return dispatch => {
    dispatch({ type: types.customer.START_LOADING });
    dispatch({ type: types.customer.DELETE_SEARCH_RESULTS });
    dispatch({ type: types.customer.END_LOADING });
  };
};

export const customerPreloaderStarter = () => {
  return dispatch => {
    dispatch({ type: types.customer.START_LOADING });
  };
};

export const customerPreloaderFinisher = () => {
  return dispatch => {
    dispatch({ type: types.customer.END_LOADING });
  };
};

export const customerCreator = (businessId, data, count) => {
  return dispatch => {
    dispatch({ type: types.customer.START_POSTING });
    api
      .post(
        apiValues.url.API_URL +
          apiValues.url.BUSINESSES_URL +
          businessId +
          apiValues.url.CUSTOMERS,
        data
      )
      .then(res => {
        switch (res.data.status) {
          case 201:
            dispatch({ type: types.customer.END_POSTING });
            dispatch({
              type: types.customer.CREATE_SINGLE_CUSTOMER,
              payload: {
                data: {
                  count,
                  list: res.data.content,
                },
              },
            });
            dispatch({
              type: types.message.SHOW_MESSAGE,
              payload: {
                data: {
                  type: res.data.status,
                  text: messageText("customer", "create"),
                },
              },
            });
            break;

          case 400:
            dispatch({ type: types.customer.END_POSTING });
            dispatch({
              type: types.message.SHOW_MESSAGE,
              payload: {
                data: {
                  type: res.data.status,
                  text: res.data.message,
                },
              },
            });
            break;

          default:
            break;
        }
      })
      .catch(err => {
        dispatch({ type: types.customer.END_POSTING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "error",
              text: messages.error.CUSTOMER_CREATE_ERROR,
            },
          },
        });
      });
  };
};

export const customerBulkCreator = (
  businessId,
  data,
  count,
  onUploadProgress
) => {
  return dispatch => {
    dispatch({ type: types.customer.START_POSTING });
    api
      .post(
        apiValues.url.API_URL +
          apiValues.url.BUSINESSES_URL +
          businessId +
          apiValues.url.CUSTOMERS_BULK,
        data,
        onUploadProgress
      )
      .then(res => {
        switch (res.data.status) {
          case 200:
            dispatch({ type: types.customer.END_POSTING });
            dispatch({
              type: types.customer.CREATE_CUSTOMERS,
              payload: {
                data: {
                  count: count + res.data.content.length,
                  list: res.data.content,
                },
              },
            });
            break;

          default:
            break;
        }
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: res.data.status,
              text: res.data.message,
            },
          },
        });
      })
      .catch(err => {
        dispatch({ type: types.customer.END_POSTING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "error",
              text: messages.error.CUSTOMER_CREATE_ERROR,
            },
          },
        });
      });
  };
};

export const customerGoogleReviewUpdater = (
  businessId,
  customerId,
  customers,
  customerIndex,
  data
) => {
  return dispatch => {
    dispatch({ type: types.customer.START_UPDATING });
    api
      .patch(
        apiValues.url.API_URL +
          apiValues.url.BUSINESSES_URL +
          businessId +
          apiValues.url.CUSTOMERS +
          customerId +
          "/",
        data
      )
      .then(res => {
        customers.splice(customerIndex, 0, res.data.content);
        dispatch({
          type: types.customer.UPDATE_CUSTOMERS,
          payload: {
            data: {
              list: customers,
            },
          },
        });
        dispatch({ type: types.customer.END_UPDATING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: res.data.status,
              text: messageText("customer google review", "update"),
            },
          },
        });
      });
  };
};

export const customerUpdater = (
  businessId,
  customerId,
  customers,
  customerIndex,
  data,
  closeCallback
) => {
  return dispatch => {
    dispatch({ type: types.customer.START_UPDATING });
    api
      .patch(
        apiValues.url.API_URL +
          apiValues.url.BUSINESSES_URL +
          businessId +
          apiValues.url.CUSTOMERS +
          customerId +
          "/",
        data
      )
      .then(res => {
        customers.splice(customerIndex, 0, res.data.content);
        dispatch({
          type: types.customer.UPDATE_CUSTOMERS,
          payload: {
            data: {
              list: customers,
            },
          },
        });
        dispatch({ type: types.customer.END_UPDATING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: res.data.status,
              text: messageText("customer", "update"),
            },
          },
        });
        if (closeCallback) closeCallback();
      })
      .catch(err => {
        dispatch({ type: types.customer.END_UPDATING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "error",
              text: messages.error.CUSTOMER_UPDATE_ERROR,
            },
          },
        });
        if (closeCallback) closeCallback();
      });
  };
};

export const customerRemover = (businessId, customerId, customers, count) => {
  return dispatch => {
    dispatch({ type: types.customer.START_DELETING });
    api
      .delete(
        apiValues.url.API_URL +
          apiValues.url.BUSINESSES_URL +
          businessId +
          apiValues.url.CUSTOMERS +
          customerId +
          "/"
      )
      .then(res => {
        dispatch({
          type: types.customer.DELETE_SINGLE_CUSTOMER,
          payload: {
            data: {
              list: customers,
              count: count,
            },
          },
        });
        dispatch({ type: types.customer.END_DELETING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: res.status,
              text: messages.success.CUSTOMER_DELETE_SUCCESS,
            },
          },
        });
      })
      .catch(err => {
        dispatch({ type: types.customer.END_DELETING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "error",
              text: messages.error.CUSTOMER_DELETE_ERROR,
            },
          },
        });
      });
  };
};

export const customersRemover = () => {
  return dispatch => {
    dispatch({ type: types.customer.DELETE_CUSTOMERS });
  };
};
