import React from 'react';
import { CircularProgress } from '@material-ui/core';
import styles from './Preloader.module.scss';

const Preloader = (props) => {

    const { fullScreen, float, inWindow, size, height, align, className, transparent, absolute, color } = props;

    const {
        preloader, preloader__spinner, preloader__fixed, preloader__float, preloader__transparent, preloader__inWindow,
        preloader__align__left, preloader__align__right, preloader__absolute } = styles;

    let preloader__classes = [preloader];

    if (className) preloader__classes.push(className);

    if (fullScreen) preloader__classes.push(preloader__fixed);

    if (float) preloader__classes.push(preloader__float);

    if (transparent) preloader__classes.push(preloader__transparent);

    if (absolute) preloader__classes.push(preloader__absolute);

    if (inWindow) preloader__classes.push(preloader__inWindow);

    if (align && align === 'left') preloader__classes.push(preloader__align__left);

    if (align && align === 'right') preloader__classes.push(preloader__align__right);

    return (
        <div className={String(preloader__classes).split(',').join(' ')} style={{ height, minHeight: height, marginTop: 2, color }}>
            <div className={preloader__spinner}>
                <CircularProgress color={color ? 'inherit' : 'primary'} size={size} />
            </div>
        </div>
    )
}

export default Preloader;