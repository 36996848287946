import types from "./types";
import api, { apiValues } from "./api";
import { messages } from "./messages";
import { messageText } from "../helpers/messages";

// This function returns all businesses of the user
// from API
export const businessFetcher = (limit = 6, offset = 0) => {
  return dispatch => {
    if (offset === 0) {
      dispatch({ type: types.business.DELETE_BUSINESSES });
    }
    dispatch({ type: types.business.START_LOADING });
    api
      .get(
        apiValues.url.API_URL +
          apiValues.url.BUSINESSES_URL +
          `?limit=${limit}&offset=${offset}`
      )
      .then(res => {
        dispatch({
          type: types.business.FETCH_BUSINESSES,
          payload: {
            data: {
              limit,
              offset,
              count: res.data.content.count,
              list: res.data.content.results,
            },
          },
        });
        dispatch({ type: types.business.END_LOADING });
      })
      .catch(err => {
        dispatch({ type: types.business.END_LOADING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "error",
              text: messages.error.BUSINESS_FETCH_ERROR,
            },
          },
        });
      });
  };
};

export const currentBusinessFetcher = (currentBusinessId, isChangePlan) => {
  return dispatch => {
    dispatch({ type: types.business.START_LOADING });
    api
      .get(
        apiValues.url.API_URL +
          apiValues.url.BUSINESSES_URL +
          currentBusinessId +
          "/"
      )
      .then(res => {
        switch (res.data.status) {
          case 200:
            dispatch({
              type: types.business.CREATE_CURERNT_BUSINESS_STATE,
              payload: {
                data: res.data.content,
              },
            });
            dispatch({ type: types.business.END_LOADING });
            if (isChangePlan) {
              return api.redirect(`/businesses/${currentBusinessId}/`);
            } else {
              return api.redirect(`/${currentBusinessId}/dashboard/`);
              // If dashboard is ready, should redirect to dashboard
            }
            break;

          case 404:
            dispatch({
              type: types.message.SHOW_MESSAGE,
              payload: {
                data: {
                  type: "error",
                  text: messages.error.BUSINESS_NOT_FOUND,
                },
              },
            });
            api.redirect("/businesses/");
            break;

          default:
            break;
        }
        dispatch({ type: types.business.END_LOADING });
      })
      .catch(err => {
        dispatch({ type: types.business.END_LOADING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "error",
              text: messages.error.BUSINESS_FETCH_ERROR,
            },
          },
        });
      });
  };
};

// This function updates the user profile to API with the given data
export const businessUpdater = (data, businesses, businessIndex, count) => {
  return dispatch => {
    dispatch({ type: types.business.START_UPDATING });
    api
      .patch(
        apiValues.url.API_URL +
          apiValues.url.BUSINESSES_URL +
          data.id +
          apiValues.url.BUSINESS_UPDATE_URL,
        data
      )
      .then(res => {
        businesses.splice(businessIndex, 0, {
          ...data,
          ...res.data.content,
        });
        dispatch({
          type: types.business.UPDATE_BUSINESSES_STATE,
          payload: {
            data: {
              list: businesses,
              count,
            },
          },
        });
        // dispatch({
        //     type: types.business.UPDATE_CURRENT_BUSINESS_STATE,
        //     payload: {
        //         data: res.data.content
        //     }
        // });
        dispatch({ type: types.business.END_UPDATING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: res.data.status,
              text: messageText("business", "update"),
            },
          },
        });
      })
      .catch(err => {
        dispatch({ type: types.business.END_UPDATING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "error",
              text: messages.error.BUSINESS_UPDATE_ERROR,
            },
          },
        });
      });
  };
};

export const businessPlanUpdater = (data, businesses, businessIndex) => {
  return dispatch => {
    dispatch({ type: types.business.START_LOADING });
    api
      .patch(
        apiValues.url.API_URL + apiValues.url.BUSINESSES_URL + data.id + "/",
        data
      )
      .then(res => {
        businesses.splice(businessIndex, 0, res.data.content);
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: res.data.status,
              text: messageText("plan", "update"),
            },
          },
        });
        switch (res.data.status) {
          case 400:
            api.redirect("/businesses/" + data.id + "/");
            break;

          case 402:
            api.redirect("/payment/add/");
            break;

          case 200:
            dispatch({
              type: types.business.UPDATE_CURRENT_BUSINESS_STATE,
              payload: {
                data: {
                  list: businesses,
                  currentBusiness: res.data.content,
                },
              },
            });
            api.redirect("/businesses/");
            break;

          default:
            break;
        }
        dispatch({ type: types.business.END_LOADING });
      })
      .catch(err => {
        dispatch({ type: types.business.END_LOADING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "error",
              text: messages.error.BUSINESS_UPDATE_ERROR,
            },
          },
        });
      });
  };
};

// This function updates newBusiness state of the
// business reducer with given input data.
export const newBusinessStateCreator = (name, value, newBusiness) => {
  return dispatch => {
    dispatch({
      type: types.business.CREATE_NEW_BUSINESS_STATE,
      payload: {
        data: {
          ...newBusiness,
          [name]: value,
        },
      },
    });
  };
};

export const newBusinessStateRemover = newBusiness => {
  return dispatch => {
    dispatch({
      type: types.business.DELETE_NEW_BUSINESS_STATE,
      payload: {
        data: {
          ...newBusiness,
        },
      },
    });
  };
};

export const currentBusinessStateCreator = (currentBusiness = {}) => {
  return dispatch => {
    dispatch({ type: types.ui.START_LOADING });
    dispatch({
      type: types.business.CREATE_CURERNT_BUSINESS_STATE,
      payload: {
        data: {
          ...currentBusiness,
        },
      },
    });
    dispatch({ type: types.ui.END_LOADING });
    if (Object.keys(currentBusiness).length) {
      dispatch({
        type: types.message.SHOW_MESSAGE,
        payload: {
          data: {
            type: "info",
            text: `Your active business is now "${currentBusiness.name}".`,
          },
        },
      });
    }
  };
};

// This function calls API to create a new business
export const businessCreator = data => {
  return dispatch => {
    dispatch({ type: types.business.START_POSTING });
    api
      .post(apiValues.url.API_URL + apiValues.url.BUSINESSES_URL, data)
      .then(res => {
        switch (res.data.status) {
          case 402:
            dispatch({
              type: types.message.SHOW_MESSAGE,
              payload: {
                data: {
                  type: "warning",
                  text: messages.warning.PAYMENT_CARD_ERROR,
                },
              },
            });
            api.redirect("/payment/add/");
            break;

          case 400:
            dispatch({
              type: types.message.SHOW_MESSAGE,
              payload: {
                data: {
                  type: "error",
                  text: res.data.message,
                },
              },
            });
            api.redirect("/businesses/add/");
            break;

          case 201:
            dispatch({
              type: types.business.CREATE_BUSINESS,
              payload: {
                data: res.data.content,
              },
            });
            dispatch({
              type: types.message.SHOW_MESSAGE,
              payload: {
                data: {
                  type: res.data.status,
                  text: messageText("business", "create"),
                },
              },
            });
            api.redirect(`/${res.data.content.id}/dashboard/`);
            break;

          default:
            break;
        }
        dispatch({ type: types.business.END_POSTING });
      })
      .catch(err => {
        dispatch({ type: types.business.END_POSTING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "error",
              text: messages.error.BUSINESS_CREATE_ERROR,
            },
          },
        });
      });
  };
};

export const businessSignouter = () => {
  return dispatch => {
    dispatch({
      type: types.business.SIGNOUT_USER,
    });
  };
};

// This is not completed
// This function calls API to delete a business
export const businessRemover = (businesses, id, name, count) => {
  return dispatch => {
    dispatch({ type: types.business.START_LOADING });
    api
      .delete(apiValues.url.API_URL + apiValues.url.BUSINESSES_URL + id + "/")
      .then(res => {
        dispatch({
          type: types.business.UPDATE_BUSINESSES_STATE,
          payload: {
            data: {
              list: businesses,
              count: count - 1,
            },
          },
        });
        dispatch({ type: types.business.END_LOADING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "success",
              text: messages.success.BUSINESS_DELETE_SUCCESS + `"${name}"`,
            },
          },
        });
      })
      .catch(err => {
        dispatch({ type: types.business.END_LOADING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "error",
              text: messages.error.BUSINESS_DELETE_ERROR,
            },
          },
        });
      });
  };
};

export const businessPreloaderStarter = () => {
  return dispatch => {
    dispatch({ type: types.business.START_LOADING });
  };
};

export const businessPreloaderFinisher = () => {
  return dispatch => {
    dispatch({ type: types.business.END_LOADING });
  };
};
