import React, { Component, Fragment } from 'react';
import { PageHeader, BusinessCard, AddBusinessCard, Preloader, LoadMoreButton } from '../../common';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { businessFetcher, currentBusinessStateCreator } from '../../../actions/businessActions';
import Script from 'react-load-script';
import styles from './businesses.module.scss';
import { apiValues } from '../../../actions/api';

class Businesses extends Component {

    constructor(props) {
        super(props);
        this.state = {
            scriptLoaded: false
        }
        window.addEventListener('scroll', this.handleScroll);
        this.businessCard = React.createRef();
        this.props.currentBusinessStateCreator();
    }

    componentWillReceiveProps = (props) => {
        document.title = `${global.appName} | ${this.props.title}`;
    }

    componentDidMount = () => {
        window.addEventListener('scroll', this.handleScroll);
        this.initialBusinessesHandler();
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScriptCreate = () => {
        this.setState({ scriptLoaded: false })
    }

    handleScriptLoad = () => {
        this.setState({ scriptLoaded: true })
    }

    handleScriptError = () => {
        this.setState({ scriptError: false })
    }

    initialBusinessesHandler = () => {
        if (this.businessCard) {
            const cardHeight = 200;
            const cardNumbers = window.innerHeight / cardHeight;
            const numbersToLoad = cardNumbers.toFixed() * 3 - 1;
            this.props.businessFetcher(numbersToLoad, 0);
        }
    }

    handleScroll = () => {
        if (this.businessCard.current) {
            const bottom = this.businessCard.current.lastChild.getBoundingClientRect().bottom - 30 <= window.innerHeight;
            this.handleRequests(bottom);
        }
    }

    handleRequests = (bottom = true) => {
        const limit = 6;
        const nextOffset = this.props.offset + this.props.limit;
        const remaining = this.props.count - this.props.businesses.length;
        if (bottom && !this.props.isLoading && remaining > 0) {
            this.props.businessFetcher(limit, nextOffset);
        }
    }

    // This function renders all business cards received from API
    renderCards = (businesses) => {

        if (businesses.length) {
            const map = new window.google.maps.Map(document.getElementById("map"), {
                center: { lat: 40.7575285, lng: -73.9884469 }
            });

            let businessesToShow = businesses;

            if (Object.keys(this.props.currentBusiness).length !== 0) {
                businessesToShow = businesses.filter(item => item.id === this.props.currentBusiness.id);
            }

            return businessesToShow.map(business => {
                const {
                    id,
                    name,
                    sender_name,
                    phone,
                    website,
                    contact_name,
                    created_on,
                    plan,
                    trial_is_active,
                    place_id,
                    isCurrentCard
                } = business;

                return (
                    <Grid className={styles.card__grid} item md={4} xs={12} key={created_on + id}>
                        <div ref={this.businessCard}>
                            <BusinessCard
                                id={id}
                                name={name}
                                senderName={sender_name}
                                phone={phone}
                                website={website}
                                contactName={contact_name}
                                createdOn={created_on}
                                planId={plan}
                                trial={trial_is_active}
                                placeId={place_id}
                                map={map}
                                active={this.state.activeCardId === id}
                                isCurrentCard={(id) => this.setState({ activeCardId: id })}
                            // businessRefresher={this.handleRequests}
                            />
                        </div>
                    </Grid>
                )

            });
        }
    }

    renderLoadMoreButton = (businesses, count, isLoading) => {
        if (!isLoading) {
            if (count && count > businesses.length) {
                return (
                    <LoadMoreButton clickHandler={this.handleRequests} />
                )
            }
        }
    }

    render() {

        const { businesses, isLoading, count } = this.props;

        const { scriptLoaded } = this.state;

        return (
            <Fragment>
                <div id="map"></div>
                <Script
                    url={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places&key=${apiValues.keys.GOOGLE_PLACES_KEY}`}
                    onCreate={this.handleScriptCreate}
                    onError={this.handleScriptError}
                    onLoad={this.handleScriptLoad} />
                <Grid container spacing={16} >
                    <Grid item md={12}>
                        <PageHeader
                            title="Business"
                            description="Here you can add, edit or remove your business." />
                    </Grid>
                </Grid>
                <Grid container spacing={16}>
                    <Grid item md={4} xs={12}>
                        <AddBusinessCard />
                    </Grid>
                    {scriptLoaded ? this.renderCards(businesses) : ''}
                    {isLoading ?
                        <Grid item md={businesses.length ? 12 : 4} xs={12}>
                            <Preloader float />
                        </Grid>
                        : ''}
                </Grid>
                <Grid container>
                    {this.renderLoadMoreButton(businesses, count, isLoading)}
                </Grid>
            </Fragment>
        )
    }
}

const mapStateToProps = ({ businesses }) => {
    return {
        currentBusiness: businesses.currentBusiness,
        businesses: businesses.list,
        count: businesses.count,
        isLoading: businesses.isLoading,
        limit: businesses.limit,
        offset: businesses.offset
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        businessFetcher,
        currentBusinessStateCreator
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Businesses);