import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Layout } from '../';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Router } from 'react-router-dom';
import { userFetcher } from '../../../actions/userActions';
import { plansFetcher } from '../../../actions/planActions';
import history from '../../../helpers/history';
import * as Cookies from 'js-cookie';

class App extends Component {

	componentWillMount = () => {
		global.appName = 'Upperly';
		global.isBeta = true;
		this.userChecker();
	}

	userChecker = () => {
		const loginCookie = Cookies.get('upperly_auth');
		if (loginCookie || loginCookie !== undefined) {
			this.props.userFetcher();
		}
	}

	render() {

		// Customize default MaterialUI styles
		const styles = createMuiTheme({
			typography: {
				useNextVariants: true,
				fontFamily: 'Ubuntu',
				fontSize: 12
			},
			palette: {
				primary: {
					light: '#6b65ae',
					main: '#544da0',
					dark: '#463187',
					contrastText: '#fff',
				},
				secondary: {
					light: '#6b65ae',
					main: '#2d79b9',
					dark: '#463187',
					contrastText: '#fff',
				},
			}
		});

		return (
			<MuiThemeProvider theme={styles}>
				<Router exact basename={process.env.PUBLIC_URL + '/'} history={history}>
					<Layout />
				</Router>
			</MuiThemeProvider>
		)
	}
}

const mapStateToProps = ({ user }) => {
	return {
		user
	}
}

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		userFetcher,
		plansFetcher
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
