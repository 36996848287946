import React, { Component, createRef } from 'react';
import { PageHeader, NewBusinessCard, Map, LocalMap, Preloader } from '../../common';
import { Grid, FormGroup, FormControlLabel, Switch, Link } from '@material-ui/core';
import styles from './NewBusiness.module.scss';
import { apiValues } from '../../../actions/api';

class NewBusiness extends Component {

    constructor(props) {
        super(props);
        this.state = {
            localMap: false,
            localMapUse: false,
            focusFunction: null
        }
        this.NewBusinessCardRef = React.createRef();
    }

    componentWillReceiveProps = (props) => {
        document.title = `${global.appName} | ${this.props.title}`;
    }

    componentWillUnmount = () => {
        this.setState({
            localMap: false,
            localMapUse: false
        })
    }

    localMapSwitch = name => event => {
        this.setState({
            [name]: event.target.checked
        }, () => {
            if (!this.state.localMapUse) this.localMapHandler();
        });
    };

    localMapHandler = () => {
        this.setState({
            localMap: !this.state.localMap
        });
    }

    setFormFocus = (focusFunction) => {
        this.setState({ focusFunction })
    }

    render() {

        const {
            newBusiness,
            map,
            map__container,
            checkbox__label,
            checkbox__link,
            checkbox__desc
        } = styles;

        const mapContainer = <div className={map__container} />;

        const { localMap, localMapUse, focusFunction } = this.state;

        return (
            <Grid container spacing={16} className={newBusiness} >
                {localMapUse ? <LocalMap
                    open={localMap}
                    closeAction={() => this.localMapHandler()} /> : ''}
                <Grid item md={4}>
                    <PageHeader
                        title="Add New Business"
                        description={`* Required`} />
                    <Grid>
                        <NewBusinessCard
                            selectable={!localMapUse}
                            closeButtonPath="/businesses" 
                            focusFunction={this.setFormFocus}
                            />
                        <strong>
                            <FormGroup row>
                                <FormControlLabel
                                    className={checkbox__label}
                                    control={
                                        <Switch
                                            checked={localMapUse}
                                            onChange={this.localMapSwitch('localMapUse')}
                                            value="localMap"
                                            color="primary"
                                        />
                                    }
                                    label="Can't find my business" />
                                {localMapUse ?
                                    <Link className={checkbox__link} onClick={this.localMapHandler} >
                                        Open Local Map
                                        </Link>
                                    : ''}
                            </FormGroup>
                        </strong>
                        <p className={checkbox__desc}>
                            Check this if your business is not found on the map.<br />
                        </p>
                        <p className={checkbox__desc}>
                            Copy your business <strong>Place ID</strong>, then paste it into the Place ID input.
                        </p>
                    </Grid>
                </Grid>
                <Grid item md={8} className={map}>
                    <Map
                        isMarkerShown
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places&key=${apiValues.keys.GOOGLE_MAP_KEY}`}
                        loadingElement={<Preloader inWindow />}
                        containerElement={mapContainer}
                        mapElement={mapContainer}
                        lat={35.713250}
                        lng={51.422185}
                        focusFunction={focusFunction}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default NewBusiness;