import React, { Fragment } from 'react';
import { Grid, Card, CardContent, ButtonBase } from '@material-ui/core';
import { Link } from 'react-router-dom';
import LibraryBooksOutlined from '@material-ui/icons/LibraryBooksOutlined';
import EmailOutlined from '@material-ui/icons/EmailOutlined';
import styles from './RequestRow.module.scss';
import { withRouter } from 'react-router-dom';

const RequestRow = (props) => {

    const {
        businessId,
        id,
        name,
        type = 'SINGLE',
        sentMethod,
        feedbackMethod,
        sent,
        result,
        date,
        email,
        agent
    } = props;

    const {
        card,
        card__effect,
        card__icon,
        card__title,
        card__subtitle
    } = styles;

    return (
        <Grid>
            <Link className="containerLink" to={`/${businessId}/requests/${id}/`}>
                <Card className={card}>
                    <ButtonBase className={card__effect} color="primary">
                        <CardContent>
                            <Grid container>
                                <Grid item md={1}>
                                    <span className={card__icon}>
                                        {type === 'CAMPAIGN' ? <LibraryBooksOutlined /> : <EmailOutlined />}
                                    </span>
                                </Grid>
                                <Grid item md={3}>
                                    <span className={card__title}>{name}</span>
                                    <span className={card__subtitle}>{date}</span>
                                    {email ? <span className={card__subtitle}>{email}</span> : ''}
                                </Grid>
                                <Grid item md={2}>
                                    {agent ?
                                        <Fragment>
                                            <span className={card__title}>{`${agent.first_name} ${agent.last_name}`}</span>
                                            <span className={card__subtitle}>Agent</span>
                                        </Fragment>
                                        : null}
                                </Grid>
                                <Grid item md={2}>
                                    <span className={card__title}>{sentMethod}</span>
                                    <span className={card__subtitle}>Channel</span>
                                </Grid>
                                <Grid item md={2}>
                                    <span className={card__title}>{feedbackMethod}</span>
                                    <span className={card__subtitle}>Message Format</span>
                                </Grid>
                                <Grid item md={2}>
                                    <span className={card__title}>
                                        {type === 'CAMPAIGN' ? `${sent}/${result}` : (result ? result : `No Result`)}
                                    </span>
                                    <span className={card__subtitle}>
                                        {type === 'CAMPAIGN' ? `Sent/Result` : `Result`}
                                    </span>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </ButtonBase>
                </Card>
            </Link>
        </Grid>
    )
}

export default withRouter(RequestRow);