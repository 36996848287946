import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import styles from './PageHeader.module.scss';

const Businesses = (props) => {
    
    const {
        title = '',
        description = ''
    } = props;

    const {
        header,
        header__title,
        header__description
    } = styles;

    return(
        <Grid className={header}>
            <Typography variant="h1" className={header__title}>
                {title}
            </Typography>
            {description ?
                <Typography className={header__description}>
                    {description}
                </Typography>
            : ''}
        </Grid>
    )
}

export default Businesses;