import React, { PureComponent } from 'react';
import styles from './StripeInput.module.scss';

class StripeInput extends PureComponent {

	static defaultProps = {
		onFocus: () => { },
		onBlur: () => { },
		onChange: () => { },
	}

	render() {
		const {
			component: Component,
			onFocus,
			onBlur,
			onChange,
		} = this.props;

		const {
			formInput
		} = styles;

		return (
			<Component
				className={formInput}
				onFocus={onFocus}
				onBlur={onBlur}
				onChange={onChange}
				placeholder=""
			/>
		)
	}
}

export default StripeInput;