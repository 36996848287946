import types from '../actions/types';

const initialState = {
    loading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.ui.START_LOADING:
            return {
                loading: true
            };

        case types.ui.END_LOADING:
            return {
                loading: false
            };

        default:
            return state;
    }
}