import types from './types';
import api, { apiValues } from './api';
import { messages } from "./messages";

export const feedbackFetcher = (businessId, requestId, limit = 10, offset = 0) => {
    return dispatch => {
        if (offset === 0) {
            dispatch({ type: types.feedback.DELETE_FEEDBACKS })
        }
        dispatch({ type: types.feedback.START_LOADING });
        api.get(apiValues.url.API_URL + apiValues.url.BUSINESSES_URL + businessId + apiValues.url.REQUESTS + requestId + apiValues.url.FEEDBACKS + `?limit=${limit}&offset=${offset}`)
            .then(res => {
                dispatch({
                    type: types.feedback.FETCH_FEEDBACKS,
                    payload: {
                        data: {
                            limit,
                            offset,
                            count: res.data.content.count,
                            list: res.data.content.results
                        }
                    }
                });
                dispatch({ type: types.feedback.END_LOADING });
            })
            .catch(err => {
                dispatch({ type: types.business.END_LOADING });
                dispatch({
                    type: types.message.SHOW_MESSAGE,
                    payload: {
                        data: {
                            type: 'error',
                            text: messages.error.FEEDBACK_FETCH_ERROR
                        }
                    }
                })
            })
    }
}

export const feedbackSearchFetcher = (businessId = null, requestId = null, limit = 10, offset = 0, searchTerm, clearLastSearch = false) => {
    return dispatch => {
        dispatch({ type: types.feedback.START_LOADING });
        api.get(apiValues.url.API_URL + apiValues.url.BUSINESSES_URL + businessId + apiValues.url.REQUESTS + requestId + apiValues.url.FEEDBACKS + `?search=${searchTerm}&limit=${limit}&offset=${offset}`)
            .then(res => {
                if (clearLastSearch) {
                    dispatch({ type: types.feedback.DELETE_SEARCH_RESULTS });
                }
                dispatch({
                    type: types.feedback.FETCH_SEARCH_RESULTS,
                    payload: {
                        data: {
                            limit,
                            offset,
                            count: res.data.content.count,
                            list: res.data.content.results,
                        }
                    }
                })
                dispatch({ type: types.feedback.END_LOADING });
            })
            .catch(err => {
                dispatch({ type: types.feedback.END_LOADING });
                dispatch({
                    type: types.message.SHOW_MESSAGE,
                    payload: {
                        data: {
                            type: 'warning',
                            text: messages.error.FEEDBACK_FETCH_ERROR
                        }
                    }
                })
            })
    }
}

export const feedbackRemover = () => {
    return dispatch => {
        dispatch({ type: types.feedback.START_LOADING });
        dispatch({ type: types.feedback.DELETE_FEEDBACKS });
        dispatch({ type: types.feedback.END_LOADING });
    }
}

export const feedbackSearchRemover = () => {
    return dispatch => {
        dispatch({ type: types.feedback.START_LOADING });
        dispatch({ type: types.feedback.DELETE_SEARCH_RESULTS });
        dispatch({ type: types.feedback.END_LOADING });
    }
}

export const feedbackPreloaderStarter = () => {
    return dispatch => {
        dispatch({ type: types.feedback.START_LOADING });
    }
}

export const feedbackPreloaderFinisher = () => {
    return dispatch => {
        dispatch({ type: types.feedback.END_LOADING });
    }
}