import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { PageHeader, SearchInput, ContactsTable, Preloader } from '../../common';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { customerFetcher, customerUpdater, customerRemover, customersRemover, customerSearchFetcher, customerSearchRemover, customerPreloaderStarter, customerPreloaderFinisher } from '../../../actions/customerActions';
import styles from './Customers.module.scss';

class Customers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            validSearchTerm: false
        }
        document.title = `${global.appName} | ${this.props.title}`;
    }

    componentWillReceiveProps = (props) => {
        document.title = `${global.appName} | ${this.props.title}`;
    }

    componentDidMount = () => {
        this._isMounted = true;
        this.initialCustomersHandler();
    }

    componentWillUnmount = () => {
        this.setState({
            searchTerm: '',
            validSearchTerm: false
        }, () => this.props.customersRemover());
        this._isMounted = false;
    }

    initialCustomersHandler = () => {
        if (Object.keys(this.props.currentBusiness).length) {
            const limit = 10;
            const businessId = this.props.currentBusiness.id;
            this.props.customerFetcher(businessId, limit, 0);
        }
    }

    initialCustomerSearchHandler = () => {
        if (Object.keys(this.props.currentBusiness).length) {
            const limit = 10;
            const businessId = this.props.currentBusiness.id;
            this.props.customerSearchFetcher(businessId, limit, this.props.searchResultsOffset, this.state.searchTerm, true);
        }
    }

    handleRequests = (businessId, bottom = true) => {
        const limit = 10;
        const nextOffset = this.props.offset + this.props.limit;
        const remaining = this.props.count - this.props.customers.length;
        if (bottom && !this.props.businessesIsLoading && !this.props.customersIsFetching && remaining > 0) {
            this.props.customerFetcher(businessId, limit, nextOffset);
        }
    }

    handleSearchRequests = (businessId, bottom = true, searchTerm) => {
        const limit = 10;
        const nextOffset = this.props.searchResultsOffset + this.props.searchResultsLimit;
        const remaining = this.props.searchResultsCount - this.props.searchResults.length;
        if (bottom && !this.props.businessesIsLoading && !this.props.customersIsFetching && remaining > 0) {
            this.props.customerSearchFetcher(businessId, limit, nextOffset, searchTerm);
        }
    }

    searchHandler = (e, name) => {
        if (e.target.value.length) {
            this.setState({
                [name]: e.target.value,
            }, () => {
                if (this.state.searchTerm.length >= 3) {
                    this.setState({
                        validSearchTerm: true
                    });
                    this.props.customerPreloaderStarter();
                    setTimeout(() => {
                        this.initialCustomerSearchHandler();
                        this.props.customerPreloaderFinisher();
                    }, 3000);
                } else {
                    this.setState({
                        validSearchTerm: false
                    });
                }
            });
        } else {
            this.setState({
                [name]: '',
                validSearchTerm: false
            }, () => setTimeout(() => {
                this.props.customerSearchRemover();
            }, 3000));
        }
    }

    render() {

        const { table, search } = styles;

        const { customers, currentBusiness, searchResults } = this.props;

        const { searchTerm, validSearchTerm } = this.state;

        if (!Object.keys(currentBusiness).length) return <Preloader inWindow />;

        return (
            <Grid container spacing={16} >
                <Grid item md={12}>
                    <PageHeader
                        title="Customer List"
                        description="Here is your list of customers." />
                </Grid>
                <Grid item md={12}>
                    <Grid container className={search}>
                        <Grid item md={5} >
                            <SearchInput
                                events={this.searchHandler}
                                placeholder="Search..."
                                searchTerm={searchTerm} />
                        </Grid>
                    </Grid>
                    <Grid container className={table}>
                        <ContactsTable
                            customers={customers}
                            searchResults={searchResults}
                            currentBusiness={currentBusiness}
                            inSearch={searchTerm.length > 0}
                            validSearchTerm={validSearchTerm}
                            scrollCalculator={(bottom) => {
                                searchTerm.length >= 3 ? this.handleSearchRequests(currentBusiness.id, bottom, searchTerm) : this.handleRequests(currentBusiness.id, bottom)
                            }} />
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = ({ businesses, customers }) => {
    return {
        businessesIsLoading: businesses.isLoading,
        customersIsFetching: customers.isFetching,
        customersIsPosting: customers.isPosting,
        customersIsUpdating: customers.isUpdating,
        customersIsDeleting: customers.isDeleting,
        currentBusiness: businesses.currentBusiness,
        customers: customers.list,
        count: customers.count,
        offset: customers.offset,
        limit: customers.limit,
        searchResults: customers.searchResults.list,
        searchResultsCount: customers.searchResults.count,
        searchResultsOffset: customers.searchResults.offset,
        searchResultsLimit: customers.searchResults.limit,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        customerFetcher,
        customerUpdater,
        customerRemover,
        customersRemover,
        customerSearchFetcher,
        customerSearchRemover,
        customerPreloaderStarter,
        customerPreloaderFinisher
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
