import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withMobileDialog
} from '@material-ui/core';

class Modal extends Component {

  render() {
    const { fullScreen } = this.props;

    const {
      title,
      text,
      secondText,
      confirm,
      cancel,
      open,
      confirmAction,
      cancelAction
    } = this.props;

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {text}
              <br />
              {secondText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelAction} color="primary">
              {cancel}
            </Button>
            <Button onClick={confirmAction} color="primary" autoFocus>
              {confirm}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(Modal);
