import types from '../actions/types';

const initialState = {
    count: 0,
    limit: 6,
    offset: 0,
    isLoading: false,
    isFetching: false,
    isPosting: false,
    isUpdating: false,
    isDeleting: false,
    currentBusiness: {},
    newBusiness: {},
    list: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.business.START_LOADING:
            return {
                ...state,
                isLoading: true
            }

        case types.business.END_LOADING:
            return {
                ...state,
                isLoading: false
            }

        case types.business.START_FETCHING:
            return {
                ...state,
                isFetching: true
            }

        case types.business.END_FETCHING:
            return {
                ...state,
                isFetching: false
            }

        case types.business.START_POSTING:
            return {
                ...state,
                isPosting: true
            }

        case types.business.END_POSTING:
            return {
                ...state,
                isPosting: false
            }

        case types.business.START_UPDATING:
            return {
                ...state,
                isUpdating: true
            }

        case types.business.END_UPDATING:
            return {
                ...state,
                isUpdating: false
            }

        case types.business.START_DELETING:
            return {
                ...state,
                isDeleting: true
            }

        case types.business.END_DELETING:
            return {
                ...state,
                isDeleting: false
            }

        case types.business.DELETE_BUSINESSES:
            return {
                ...state,
                list: initialState.list
            };

        case types.business.FETCH_BUSINESSES:
            return {
                ...state,
                count: action.payload.data.count,
                limit: action.payload.data.limit,
                offset: action.payload.data.offset,
                list: [
                    ...state.list,
                    ...action.payload.data.list
                ]
            }

        case types.business.UPDATE_BUSINESSES_STATE:
            return {
                ...state,
                currentBusiness: {},
                count: action.payload.data.count,
                list: action.payload.data.list
            }

        case types.business.CREATE_NEW_BUSINESS_STATE:
            return {
                ...state,
                currentBusiness: {},
                newBusiness: {
                    ...action.payload.data
                }
            }

        case types.business.CREATE_CURERNT_BUSINESS_STATE:
            return {
                ...state,
                currentBusiness: action.payload.data
            }

        case types.business.UPDATE_CURRENT_BUSINESS_STATE:
            return {
                ...state,
                list: action.payload.data.list,
                currentBusiness: action.payload.data.currentBusiness
            }

        case types.business.DELETE_NEW_BUSINESS_STATE:
            return {
                ...state,
                newBusiness: {
                    ...action.payload.data
                }
            }

        case types.business.CREATE_BUSINESS:
            return {
                ...state,
                newBusiness: initialState.newBusiness,
                currentBusiness: action.payload.data,
                list: [
                    ...state.list,
                    action.payload.data
                ]
            };

        case types.business.SIGNOUT_USER:
            return initialState;

        default:
            return state;
    }
}