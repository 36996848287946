import React, { Component } from 'react';
import { PageHeader, SearchInput, RequestRow, RequestFilter, Preloader } from '../../common';
import { Grid, RootRef, Card, CardContent } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styles from './RequestReports.module.scss';
import { requestFetcher, requestSearchFetcher, requestSearchRemover, requestPreloaderStarter } from '../../../actions/requestActions';

class RequestReports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            sentMethod: [],
            type: [],
            feedbackMethod: [],
            validSearchTerm: false
        }
        document.title = `${global.appName} | ${this.props.title}`;
        window.addEventListener('scroll', this.handleScroll);
        this.requestCard = React.createRef();
    }

    componentWillReceiveProps = (props) => {
        document.title = `${global.appName} | ${this.props.title}`;
    }

    componentDidMount = () => {
        window.addEventListener('scroll', this.handleScroll);
        this.initialRequestsHandler();
    }

    componentWillUnmount = () => {
        this.setState({
            searchTerm: '',
            sentMethod: '',
            type: '',
            feedbackMethod: '',
            validSearchTerm: false
        });
        this.props.requestSearchRemover();
        this._isMounted = false;
        window.removeEventListener('scroll', this.handleScroll);
    }

    initialRequestsHandler = () => {
        if (this.requestCard && Object.keys(this.props.currentBusiness).length) {
            const cardHeight = 75;
            const numbersToLoad = window.innerHeight / cardHeight;
            const businessId = this.props.currentBusiness.id;
            this.props.requestFetcher(businessId, numbersToLoad, 0);
        }
    }

    initialRequestSearchHandler = () => {
        const { searchTerm, sentMethod, type, feedbackMethod } = this.state;
        // console.log('searchTerm, sentMethod, type, feedbackMethod', searchTerm, sentMethod, type, feedbackMethod)
        if (Object.keys(this.props.currentBusiness).length) {
            const limit = 10;
            const businessId = this.props.currentBusiness.id;
            const sentMethodParams = sentMethod.length ? String(sentMethod.map(item => { if (item.length) return `&channel=${item}` })).split(',').join('') : '';
            const typeParams = type.length ? String(type.map(item => { if (item.length) return `&type=${item}` })).split(',').join('') : '';
            const feedbackMethodParams = feedbackMethod.length ? String(feedbackMethod.map(item => { if (item.length) return `&message_format=${item}` })).split(',').join('') : '';
            this.props.requestSearchFetcher(businessId, limit, this.props.searchResultsOffset, searchTerm, true, sentMethodParams, typeParams, feedbackMethodParams);
        }
    }

    handleScroll = () => {
        if (this.requestCard.current) {
            const bottom = this.requestCard.current.lastChild.getBoundingClientRect().bottom - 30 <= window.innerHeight;
            const businessId = this.props.currentBusiness.id;
            const { searchTerm, sentMethod, type, feedbackMethod } = this.state;
            if (this.state.searchTerm.length >= 3) {
                this.handleSearchRequests(businessId, bottom, searchTerm, sentMethod, type, feedbackMethod);
            } else {
                this.handleRequests(businessId, bottom);
            }
        }
    }

    handleRequests = (businessId, bottom = true) => {
        const cardHeight = 75;
        const numbersToLoad = window.innerHeight / cardHeight;
        const nextOffset = this.props.offset + this.props.limit;
        const remaining = this.props.count - this.props.requests.length;
        if (bottom && !this.props.businessesIsLoading && !this.props.requestsIsLoading && remaining > 0) {
            this.props.requestFetcher(businessId, numbersToLoad, nextOffset);
        }
    }

    handleSearchRequests = (businessId, bottom = true, searchTerm, sentMethod, type, feedbackMethod) => {
        const { searchResultsOffset, searchResultsLimit, searchResultsCount, searchResults,
            businessesIsLoading, requestsIsLoading, requestSearchFetcher } = this.props;
        const limit = 10;
        const nextOffset = searchResultsOffset + searchResultsLimit;
        const remaining = searchResultsCount - searchResults.length;
        if (bottom && !businessesIsLoading && !requestsIsLoading && remaining > 0) {
            requestSearchFetcher(businessId, limit, nextOffset, searchTerm);
        }
    }

    searchHandler = (e, name) => {
        let valueToSearch = typeof e.target !== "undefined" ? e.target.value : e;
        let stateToSet = name;
        if (Array.isArray(valueToSearch)) {
            if (valueToSearch.length) {
                valueToSearch.map(stateValue => {
                    if (stateValue === 'SINGLE' || stateValue === 'CAMPAIGN') stateToSet = 'type';
                    if (stateValue === 'SMS' || stateValue === 'EMAIL') stateToSet = 'sentMethod';
                    if (stateValue === 'YES/NO' || stateValue === 'NPS' || stateValue === 'DIRECT') stateToSet = 'feedbackMethod';
                    let restState = this.state[stateToSet];
                    if (this.state[stateToSet].indexOf(stateValue) === -1) {
                        restState = [...restState, stateValue];
                    } else {
                        restState = restState.filter(item => item === stateValue)
                    }
                    this.setState({
                        [stateToSet]: restState
                    }, () => {
                        const { sentMethod, type, feedbackMethod } = this.state;
                        if (sentMethod.length >= 1 || type.length >= 1 || feedbackMethod.length >= 1) {
                            this.setState({
                                validSearchTerm: true
                            }, () => {
                                this.props.requestPreloaderStarter();
                                this.initialRequestSearchHandler();
                            });
                        } else {
                            this.setState({
                                [stateToSet]: [],
                                validSearchTerm: false
                            }, () => {
                                this.props.requestPreloaderStarter();
                                this.initialRequestSearchHandler();
                            });
                        }
                    })
                })
            } else {
                this.setState({
                    sentMethod: [],
                    type: [],
                    feedbackMethod: [],
                    // validSearchTerm: false
                }, () => {
                    this.props.requestPreloaderStarter();
                    this.initialRequestSearchHandler();
                });
            }
        } else {
            if (valueToSearch) {
                this.setState({
                    [stateToSet]: valueToSearch,
                }, () => {
                    const { searchTerm, sentMethod, type, feedbackMethod } = this.state;
                    const isValidSearchTerm = Array.isArray(e) ? (sentMethod.length >= 1 || type.length >= 1 || feedbackMethod.length >= 1) : searchTerm.length >= 3;
                    if (isValidSearchTerm) {
                        this.setState({
                            validSearchTerm: true
                        }, () => {
                            this.props.requestPreloaderStarter();
                            setTimeout(() => {
                                this.initialRequestSearchHandler();
                            }, 3000)
                        });
                    } else {
                        this.setState({
                            validSearchTerm: false
                        });
                    }
                });
            } else {
                // this.props.requestPreloaderStarter();
                // setTimeout(() => {
                //     this.initialRequestSearchHandler();
                // }, 3000);
                this.setState({
                    [stateToSet]: '',
                    validSearchTerm: false
                }, () => setTimeout(() => {
                    this.initialRequestSearchHandler();
                }, 3000))
                // const { searchTerm, sentMethod, type, feedbackMethod } = this.state;
                // if (!searchTerm.length || !sentMethod.length || !type.length || !feedbackMethod.length) {
                // }
            }
        }
    }

    render() {

        const { requests__filter, card, card__title } = styles;

        const { currentBusiness, requests, searchResults, requestsIsLoading } = this.props;

        const { searchTerm, sentMethod, type, feedbackMethod, validSearchTerm } = this.state;

        let requestsToShow = requests;

        if (searchTerm.length > 0 || sentMethod.length > 0 || type.length > 0 || feedbackMethod.length > 0) requestsToShow = searchResults;

        if (!Object.keys(currentBusiness).length) return <Preloader inWindow />;

        return (
            <Grid container spacing={16} >
                <Grid item md={12}>
                    <PageHeader
                        title="Request List"
                        description="" />
                </Grid>
                <Grid item md={4}>
                    <SearchInput
                        events={this.searchHandler}
                        placeholder="Search the request or campaign name..."
                        searchTerm={searchTerm} />
                </Grid>
                <Grid item md={4}>
                    <RequestFilter
                        isLoading={requestsIsLoading}
                        width={requests__filter}
                        label="By request type"
                        list={['SMS', 'EMAIL', 'CAMPAIGN', 'SINGLE', 'YES/NO', 'NPS', 'DIRECT']}
                        multiple={true}
                        searchable={false}
                        events={(values) => this.searchHandler(values)} />
                </Grid>
                <Grid item md={12}>
                    {requestsToShow.map(request => {
                        const { business, id, name, message_format, channel, type, created_on, sent, result, agent } = request;
                        return (
                            <RootRef rootRef={this.requestCard} key={id} >
                                <RequestRow
                                    businessId={business}
                                    id={id}
                                    name={name}
                                    type={type}
                                    sentMethod={channel}
                                    feedbackMethod={message_format}
                                    sent={sent}
                                    result={result}
                                    date={created_on}
                                    agent={agent} />
                            </RootRef>
                        )
                    })}
                    {!requestsIsLoading && !requestsToShow.length ?
                        <Grid>
                            <Card className={card}>
                                <CardContent>
                                    <Grid container>
                                        <Grid item md={12}>
                                            <span className={card__title}>
                                                {searchTerm.length > 0 ? (validSearchTerm ? 'No request found for this search.' : 'Please enter 3 characters at least to start searcing...') : 'You have no requests for this business.'}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid> : null}
                    {requestsIsLoading ? <Preloader float height={75} /> : ''}
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = ({ businesses, requests }) => {
    return {
        businessesIsLoading: businesses.isLoading,
        requestsIsLoading: requests.isLoading,
        currentBusiness: businesses.currentBusiness,
        requests: requests.list,
        count: requests.count,
        offset: requests.offset,
        limit: requests.limit,
        searchResults: requests.searchResults.list,
        searchResultsCount: requests.searchResults.count,
        searchResultsOffset: requests.searchResults.offset,
        searchResultsLimit: requests.searchResults.limit,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        requestFetcher,
        requestSearchFetcher,
        requestSearchRemover,
        requestPreloaderStarter
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestReports);