import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import styles from './Alert.module.scss';

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon,
};

const styles1 = theme => ({
	success: {
		backgroundColor: green[600],
	},
	error: {
		backgroundColor: theme.palette.error.dark,
	},
	info: {
		backgroundColor: theme.palette.secondary.main,
	},
	warning: {
		backgroundColor: amber[700],
	},
	icon: {
		fontSize: 20,
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing.unit,
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	}
});

function MySnackbarContent(props) {
	const { classes, className, message, onClose, variant, ...other } = props;
	const Icon = variantIcon[variant];

	return (
		<SnackbarContent
			className={classNames(classes[variant], className)}
			style={props.style}
			aria-describedby="client-snackbar"
			message={
				<span id="client-snackbar" className={classes.message}>
					<Icon className={classNames(classes.icon, classes.iconVariant)} />
					{message}
				</span>
			}
			action={[
				<IconButton
					key="close"
					aria-label="Close"
					color="inherit"
					className={classes.close}
					onClick={onClose}
				>
					<CloseIcon className={classes.icon} />
				</IconButton>,
			]}
			{...other}
		/>
	);
}

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = theme => ({
	margin: {
		margin: theme.spacing.unit,
	}
});

class Alert extends React.Component {
	state = { open: true }

	handleClose = (event, reason) => {
		if (reason === 'clickaway') return;
		this.setState({ open: false });
	}

	render() {
		const { variant, message } = this.props;

		let statusVariant = variant;

		if (
			variant === 400 ||
			variant === 401 ||
			variant === 402 ||
			variant === 403 ||
			variant === 404 ||
			variant === 405
		) statusVariant = 'error';

		if (
			variant === 200 ||
			variant === 201 ||
			variant === 204
		) statusVariant = 'success';

		const { alert__container, alert__inner, alert__content } = styles;

		return (
			<div className={alert__container}>
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={this.state.open}
					autoHideDuration={3000}
					onClose={this.handleClose}
					className={alert__inner}
				>
					<MySnackbarContentWrapper
						onClose={this.handleClose}
						variant={statusVariant}
						message={message}
						className={alert__content}
					/>
				</Snackbar>
			</div>
		);
	}
}

export default withStyles(styles2)(Alert);
