import React, { Component, Fragment } from "react";
import { Grid, Card, CardContent, IconButton } from "@material-ui/core";
import { FormInput, Preloader } from "../index";
import CheckCircleOutlined from "@material-ui/icons/CheckCircleOutlined";
import CloseOutlined from "@material-ui/icons/CloseOutlined";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  businessFetcher,
  businessUpdater,
} from "../../../actions/businessActions";
import { messageFetcher } from "../../../actions/messageActions";
import styles from "./EditBusinessCard.module.scss";
import has from "lodash/has";

class EditBusinessCard extends Component {
  state = {
    data: {
      id: this.props.id,
      name: this.props.name,
      sender_name: this.props.sender_name,
      phone: this.props.phone,
      website: this.props.website,
      contact_name: this.props.contact_name,
    },
    stateChanged: false,
    hasError: [],
  };

  // This function updates current business reducer with input
  // new data as user enters
  inputHandler = (fieldName, fieldLabel, e, validationType) => {
    const inputValue = e.target ? e.target.value : e;
    this.setState(
      {
        ...this.state,
        data: {
          ...this.state.data,
          [fieldName]: inputValue,
        },
      },
      () => {
        this.setState(
          {
            stateChanged: this.state.data[fieldName] !== this.props[fieldName],
          },
          () => {
            this.inputErrorHandler(
              fieldName,
              fieldLabel,
              inputValue,
              validationType
            );
          }
        );
      }
    );
  };

  inputErrorHandler = (fieldName, fieldLabel, inputValue, validationType) => {
    const { messageFetcher } = this.props;
    const hasError = [];
    validationType.map(validationItem => {
      switch (validationItem.type) {
        case "required":
          if (!inputValue.length) {
            messageFetcher("warning", `${fieldLabel} Could not be empty!`);
            hasError.push({ type: validationItem.type, field: fieldName });
          }
          break;

        case "phone":
          const numberRegex = /[+]?(\d{1,4}(\s|-)?)?(((\(\d{2,4}\)|\d{2,4})(\s|-)?){3}|(\d{5,10}))/;
          if (!numberRegex.test(inputValue)) {
            messageFetcher("warning", `Please enter a valid ${fieldLabel}!`);
            hasError.push({ type: validationItem.type, field: fieldName });
          }
          break;

        case "minLength":
          if (inputValue.length < validationItem.value) {
            messageFetcher(
              "warning",
              `Min characters for ${fieldLabel} is ${validationItem.value}`
            );
            hasError.push({ type: validationItem.type, field: fieldName });
          }
          break;

        case "maxLength":
          if (inputValue.length > validationItem.value) {
            messageFetcher(
              "warning",
              `Max characters for ${fieldLabel} is ${validationItem.value}`
            );
            hasError.push({ type: validationItem.type, field: fieldName });
          }
          break;

        default:
          break;
      }
    });
    const oldHasError = this.state.hasError.filter(
      errorItem => errorItem.field !== fieldName
    );
    this.setState({ hasError: [...oldHasError, ...hasError] });
  };

  // This function fetches businesses from API
  // then closes the edit card by refrencing "closeAction" prop
  closeHandler = () => {
    this.props.closeAction();
  };

  // This function receives all businesses and filter current id
  // then call action creator to patch current business to API
  // then closes the card.
  businessUpdater = (e, id, businesses, count) => {
    e.preventDefault();
    const { hasError } = this.state;
    if (hasError.length === 0) {
      const businessIndex = businesses.findIndex(item => item.id === id);
      const restBusinesses = businesses.filter(item => item.id !== id);
      const business = businesses.filter(item => item.id === id);
      const businessData = { ...business[0], ...this.state.data };
      this.props.businessUpdater(
        businessData,
        restBusinesses,
        businessIndex,
        count
      );
      this.props.closeAction();
    }
  };

  render() {
    const { id, closeButtonPath, businesses, isUpdating, count } = this.props;

    const {
      card,
      card__header,
      card__content,
      card__icon,
      card__icon__save,
      card__icon__disabled,
      card__icon__close,
      card__input,
    } = styles;

    const { stateChanged, hasError } = this.state;

    const { name, sender_name, phone, website, contact_name } = this.state.data;

    return (
      <Card className={card}>
        {!isUpdating ? (
          <form onSubmit={e => this.businessUpdater(e, id, businesses, count)}>
            <CardContent className={card__header}>
              <Grid item>
                {closeButtonPath ? (
                  <Link to={closeButtonPath}>
                    <IconButton className={card__icon}>
                      <CloseOutlined className={card__icon__close} />
                    </IconButton>
                  </Link>
                ) : (
                  <IconButton
                    className={card__icon}
                    onClick={() => this.closeHandler()}
                  >
                    <CloseOutlined className={card__icon__close} />
                  </IconButton>
                )}
                <IconButton
                  type="submit"
                  disabled={
                    hasError.length ? true : !stateChanged ? true : false
                  }
                  className={
                    !hasError.length && stateChanged
                      ? card__icon
                      : String([card__icon, card__icon__disabled]).replace(
                          ",",
                          " "
                        )
                  }
                  onClick={e => this.businessUpdater(e, id, businesses, count)}
                >
                  <CheckCircleOutlined className={card__icon__save} />
                </IconButton>
              </Grid>
            </CardContent>
            <CardContent className={`ripple ${card__content}`}>
              <Grid className={card__input}>
                <FormInput
                  label="Business Name"
                  value={name}
                  validation
                  validated={
                    hasError.find(item => item.field == "name") ? false : true
                  }
                  events={e =>
                    this.inputHandler("name", "Name", e, [{ type: "required" }])
                  }
                />
              </Grid>
              <Grid className={card__input}>
                <FormInput
                  label="Send From"
                  value={sender_name}
                  validation
                  validated={
                    hasError.find(item => item.field == "sender_name")
                      ? false
                      : true
                  }
                  events={e =>
                    this.inputHandler("sender_name", "Sender Name", e, [
                      { type: "maxLength", value: 125 },
                    ])
                  }
                />
              </Grid>
              <Grid className={card__input}>
                <FormInput
                  label="Phone"
                  value={phone}
                  validation
                  validated={
                    hasError.find(item => item.field == "phone") ? false : true
                  }
                  events={e =>
                    this.inputHandler("phone", "Phone Number", e, [
                      { type: "phone" },
                      { type: "minLength", value: 7 },
                      { type: "maxLength", value: 15 },
                    ])
                  }
                />
              </Grid>
              <Grid className={card__input}>
                <FormInput
                  label="Website"
                  value={website}
                  validation
                  validated={
                    hasError.find(item => item.field == "website")
                      ? false
                      : true
                  }
                  events={e =>
                    this.inputHandler("website", "Website", e, [
                      { type: "maxLength", value: 200 },
                    ])
                  }
                />
              </Grid>
              <Grid className={card__input}>
                <FormInput
                  label="Contact Name"
                  value={contact_name}
                  validation
                  validated={
                    hasError.find(item => item.field == "contact_name")
                      ? false
                      : true
                  }
                  events={e =>
                    this.inputHandler("contact_name", "Contact Name", e, [
                      { type: "maxLength", value: 180 },
                    ])
                  }
                />
              </Grid>
            </CardContent>
          </form>
        ) : (
          <Preloader float />
        )}
      </Card>
    );
  }
}

const mapStateToProps = ({ user, businesses }) => {
  return {
    user,
    isUpdating: businesses.isUpdating,
    businesses: businesses.list,
    count: businesses.count,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      businessFetcher,
      businessUpdater,
      messageFetcher,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBusinessCard);
