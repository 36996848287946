import { combineReducers } from 'redux';
import uiReducer from "./uiReducer";
import userReducer from "./userReducer";
import planReducer from "./planReducer";
import businessReducer from "./businessReducer";
import messageReducer from "./messageReducer";
import paymentReducer from './paymentReducer';
import agentReducer from './agentReducer';
import customerReducer from './customerReducer';
import requestReducer from './requestReducer';
import feedbackReducer from './feedbackReducer';

const rootReducer = combineReducers({
    ui: uiReducer,
    user: userReducer,
    plans: planReducer,
    businesses: businessReducer,
    messages: messageReducer,
    payment: paymentReducer,
    agents: agentReducer,
    customers: customerReducer,
    requests: requestReducer,
    feedbacks: feedbackReducer
});

export default rootReducer;