import React from 'react';
import { GoogleLogin } from 'react-google-login';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { userAuth, userFetcher } from '../../../actions/userActions';
import * as Cookies from 'js-cookie';
import { apiValues } from '../../../actions/api';

const Auth = (props) => {

    // This function receives Google response and dispatches
    // google access token to the userAuth action creator
    const googleAuth = async (response) => {
        await Cookies.remove('upperly_auth');
        const loginCookie = Cookies.get('upperly_auth');
        if (!loginCookie) {
            props.userAuth(response.accessToken, props.router);
        }
    }

    const { buttonText } = props;

    return (
        <GoogleLogin
            clientId={apiValues.keys.GOOGLE_CLIENT_ID}
            buttonText={buttonText}
            onSuccess={googleAuth}
            onFailure={googleAuth}
            cookiePolicy={'single_host_origin'}
        />
    )
}

const mapStateToProps = ({ user }) => {
    return {
        user
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        userAuth,
        userFetcher
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Auth));