import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import styles from './UploadProgress.module.scss';

class UploadProgress extends React.Component {
  // state = {
  //   completed: this.props.completed,
  // };

  // componentDidMount() {
  //   this.timer = setInterval(this.progress, 500);
  // }

  // componentWillUnmount() {
  //   clearInterval(this.timer);
  // }

  // progress = () => {
  //   const { completed } = this.state;
  //   if (completed === 100) {
  //     this.setState({ completed: 100 });
  //   } else {
  //     const diff = Math.random() * this.props.step;
  //     this.setState({ completed: Math.min(completed + diff, 100) });
  //   }
  // };

  render() {
    return (
      <div className={styles.progressContainer}>
        <LinearProgress variant="determinate" value={this.props.steps} />
      </div>
    );
  }
}

export default withStyles(styles)(UploadProgress);
