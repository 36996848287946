import React, { Fragment } from 'react';
import styles from './ContactsTable.module.scss';
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Paper, Tooltip, IconButton, Menu, MenuItem, RootRef, FormGroup, FormControlLabel, Switch } from '@material-ui/core';
import { ContactTableEditRow, Preloader, Modal, GoogleReviewSwitch, LoadMoreButton } from '../index';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state/index';
import MoreHorizOutlined from '@material-ui/icons/MoreHorizOutlined';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { customerUpdater, customerRemover, customerGoogleReviewUpdater } from '../../../actions/customerActions';
import { messageFetcher } from '../../../actions/messageActions';
import { renderLoadMoreButton } from '../../../helpers/tableHelpers';

let counter = 0;
function createData(firstName, lastName, mobile, email, hasReview) {
	counter += 1;
	return { id: counter, firstName, lastName, mobile, email, hasReview };
}

function desc(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function stableSort(array, cmp) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
	{ id: 'firstName', numeric: false, label: 'First Name' },
	{ id: 'lastName', numeric: false, label: 'Last Name' },
	// { id: 'mobile', numeric: true, label: 'Mobile' },
	{ id: 'email', numeric: false, label: 'Email' },
	{ id: 'hasReview', numeric: false, label: 'Has Review' },

];

class EnhancedTableHead extends React.Component {
	createSortHandler = property => event => {
		this.props.onRequestSort(event, property);
	};

	render() {
		const { order, orderBy } = this.props;

		const { table__th } = styles;

		return (
			<TableHead>
				<TableRow>
					{rows.map(
						row => (
							<TableCell
								key={row.id}
								align={row.numeric ? 'right' : 'left'}
								padding={row.disablePadding ? 'none' : 'default'}
								sortDirection={orderBy === row.id ? order : false}
								className={table__th}
							>
								<Tooltip
									title="Sort"
									placement={row.numeric ? 'bottom-end' : 'bottom-start'}
									enterDelay={300}
								>
									<TableSortLabel
										active={orderBy === row.id}
										direction={order}
										onClick={this.createSortHandler(row.id)}
									>
										{row.label}
									</TableSortLabel>
								</Tooltip>
							</TableCell>
						),
						this,
					)}
					<TableCell></TableCell>
				</TableRow>
			</TableHead>
		);
	}
}

class ContactsTable extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			currentCustomer: '',
			editable: '',
			deletable: false,
			order: 'asc',
			orderBy: 'amount',
			selected: [],
			page: 0,
			rowsPerPage: 0
		}
		window.addEventListener('scroll', this.handleScroll);
		this.customerRow = React.createRef();
	}

	componentDidMount = () => {
		this._isMounted = true;
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount = () => {
		window.removeEventListener('scroll', this.handleScroll);
		this._isMounted = false;
	}

	handleScroll = () => {
		if (this.customerRow.current) {
			const bottom = this.customerRow.current.lastChild.getBoundingClientRect().bottom - 70 <= window.innerHeight;
			this.props.scrollCalculator(bottom)
		}
	}

	menuActionsHandler = (type = '', popupState = '', currentCustomer = '') => {
		switch (type) {
			case 'edit':
				this.setState({
					editable: currentCustomer.id
				})
				break;

			case 'deletable':
				this.setState({
					currentCustomer,
					deletable: !this.state.deletable
				});
				break;

			default:
				this.setState({
					editable: ''
				});
		}
		if (popupState) popupState.close();
	}

	customerUpdateHandler = async (businessId, customer, customers, data, close) => {
		const customerIndex = customers.indexOf(customer);
		const restCustomers = customers.filter(item => item.id !== customer.id);
		this.props.customerUpdater(businessId, customer.id, restCustomers, customerIndex, data, close);
	}

	customerRemoveHandler = (businessId, customerId, customers) => {
		const restCustomers = customers.filter(item => item.id !== customerId);
		this.setState({
			deletable: !this.state.deletable
		}, () => this.props.customerRemover(businessId, customerId, restCustomers, this.props.count - 1))
	}

	googleReviewHandler = (hasReview, businessId, customer, customers) => {
		setTimeout(() => {
			this.setState({
				currentCustomer: customer
			}, () => {
				const customerIndex = customers.indexOf(customer);
				const restCustomers = customers.filter(item => item.id !== customer.id);
				const data = { google_review: hasReview };
				this.props.customerGoogleReviewUpdater(businessId, customer.id, restCustomers, customerIndex, data)
			});
		}, 0)
	}

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = 'desc';

		if (this.state.orderBy === property && this.state.order === 'desc') {
			order = 'asc';
		}

		this.setState({ order, orderBy });
	};

	handleClick = (event, id) => {
		const { selected } = this.state;
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		this.setState({ selected: newSelected });
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value });
	};

	isSelected = id => this.state.selected.indexOf(id) !== -1;

	render() {

		const { currentCustomer, deletable, order, orderBy, selected, rowsPerPage, page } = this.state;

		const { isLoading, isFetching, isPosting, isUpdating, isDeleting, count, searchResultsCount, customers, searchResults, currentBusiness, inSearch, validSearchTerm, messageFetcher } = this.props;

		const { table, table__row, table__td, table__td__preloader, table__td__empty, table__dropdown__icon, table__dropdown__item } = styles;

		let customersToShow = customers;

		if (inSearch) customersToShow = searchResults;

		const emptyRows = rowsPerPage - Math.min(rowsPerPage, customersToShow.length - page * rowsPerPage);

		return (
			<Paper className={table}>
				<PopupState variant="popover">
					{popupState => (
						<Modal
							open={deletable}
							title="Delete customer"
							text={`Are you sure you want to permanently delete "${currentCustomer.first_name} ${currentCustomer.last_name}"?`}
							secondText="You can't undo this action."
							confirm="Delete Customer"
							cancel="Cancel"
							confirmAction={() => this.customerRemoveHandler(currentBusiness.id, currentCustomer.id, customers)}
							cancelAction={() => this.menuActionsHandler('deletable', popupState)} />
					)}
				</PopupState>
				<div >
					<Table aria-labelledby="tableTitle">
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={this.handleSelectAllClick}
							onRequestSort={this.handleRequestSort}
							rowCount={customersToShow.length}
						/>
						{isLoading ? <TableBody>
							<TableRow className={table__row}>
								<TableCell className={table__td} scope="row" colSpan={5}>
									<Preloader float height="49px" />
								</TableCell>
							</TableRow>
						</TableBody> :
							<TableBody>
								{stableSort(customersToShow, getSorting(order, orderBy))
									// This slice function is for using rowsPerPage state to limit the results
									// I commented for the future
									// .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map(customer => {
										const isSelected = this.isSelected(customer.id);
										return (
											<Fragment key={customer.id}>
												{Number(this.state.editable) !== Number(customer.id) ?
													<RootRef rootRef={this.customerRow}>
														<TableRow
															hover
															onClick={event => this.handleClick(event, customer.id)}
															aria-checked={isSelected}
															tabIndex={-1}
															selected={isSelected}
															className={table__row}>
															<TableCell className={table__td} scope="row">
																{customer.first_name}
															</TableCell>
															<TableCell className={table__td}>
																{customer.last_name}
															</TableCell>
															{/* <TableCell className={table__td}>
														{customer.mobile}
													</TableCell> */}
															<TableCell className={table__td}>
																{customer.email}
															</TableCell>
															<TableCell className={table__td}>
																{isUpdating && currentCustomer.id === customer.id ?
																	<Preloader float size="20px" height="20px" align="left" className={table__td__preloader} /> :
																	<GoogleReviewSwitch hasReview={customer.google_review} events={(hasReview) => this.googleReviewHandler(hasReview, currentBusiness.id, customer, customers)} />}
															</TableCell>
															<TableCell className={table__td}>
																<PopupState variant="popover">
																	{popupState => (
																		<Fragment>
																			<IconButton {...bindTrigger(popupState)} className={table__dropdown__icon}>
																				<MoreHorizOutlined />
																			</IconButton>
																			<Menu {...bindMenu(popupState)}>
																				<MenuItem className={table__dropdown__item} onClick={() => this.menuActionsHandler('edit', popupState, customer)}>
																					Edit
																		</MenuItem>
																				{/* <MenuItem className={table__dropdown__item} onClick={() => this.menuActionsHandler('deletable', popupState, customer)}>
																					Delete
                                                                    </MenuItem> */}
																			</Menu>
																		</Fragment>
																	)}
																</PopupState>
															</TableCell>
														</TableRow>
													</RootRef> :
													(isUpdating ?
														<TableRow className={table__row}>
															<TableCell className={table__td} scope="row" colSpan={5}>
																<Preloader float height="48px" />
															</TableCell>
														</TableRow>
														: <ContactTableEditRow
															first_name={customer.first_name}
															last_name={customer.last_name}
															google_review={customer.google_review}
															email={customer.email}
															messageFetcher={messageFetcher}
															close={() => this.menuActionsHandler()}
															update={(data) => this.customerUpdateHandler(currentBusiness.id, customer, customers, data, this.menuActionsHandler)} />)}
											</Fragment>
										);
									})}
								{isFetching && !isLoading ?
									<TableRow className={table__row}>
										<TableCell className={table__td} scope="row" colSpan={5}>
											<Preloader float height="49px" />
										</TableCell>
									</TableRow> : null}
								{!isFetching && !isLoading && !customersToShow.length ?
									<TableRow className={table__row}>
										<TableCell className={String([table__td, table__td__empty]).split(',').join(' ')} scope="row" colSpan={5}>
											{inSearch ? (validSearchTerm ? 'No customer(s) found for this search.' : 'Please enter 3 characters at least to start searching...') : 'You have no customers added for your business.'}
										</TableCell>
									</TableRow> : null}
								{emptyRows > 0 && (
									<TableRow className={table__row} style={{ height: 49 * emptyRows }}>
										<TableCell colSpan={6} />
									</TableRow>
								)}
								{renderLoadMoreButton(customers, searchResults, count, searchResultsCount, inSearch, validSearchTerm, isFetching, isLoading,
									<TableRow className={table__row}>
										<TableCell className={table__td} scope="row" colSpan={5}>
											<LoadMoreButton clickHandler={this.props.scrollCalculator} isTable={true} />
										</TableCell>
									</TableRow>)}
							</TableBody>}
					</Table>
				</div>
			</Paper>
		);
	}
}

const mapStateToProps = ({ customers }) => {
	return {
		count: customers.count,
		searchResultsCount: customers.searchResults.count,
		isLoading: customers.isLoading,
		isFetching: customers.isFetching,
		isPosting: customers.isPosting,
		isUpdating: customers.isUpdating,
		isDeleting: customers.isDeleting,
	}
}

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		customerUpdater,
		customerRemover,
		customerGoogleReviewUpdater,
		messageFetcher
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactsTable);