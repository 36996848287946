const renderLoadMoreButton = (
    originalArray, searchResultsArray, originalArrayCount, searchResultsCount,
    inSearch, validSearchTerm, isFetching, isLoading, buttonElement
) => {
    let validToShow = false;
    if (!isFetching && !isLoading) {
        if (inSearch && validSearchTerm) {
            validToShow = searchResultsCount > searchResultsArray.length;
        } else if (!inSearch) {
            validToShow = originalArrayCount > originalArray.length;
        }
    }
    if (validToShow) return buttonElement;
}

export {
    renderLoadMoreButton
}