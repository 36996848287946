import React, { Component } from 'react';
import { TableRow, TableCell, IconButton } from '@material-ui/core';
import CheckCircleOutlined from '@material-ui/icons/CheckCircleOutlined';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import { FormInput } from '../index';
import styles from './AgentTabelEditRow.module.scss';

class AgentTabelEditRow extends Component {

    state = {
        data: {
            first_name: this.props.first_name,
            last_name: this.props.last_name,
            email: this.props.email,
        },
        hasError: []
    }

    inputHandler = (fieldName, fieldLabel, e, validationType) => {
        const inputValue = e.target ? e.target.value : e;
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [fieldName]: inputValue
            }
        }, () => this.inputErrorHandler(fieldName, fieldLabel, inputValue, validationType))
    }
    
    inputErrorHandler = (fieldName, fieldLabel, inputValue, validationType) => {
        const { messageFetcher } = this.props;
        const hasError = [];
        validationType.map(validationItem => {
            switch (validationItem.type) {
                case 'required':
                    if(!inputValue.length) {
                        messageFetcher('warning', `${fieldLabel} Could not be empty!`);
                        hasError.push({ type: validationItem.type, field: fieldName });
                    }
                    break;

                    case 'phone':
                        const numberRegex = /[+]?(\d{1,4}(\s|-)?)?(((\(\d{2,4}\)|\d{2,4})(\s|-)?){3}|(\d{5,10}))/;
                            if (!numberRegex.test(inputValue)) {
                                messageFetcher('warning', `Please enter a valid ${fieldLabel}!`);
                                hasError.push({ type: validationItem.type, field: fieldName });
                            }
                        break;

                        case 'email':
                            const emailRegex = /^[a-zA-Z0-9.!#$%&’+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/;
                            if(!emailRegex.test(inputValue)) {
                                hasError.push({ type: validationItem.type, field: fieldName });
                            }
                        break;

                        case 'minLength':
                            if(inputValue.length < validationItem.value) {
                                messageFetcher('warning', `Min characters for ${fieldLabel} is ${validationItem.value}`);
                                hasError.push({ type: validationItem.type, field: fieldName });
                            } 
                            break;

                        case 'maxLength':
                            if(inputValue.length > validationItem.value) {
                                messageFetcher('warning', `Max characters for ${fieldLabel} is ${validationItem.value}`);
                                hasError.push({ type: validationItem.type, field: fieldName });
                            } 
                            break;          
            
                default:
                    break;
            }
        })
        const oldHasError = this.state.hasError.filter(errorItem => errorItem.field !== fieldName)
        this.setState({ hasError: [...oldHasError, ...hasError] })
    }

    updateHandler = (e, update, close) => {
        update(this.state.data);
        close();
    }

    render() {

        const {
            update,
            close,
            messageFetcher
        } = this.props;

        const {
            table__row,
            table__td,
            table__td__icon,
            td__icon__close,
            td__icon__save,
            td__icon__disabled
        } = styles;

        const {
            first_name,
            last_name,
            email
        } = this.state.data;

        const { hasError } = this.state;

        return (
            <TableRow
                hover
                tabIndex={-1}
                className={table__row}>
                <TableCell className={table__td} scope="row">
                    <FormInput
                        label="First Name"
                        value={first_name}
                        validation
                        validated={hasError.find(item => item.field == 'first_name') ? false : true}
                        events={(e) => this.inputHandler('first_name', 'First Name', e, [{ type: 'required' }, { type: 'maxLength', value: 30 }])}/>
                </TableCell>
                <TableCell className={table__td}>
                    <FormInput
                        label="Last Name"
                        value={last_name}
                        validation
                        validated={hasError.find(item => item.field == 'last_name') ? false : true}
                        events={(e) => this.inputHandler('last_name', 'Last Name', e, [{ type: 'required' }, { type: 'maxLength', value: 150 }])} />
                </TableCell>
                <TableCell className={table__td}>
                    <FormInput
                        label="Email"
                        value={email}
                        validation
                        onBlur={() => hasError.find(item => item.field == 'email') ? messageFetcher('warning', `Please enter a valid Email!`) : null}
                        validated={hasError.find(item => item.field == 'email') ? false : true}
                        events={(e) => this.inputHandler('email', 'Email', e, [{ type: 'required' }, { type: 'email' }, { type: 'maxLength', value: 254 }])} />
                </TableCell>
                <TableCell className={table__td}>
                    <IconButton className={table__td__icon} onClick={close}>
                            <CloseOutlined className={td__icon__close} />
                        </IconButton>
                    <IconButton 
                        disabled={(!first_name.length || !last_name.length || !email.length) || hasError.length ? true : false}
                        onClick={(e) => this.updateHandler(e, update, close)}
                        className={(!first_name.length || !last_name.length || !email.length) || hasError.length ? String([table__td__icon, td__icon__disabled]).split(',').join(' ') : table__td__icon}>
                        <CheckCircleOutlined className={td__icon__save} />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    }
}

export default AgentTabelEditRow;
