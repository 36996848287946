// A Wrapper for the <FormControl>, <InputLabel>, <Error> and the Stripe <*Element>.
// Similar to Material UI's <TextField>. Handles focused, empty and error state
// to correctly show the floating label and error messages etc.
import React, { PureComponent } from 'react'
import { FormControl, Input, InputLabel } from '@material-ui/core';
import { StripeInput } from '../index';

class StripeElementWrapper extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            focused: false,
            empty: true,
        }
    }

    handleBlur = () => {
        this.setState({ focused: false })
    }

    handleFocus = () => {
        this.setState({ focused: true })
    }

    handleChange = (changeObj) => {
        this.setState({
            error: changeObj.error,
            empty: changeObj.empty,
        })
    }

    render() {
        const { component, label } = this.props;
        const { focused, empty, error } = this.state;

        return (
            <div>
                <FormControl fullWidth>
                    <InputLabel
                        focused={focused}
                        shrink={focused || !empty}
                        error={!!error}>
                        {label}
                    </InputLabel>
                    <Input
                        fullWidth
                        inputComponent={StripeInput}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        value={this.state.value}
                        onChange={this.handleChange}
                        inputProps={{ component }}
                    />
                </FormControl>
                {error ? this.props.fieldMessage(error.message) : this.props.fieldMessage()}
            </div>
        )
    }
}

export default StripeElementWrapper;