const types = {
    message: {
        SHOW_MESSAGE: 'SHOW_MESSAGE',
        SIGNOUT_USER: 'SIGNOUT_USER'
    },
    ui: {
        START_LOADING: 'START_LOADING',
        END_LOADING: 'END_LOADING'
    },
    user: {
        START_LOADING: 'START_LOADING',
        END_LOADING: 'END_LOADING',
        FETCH_USER: 'FETCH_USER',
        UPDATE_USER_STATE: 'UPDATE_USER_STATE',
        SIGNOUT_USER: 'SIGNOUT_USER'
    },
    plan: {
        START_LOADING: 'START_LOADING',
        END_LOADING: 'END_LOADING',
        FETCH_PLANS: 'FETCH_PLANS',
        SIGNOUT_USER: 'SIGNOUT_USER'
    },
    business: {
        START_LOADING: 'START_LOADING',
        END_LOADING: 'END_LOADING',
        START_FETCHING: 'START_FETCHING',
        END_FETCHING: 'END_FETCHING',
        START_POSTING: 'START_POSTING',
        END_POSTING: 'END_POSTING',
        START_UPDATING: 'START_UPDATING',
        END_UPDATING: 'END_UPDATING',
        START_DELETING: 'START_DELETING',
        END_DELETING: 'END_DELETING',
        DELETE_BUSINESSES: 'DELETE_BUSINESSES',
        FETCH_BUSINESSES: 'FETCH_BUSINESSES',
        CREATE_BUSINESS: 'CREATE_BUSINESS',
        UPDATE_BUSINESSES_STATE: 'UPDATE_BUSINESSES_STATE',
        CREATE_NEW_BUSINESS_STATE: 'CREATE_NEW_BUSINESS_STATE',
        DELETE_NEW_BUSINESS_STATE: 'DELETE_NEW_BUSINESS_STATE',
        CREATE_CURERNT_BUSINESS_STATE: 'CREATE_CURERNT_BUSINESS_STATE',
        UPDATE_CURRENT_BUSINESS_STATE: 'UPDATE_CURRENT_BUSINESS_STATE',
        DELETE_CURRENT_BUSINESS_STATE: 'DELETE_CURRENT_BUSINESS_STATE',
        SIGNOUT_USER: 'SIGNOUT_USER'
    },
    payment: {
        START_LOADING: 'START_LOADING',
        END_LOADING: 'END_LOADING',
        FETCH_PAYMENTS_HISTORY: 'FETCH_PAYMENTS_HISTORY',
        CREATE_PAYMENT_CARD: 'CREATE_PAYMENT_CARD',
        FETCH_PAYMENT_CRAD: 'FETCH_PAYMENT_CRAD',
        UPDATE_PAYMENT_CRAD: 'UPDATE_PAYMENT_CRAD',
        FETCH_SEARCH_RESULTS: 'FETCH_SEARCH_RESULTS',
        DELETE_SEARCH_RESULTS: 'DELETE_SEARCH_RESULTS',
        SIGNOUT_USER: 'SIGNOUT_USER'
    },
    customer: {
        START_LOADING: 'START_LOADING',
        END_LOADING: 'END_LOADING',
        START_FETCHING: 'START_FETCHING',
        END_FETCHING: 'END_FETCHING',
        START_POSTING: 'START_POSTING',
        END_POSTING: 'END_POSTING',
        START_UPDATING: 'START_UPDATING',
        END_UPDATING: 'END_UPDATING',
        START_DELETING: 'START_DELETING',
        END_DELETING: 'END_DELETING',
        FETCH_CUSTOMERS: 'FETCH_CUSTOMERS',
        CREATE_CUSTOMERS: 'CREATE_CUSTOMERS',
        CREATE_SINGLE_CUSTOMER: 'CREATE_SINGLE_CUSTOMER',
        UPDATE_CUSTOMERS: 'UPDATE_CUSTOMERS',
        DELETE_CUSTOMERS: 'DELETE_CUSTOMERS',
        DELETE_SINGLE_CUSTOMER: 'DELETE_SINGLE_CUSTOMER',
        FETCH_SEARCH_RESULTS: 'FETCH_SEARCH_RESULTS',
        DELETE_SEARCH_RESULTS: 'DELETE_SEARCH_RESULTS',
        SIGNOUT_USER: 'SIGNOUT_USER',
        CREATE_CURERNT_BUSINESS_STATE: 'CREATE_CURERNT_BUSINESS_STATE'
    },
    agent: {
        START_LOADING: 'START_LOADING',
        END_LOADING: 'END_LOADING',
        START_FETCHING: 'START_FETCHING',
        END_FETCHING: 'END_FETCHING',
        START_POSTING: 'START_POSTING',
        END_POSTING: 'END_POSTING',
        START_UPDATING: 'START_UPDATING',
        END_UPDATING: 'END_UPDATING',
        START_DELETING: 'START_DELETING',
        END_DELETING: 'END_DELETING',
        FETCH_AGENTS: 'FETCH_AGENTS',
        CREATE_AGENTS: 'CREATE_AGENTS',
        UPDATE_AGENTS: 'UPDATE_AGENTS',
        DELETE_AGENTS: 'DELETE_AGENTS',
        DELETE_SINGLE_AGENT: 'DELETE_SINGLE_AGENT',
        FETCH_SEARCH_RESULTS: 'FETCH_SEARCH_RESULTS',
        DELETE_SEARCH_RESULTS: 'DELETE_SEARCH_RESULTS',
        SIGNOUT_USER: 'SIGNOUT_USER',
        CREATE_CURERNT_BUSINESS_STATE: 'CREATE_CURERNT_BUSINESS_STATE'
    },
    request: {
        START_LOADING: 'START_LOADING',
        END_LOADING: 'END_LOADING',
        DELETE_REQUESTS: 'DELETE_REQUESTS',
        FETCH_REQUESTS: 'FETCH_REQUESTS',
        CREATE_REQUESTS: 'CREATE_REQUESTS',
        FETCH_SEARCH_RESULTS: 'FETCH_SEARCH_RESULTS',
        DELETE_SEARCH_RESULTS: 'DELETE_SEARCH_RESULTS',
        FETCH_SINGLE_REQUEST: 'FETCH_SINGLE_REQUEST',
        SIGNOUT_USER: 'SIGNOUT_USER',
    },
    feedback: {
        START_LOADING: 'START_LOADING',
        END_LOADING: 'END_LOADING',
        DELETE_FEEDBACKS: 'DELETE_FEEDBACKS',
        FETCH_FEEDBACKS: 'FETCH_FEEDBACKS',
        CREATE_FEEDBACKS: 'CREATE_FEEDBACKS',
        FETCH_SEARCH_RESULTS: 'FETCH_SEARCH_RESULTS',
        DELETE_SEARCH_RESULTS: 'DELETE_SEARCH_RESULTS',
        FETCH_SINGLE_FEEDBACK: 'FETCH_SINGLE_FEEDBACK',
        SIGNOUT_USER: 'SIGNOUT_USER',
    }
}

export default types;
