import types from "./types";
import api, { apiValues } from "./api";
import { messages } from "./messages";
import { messageText } from "../helpers/messages";

export const agentFetcher = (businessId, limit = 10, offset = 0) => {
  return dispatch => {
    if (offset === 0) {
      dispatch({ type: types.agent.DELETE_AGENTS });
    }
    dispatch({ type: types.agent.START_FETCHING });
    api
      .get(
        apiValues.url.API_URL +
          apiValues.url.BUSINESSES_URL +
          businessId +
          apiValues.url.AGENTS +
          `?limit=${limit}&offset=${offset}`
      )
      .then(res => {
        dispatch({
          type: types.agent.FETCH_AGENTS,
          payload: {
            data: {
              limit,
              offset,
              count: res.data.content.count,
              list: res.data.content.results,
            },
          },
        });
        dispatch({ type: types.agent.END_FETCHING });
      })
      .catch(err => {
        dispatch({ type: types.agent.END_FETCHING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "error",
              text: messages.error.AGENT_FETCH_ERROR,
            },
          },
        });
      });
  };
};

export const agentSearchFetcher = (
  businessId = null,
  limit = 10,
  offset = 0,
  searchTerm,
  clearLastSearch = false
) => {
  return dispatch => {
    dispatch({ type: types.agent.START_FETCHING });
    api
      .get(
        apiValues.url.API_URL +
          apiValues.url.BUSINESSES_URL +
          businessId +
          apiValues.url.AGENTS +
          `?search=${searchTerm}&limit=${limit}&offset=${offset}`
      )
      .then(res => {
        if (clearLastSearch) {
          dispatch({ type: types.agent.DELETE_SEARCH_RESULTS });
        }
        dispatch({
          type: types.agent.FETCH_SEARCH_RESULTS,
          payload: {
            data: {
              limit,
              offset,
              count: res.data.content.count,
              list: res.data.content.results,
            },
          },
        });
        dispatch({ type: types.agent.END_FETCHING });
      })
      .catch(err => {
        dispatch({ type: types.agent.END_FETCHING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "warning",
              text: messages.error.AGENT_FETCH_ERROR,
            },
          },
        });
      });
  };
};

export const agentSearchRemover = () => {
  return dispatch => {
    dispatch({ type: types.agent.START_LOADING });
    dispatch({ type: types.agent.DELETE_SEARCH_RESULTS });
    dispatch({ type: types.agent.END_LOADING });
  };
};

export const agentPreloaderStarter = () => {
  return dispatch => {
    dispatch({ type: types.agent.START_LOADING });
  };
};

export const agentPreloaderFinisher = () => {
  return dispatch => {
    dispatch({ type: types.agent.END_LOADING });
  };
};

export const agentCreator = (businessId, data, count) => {
  return dispatch => {
    dispatch({ type: types.agent.START_POSTING });
    api
      .post(
        apiValues.url.API_URL +
          apiValues.url.BUSINESSES_URL +
          businessId +
          apiValues.url.AGENTS,
        data
      )
      .then(res => {
        dispatch({
          type: types.agent.CREATE_AGENTS,
          payload: {
            data: {
              count,
              list: res.data.content,
            },
          },
        });
        dispatch({ type: types.agent.END_POSTING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: res.data.status,
              text: messageText("agent", "create"),
            },
          },
        });
      })
      .catch(err => {
        dispatch({ type: types.agent.END_POSTING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "error",
              text: messages.error.AGENT_CREATE_ERROR,
            },
          },
        });
      });
  };
};

export const agentUpdater = (businessId, agentId, agents, agentIndex, data) => {
  return dispatch => {
    dispatch({ type: types.agent.START_UPDATING });
    api
      .patch(
        apiValues.url.API_URL +
          apiValues.url.BUSINESSES_URL +
          businessId +
          apiValues.url.AGENTS +
          agentId +
          "/",
        data
      )
      .then(res => {
        agents.splice(agentIndex, 0, res.data.content);
        dispatch({
          type: types.agent.UPDATE_AGENTS,
          payload: {
            data: {
              list: agents,
            },
          },
        });
        dispatch({ type: types.agent.END_UPDATING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: res.data.status,
              text: messageText("agent", "update"),
            },
          },
        });
      })
      .catch(err => {
        dispatch({ type: types.agent.END_UPDATING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "error",
              text: messages.error.AGENT_UPDATE_ERROR,
            },
          },
        });
      });
  };
};

export const agentRemover = (businessId, agentId, agents, count) => {
  return dispatch => {
    dispatch({ type: types.agent.START_DELETING });
    api
      .delete(
        apiValues.url.API_URL +
          apiValues.url.BUSINESSES_URL +
          businessId +
          apiValues.url.AGENTS +
          agentId +
          "/"
      )
      .then(res => {
        dispatch({
          type: types.agent.DELETE_SINGLE_AGENT,
          payload: {
            data: {
              list: agents,
              count: count,
            },
          },
        });
        dispatch({ type: types.agent.END_DELETING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: res.status,
              text: messages.success.AGENT_DELETE_SUCCESS,
            },
          },
        });
      })
      .catch(err => {
        dispatch({ type: types.agent.END_DELETING });
        dispatch({
          type: types.message.SHOW_MESSAGE,
          payload: {
            data: {
              type: "error",
              text: messages.error.AGENT_DELETE_ERROR,
            },
          },
        });
      });
  };
};

export const agentsRemover = () => {
  return dispatch => {
    dispatch({ type: types.customer.DELETE_AGENTS });
  };
};
