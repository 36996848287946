import React, { Component } from 'react';
import { Grid, Tooltip, FormControlLabel, Radio, Typography, Zoom, Link } from '@material-ui/core';
import styles from './SingleRequestTab.module.scss';
import { ContactListZone, Preloader, ImageModal } from '../../common';
import { RequestSingleContactZone, AgentListZone } from '../../containers';

class SingleRequestTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                name: '',
                channel: 'EMAIL',
                message_format: 'NPS',
                type: 'SINGLE',
                customers: [],
                agent: '',
            },
            csvUpload: 'LIST',
            addContactMode: false,
            npsTemplateModal: false,
            yesNoTemplateModal: false,
            directTemplateModal: false
        }
    }

    componentDidMount = () => {
        this.props.events(this.state.data);
    }

    singleContactFormHandler = (e, name) => {
        this.setState({
            ...this.state,
            [name]: typeof e.target !== "undefined" ? e.target.value : e
        });
    }

    requestRadioHandler = (e, name, events, isData) => {
        if (isData) {
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    [name]: e.target.value
                }
            }, () => events ? events(this.state.data) : null);
        } else {
            this.setState({
                [name]: e.target.value
            });
        }
    }

    listDataHandler = (stateName, data, events) => {
        let valueToSet;
        console.log('data from handler', data)
        switch (stateName) {
            case 'customers':
                valueToSet = { customers: Object.keys(data).length ? [data[0].id] : [] };
                break;

            case 'name':
                valueToSet = { name: Object.keys(data).length ? `${data[0].first_name} ${data[0].last_name}` : '' };
                break;

            case 'agent':
                valueToSet = { agent: Object.keys(data).length ? data[0].id : '' };
                break;

            default:
                break;
        }
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                ...valueToSet
            }
        }, () => events ? events(this.state.data) : null);
    }

    templateModalHandler = (messageFormat) => {
        const { npsTemplateModal, yesNoTemplateModal, directTemplateModal } = this.state;
        switch(messageFormat) {
            case 'npsModal':
                this.setState({ npsTemplateModal: !npsTemplateModal })
                break;

                case 'yesNoModal':
                this.setState({ yesNoTemplateModal: !yesNoTemplateModal })
                break;

                case 'directModal':
                this.setState({ directTemplateModal: !directTemplateModal })
                break;
        }
    }
       

    render() {

        const { tab, tab__preloader, tab__link } = styles;

        const { tabHandler, events, active, isLoading } = this.props;

        const { addContactMode, csvUpload, npsTemplateModal, yesNoTemplateModal, directTemplateModal } = this.state;

        const { name, channel, message_format, agent, first_name, last_name, mobile, email } = this.state.data;

        return (
            <Grid container className={tab}>
                {isLoading ? <Preloader className={tab__preloader} absolute transparent /> : null}
                <Grid container className={styles.requestFieldSection}>
                    <Grid item md={12}>
                        <Typography variant="h4" className={styles.requestFieldTitle}>
                            Please select channel.
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Grid item md={6}>
                            <FormControlLabel
                                value="EMAIL"
                                control={<Radio color="primary" />}
                                label="Email"
                                labelPlacement="end"
                                onClick={(e) => this.requestRadioHandler(e, 'channel', events, true)}
                                checked={channel === 'EMAIL'}
                            />
                            <Typography className={styles.requestFieldHelp}>
                                Will send the request by email
                            </Typography>
                        </Grid>
                        <Grid item md={6}>
                            <Tooltip TransitionComponent={Zoom} placement="top" title="This feature is disabled for now, we will add it in the future.">
                                <FormControlLabel
                                    value="SMS"
                                    control={<Radio color="primary" />}
                                    label="SMS"
                                    labelPlacement="end"
                                    // onClick={(e) => this.requestRadioHandler(e, 'method', events)}
                                    disabled={true}
                                    checked={channel === 'SMS'}
                                />
                            </Tooltip>
                            <Typography className={styles.requestFieldHelp}>
                                Will send the request by SMS
                                </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={styles.requestFieldSection}>
                    <Grid item md={12}>
                        <Typography variant="h4" className={styles.requestFieldTitle}>
                            Please select message format.
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Grid item md={6}>
                            <FormControlLabel
                                value="NPS"
                                control={<Radio color="primary" />}
                                label="NPS"
                                labelPlacement="end"
                                onClick={(e) => this.requestRadioHandler(e, 'message_format', events, true)}
                                checked={message_format === 'NPS'}
                            />
                            <Typography className={styles.requestFieldHelp}>
                                An email to ask the user to choose 1 to 10 for the referral possibility. <br />
                                <Link onClick={() => this.templateModalHandler('npsModal')} className={tab__link}>View example</Link>
                            </Typography>
                            <ImageModal
                                open={npsTemplateModal}
                                title="NPS Email Template Preview"
                                cancelAction={() => this.templateModalHandler('npsModal')}
                                imageUrl={`${process.env.PUBLIC_URL}/static/panel/img/templates/upperly_nps_email_template.png`} />
                        </Grid>
                        <Grid item md={6}>
                            <FormControlLabel
                                value="YES/NO"
                                control={<Radio color="primary" />}
                                label="Yes/No"
                                labelPlacement="end"
                                onClick={(e) => this.requestRadioHandler(e, 'message_format', events, true)}
                                checked={message_format === "YES/NO"}
                            />
                            <Typography className={styles.requestFieldHelp}>
                                An email to ask that user will suggest the business to others or not. <br />
                                <Link onClick={() => this.templateModalHandler('yesNoModal')} className={tab__link}>View example</Link>
                            </Typography>
                            <ImageModal
                                open={yesNoTemplateModal}
                                title="Yes/No Email Template Preview"
                                cancelAction={() => this.templateModalHandler('yesNoModal')}
                                imageUrl={`${process.env.PUBLIC_URL}/static/panel/img/templates/upperly_yesno_email_template.png`} />
                        </Grid>
                        <Grid item md={6}>
                            <FormControlLabel
                                value="DIRECT"
                                control={<Radio color="primary" />}
                                label="Direct"
                                labelPlacement="end"
                                onClick={(e) => this.requestRadioHandler(e, 'message_format', events, true)}
                                checked={message_format === 'DIRECT'}
                            />
                            <Typography className={styles.requestFieldHelp}>
                                An email to ask directly write a review on Google <br />
                                <Link onClick={() => this.templateModalHandler('directModal')} className={tab__link}>View example</Link>
                            </Typography>
                            <ImageModal
                                open={directTemplateModal}
                                title="Direct Email Template Preview"
                                cancelAction={() => this.templateModalHandler('directModal')}
                                imageUrl={`${process.env.PUBLIC_URL}/static/panel/img/templates/upperly_direct_email_template.png`} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={styles.requestFieldSection}>
                    <Grid item md={12}>
                        <Typography variant="h4" className={styles.requestFieldTitle}>
                            Please select customer from the list or enter information to add a new one.
                        </Typography>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item md={6}>
                            <FormControlLabel
                                value="LIST"
                                control={<Radio color="primary" />}
                                label="Choose from previously contacts"
                                labelPlacement="end"
                                onClick={(e) => this.requestRadioHandler(e, 'csvUpload', events, false)}
                                checked={csvUpload === 'LIST'}
                            />
                            <Typography className={styles.requestFieldHelp}>
                                (You can multi check the select box)
                            </Typography>
                            <Grid item md={12}>
                                {addContactMode ?
                                    <RequestSingleContactZone
                                        addContactMode={addContactMode}
                                        addContactHandler={this.singleContactFormHandler}
                                        active={csvUpload === 'LIST'}
                                        firstName={first_name}
                                        lastName={last_name}
                                        mobile={mobile}
                                        email={email}
                                        isAgent={false} /> :
                                    <ContactListZone
                                        activeTab={active}
                                        addContactMode={addContactMode}
                                        addContactHandler={this.singleContactFormHandler}
                                        multiple={false}
                                        active={csvUpload === 'LIST'}
                                        method={channel}
                                        events={async (data) => {
                                            await this.listDataHandler('customers', data, events)
                                            this.listDataHandler('name', data, events)
                                        }} />}
                            </Grid>
                        </Grid>
                        <Grid item md={6}></Grid>
                        <Typography className={styles.requestFieldHelp}>
                            * If you want to add more that one user, from top of the page
                                <Link to="#" onClick={tabHandler}>choose Campaign</Link>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={styles.requestFieldSection} >
                    <Grid item md={12}>
                        <Typography variant="h4" className={styles.requestFieldTitle}>
                            Please choose an agent from the list.
                                </Typography>
                    </Grid>
                    <Grid item md={5}>
                        <AgentListZone
                            multiple={false}
                            events={(data) => this.listDataHandler('agent', data, events)} />
                    </Grid>
                    <Grid item md={7}></Grid>
                    <Typography className={styles.requestFieldHelp}>
                        * If you want to add justone user, from top of the page
                                <Link to="#" onClick={tabHandler}>choose Single Request</Link>
                    </Typography>
                </Grid>
            </Grid>
        )
    }
}

export default SingleRequestTab;
