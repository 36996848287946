import types from '../actions/types';

const initialState = {
    count: 0,
    limit: 10,
    offset: 0,
    isLoading: false,
    currentRequest: {},
    list: [],
    searchResults: {
        count: 0,
        limit: 10,
        offset: 0,
        list: []
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.request.START_LOADING:
            return {
                ...state,
                isLoading: true
            }

        case types.request.END_LOADING:
            return {
                ...state,
                isLoading: false
            }

        case types.request.CREATE_REQUESTS:
            return {
                ...state,
                list: [
                    ...state.list,
                    action.payload.data
                ]
            };

        case types.request.DELETE_REQUESTS:
            return {
                ...state,
                list: initialState.list
            }


        case types.request.FETCH_REQUESTS:
            return {
                ...state,
                count: action.payload.data.count,
                limit: action.payload.data.limit,
                offset: action.payload.data.offset,
                currentRequest: {},
                list: [
                    ...state.list,
                    ...action.payload.data.list
                ]
            }

        case types.request.FETCH_SEARCH_RESULTS:
            return {
                ...state,
                searchResults: {
                    count: action.payload.data.count,
                    limit: action.payload.data.limit,
                    offset: action.payload.data.offset,
                    list: [
                        ...state.searchResults.list,
                        ...action.payload.data.list
                    ]
                }
            }

        case types.request.DELETE_SEARCH_RESULTS:
            return {
                ...state,
                searchResults: initialState.searchResults
            }

        case types.request.FETCH_SINGLE_REQUEST:
            return {
                ...state,
                currentRequest: action.payload.data
            }

        case types.request.SIGNOUT_USER:
            return initialState;

        default:
            return state;
    }
}
