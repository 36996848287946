import React from "react";
import styles from "./RequestTable.module.scss";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Tooltip,
  RootRef,
  Zoom,
} from "@material-ui/core";
import { Preloader } from "../index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CheckCircleOutlined from "@material-ui/icons/CheckCircleOutlined";
import CloseOutlined from "@material-ui/icons/CloseOutlined";
import QueryBuilderOutlined from "@material-ui/icons/QueryBuilderOutlined";
import CloudUpload from "@material-ui/icons/CloudUpload";

let counter = 0;
function createData(name, answer, status) {
  counter += 1;
  return { id: counter, name, answer, status };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: "name", numeric: false, label: "Name" },
  { id: "anwser", numeric: false, label: "Answer" },
  { id: "satisfaction", numeric: false, label: "Status" },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;

    const { table__th } = styles;

    return (
      <TableHead>
        <TableRow>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? "right" : "left"}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
                className={table__th}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

class RequestTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: "calories",
      selected: [],
      page: 0,
      rowsPerPage: 0,
    };
    window.addEventListener("scroll", this.handleScroll);
    this.feedbackRow = React.createRef();
  }

  componentDidMount = () => {
    this._isMounted = true;
    window.addEventListener("scroll", this.handleScroll);
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
    this._isMounted = false;
  };

  handleScroll = () => {
    if (this.feedbackRow.current) {
      const bottom =
        this.feedbackRow.current.lastChild.getBoundingClientRect().bottom -
          70 <=
        window.innerHeight;
      this.props.scrollCalculator(bottom);
    }
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  statusHandler = (status, status_description) => {
    let statusClass;
    let statusIcon;
    switch (status) {
      case "DELIVERED":
        statusClass = styles.table__td__green;
        statusIcon = <CheckCircleOutlined />;
        break;

      case "CUSTOMER_FAILURE":
        statusClass = styles.table__td__red;
        statusIcon = <CloseOutlined />;
        return (
          <span className={statusClass}>
            {statusIcon}
            {status_description}
          </span>
        );

      case "SERVER_FAILURE":
        statusClass = styles.table__td__gray;
        statusIcon = <QueryBuilderOutlined />;
        return (
          <span className={statusClass}>
            {statusIcon}
            Schedule for sending...
          </span>
        );

      case "SENDING":
        statusClass = styles.table__td__blue;
        statusIcon = <CloudUpload />;
        break;

      case "IN_QUEUE":
        statusClass = styles.table__td__blue;
        statusIcon = <QueryBuilderOutlined />;
        break;

      default:
        statusClass = styles.table__td__gray;
    }
    return (
      <span className={statusClass}>
        {statusIcon}
        {String(status)
          .toLowerCase()
          .replace("_", " ")}
      </span>
    );
  };

  render() {
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;

    const {
      isLoading,
      feedbacks,
      searchResults,
      inSearch,
      validSearchTerm,
      sentMethod,
    } = this.props;

    const {
      table,
      table__row,
      table__td,
      table__td__subtitle,
      table__td__icon,
    } = styles;

    let feedbacksToShow = feedbacks;

    if (inSearch) feedbacksToShow = searchResults;

    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, feedbacksToShow.length - page * rowsPerPage);

    return (
      <Paper className={table}>
        <div>
          <Table aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={feedbacksToShow.length}
            />
            <TableBody>
              {stableSort(feedbacksToShow, getSorting(order, orderBy))
                // This slice function is for using rowsPerPage state to limit the results
                // I commented for the future
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(feedback => {
                  const isSelected = this.isSelected(feedback.id);
                  return (
                    <RootRef rootRef={this.feedbackRow} key={feedback.id}>
                      <TableRow
                        hover
                        onClick={event => this.handleClick(event, feedback.id)}
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        selected={isSelected}
                        className={table__row}
                      >
                        <TableCell className={table__td}>
                          {`${feedback.customer.first_name} ${feedback.customer.last_name}`}
                          <span className={table__td__subtitle}>
                            {sentMethod === "EMAIL"
                              ? feedback.customer.email
                              : feedback.customer.mobile}
                          </span>
                        </TableCell>
                        <TableCell className={table__td}>
                          {feedback.is_opened
                            ? feedback.score
                              ? feedback.score
                              : feedback.result
                            : "Not Opened"}
                          {feedback.referred_to_google ? (
                            <Tooltip
                              TransitionComponent={Zoom}
                              placement="top"
                              title="Referred to Google"
                            >
                              <img
                                className={table__td__icon}
                                src={`${process.env.PUBLIC_URL}/static/panel/img/google_logo.svg`}
                                alt="Referred to Google"
                              />
                            </Tooltip>
                          ) : null}
                        </TableCell>
                        <TableCell className={table__td}>
                          {this.statusHandler(
                            feedback.status,
                            feedback.status_description
                          )}
                        </TableCell>
                      </TableRow>
                    </RootRef>
                  );
                })}
              {isLoading ? (
                <TableRow>
                  <TableCell className={table__td} scope="row" colSpan={4}>
                    <Preloader float />
                  </TableCell>
                </TableRow>
              ) : null}
              {!isLoading && !feedbacksToShow.length ? (
                <TableRow>
                  <TableCell className={table__td} scope="row" colSpan={4}>
                    {inSearch
                      ? validSearchTerm
                        ? "No feedback(s) found for this search."
                        : "Please enter 3 characters at least to start searcing..."
                      : "No feedbacks found for this request."}
                  </TableCell>
                </TableRow>
              ) : null}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = ({ feedbacks }) => {
  return {
    isLoading: feedbacks.isLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestTable);
