import React, { Fragment, Component } from 'react';
import { Card, CardContent, Grid, IconButton, Menu, MenuItem, Typography, Link } from '@material-ui/core';
import MoreHorizOutlined from '@material-ui/icons/MoreHorizOutlined';
import StarIcon from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import Rating from 'react-rating';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state/index';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { plansFetcher } from '../../../actions/planActions';
import { businessRemover, currentBusinessFetcher, businessUpdater } from '../../../actions/businessActions';
import styles from './BusinessCard.module.scss';
import { EditBusinessCard, Modal, Preloader } from '../index';
import history from '../../../helpers/history';
import api from '../../../actions/api';

class BusinessCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editable: false,
            deletable: false,
            unsubscribable: false,
            rating: null,
            mounted: false,
        }
    }

    componentWillMount = () => {
        // Fetches all plans from API
        // and show plan information for each business card
        this.props.plansFetcher();
    }

    componentDidMount = () => {
        this.setState({
            mounted: true
        }, () => this.businessRatingFetcher());
    }

    componentWillUnmount = () => {
        this.setState({
            mounted: false
        })
    }

    businessRatingFetcher = () => {
        if (this.state.mounted) {
            const mapService = new window.google.maps.places.PlacesService(this.props.map);
            mapService.getDetails({
                placeId: this.props.placeId
            }, (place, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    this.setState({
                        rating: place.rating
                    })
                }
                if (status === window.google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT) {
                    this.setState({
                        rating: 0
                    })
                }
            });

            // return api.getUnauth(`https://maps.googleapis.com/maps/api/place/details/json?placeid=${this.props.placeId}&fields=rating&key=AIzaSyBL5YIn4Rk_RRoru_rWOWYiQWWaIRgu0JY`)
            //     .then(res => {
            //         console.log(res)
            //         this.setState({
            //             rating: res.data.result.rating
            //         })
            //     })
            //     .catch(err => {
            //         console.log(err)
            //     })

        }
    }

    businessSelector = (id) => {
        this.props.currentBusinessFetcher(id);
        history.push(`/${id}/dashboard/`);
    }

    // This function handles editBusinessCard close button
    // with toggle the "editable" state
    editToggler = () => {
        this.setState({
            editable: !this.state.editable
        })
    }

    businessUnsubscriber = (id, businesses, count) => {
        this.setState({
            unsubscribable: !this.state.unsubscribable,
        }, () => this.props.isCurrentCard(id));
        const businessIndex = businesses.findIndex(item => item.id === id);
        const restBusinesses = businesses.filter(item => item.id !== id);
        const business = businesses.filter(item => item.id === id);
        const businessData = { ...business[0], is_subscribed: false };
        this.props.businessUpdater(businessData, restBusinesses, businessIndex, count);
    }

    // This function handles the functionality of the
    // card dropdown items
    menuActionsHandler = (type, popupState) => {
        const { id } = this.props;
        switch (type) {
            case 'edit':
                this.setState({
                    editable: true
                }, this.props.isCurrentCard(id))
                break;

            case 'deletable':
                this.setState({
                    deletable: !this.state.deletable
                });
                break;

            case 'changePlan':
                history.push(`/businesses/${id}/`);
                this.props.currentBusinessFetcher(id, true);
                break;

            case 'unsubscribable':
                this.setState({
                    unsubscribable: !this.state.unsubscribable,
                }, () => this.props.isCurrentCard(id));
                break;

            default:
                return null;
        }
        if (popupState) popupState.close();
    }

    removeBusinessHandler = (businesses, id, name) => {
        const rest = businesses.filter(item => item.id !== id);
        this.props.businessRemover(rest, id, name, this.props.count);
        this.menuActionsHandler('deletable');
        // this.props.businessRefresher();
    }

    checkRatingIsInt = (num) => {
        return Number(num) === num && num % 1 === 0;
    }

    render() {

        const { id, name, senderName, phone, website, contactName, createdOn, planId,
            placeId, trial, isUpdating, businesses, plans, active, count } = this.props;

        const { card, card__header, card__header__text, card__badge, card__title, card__menu,
            card__menu__icon, card__dropdown__item, card__rating__number, card__rating__null,
            card__rating__stars, card__description, card__paragraph, card__button, card__button__disabled,
            card__button__active, card__rating__container, card__rating__deactive } = styles;

        const planArr = Array.isArray(plans) ? plans.filter(item => item.id === planId) : '';

        const plan = planArr[0];

        const {
            editable,
            deletable,
            unsubscribable,
            rating
        } = this.state;

        const card__buttonClasses = [card__button];

        if (rating === null) card__buttonClasses.push(card__button__disabled);

        if (rating !== null) card__buttonClasses.push(card__button__active);

        // Checks if "editable" state is true
        // then renders EditBusinessCard with card props
        if (editable) return (
            <Fragment>
                <div className={styles.card__overlay}></div>
                <EditBusinessCard
                    id={id}
                    name={name}
                    sender_name={senderName}
                    phone={phone}
                    website={website}
                    contact_name={contactName}
                    created_on={createdOn}
                    place_id={placeId}
                    trial={trial}
                    closeAction={this.editToggler}
                />
            </Fragment>
        );

        const createdTime = String(createdOn).split(/([0-9]{2}:[0-9]{2})/g);

        return (
            <Card className={card}>
                <Modal
                    open={deletable}
                    title="Business Deletion"
                    text="Deleting a business means you can not bring it back! Are you sure you want to delete this business?"
                    confirm="Yes, Delete Business"
                    cancel="No, Get back"
                    confirmAction={() => this.removeBusinessHandler(businesses, id, name)}
                    cancelAction={() => this.menuActionsHandler('deletable')} />
                <Modal
                    open={unsubscribable}
                    title="Business Unsubscribe"
                    text="Are you sure you want to delete unsubscribe this business?"
                    confirm="Yes, Unsubscribe"
                    cancel="No, Get back"
                    confirmAction={() => this.businessUnsubscriber(id, businesses, count)}
                    cancelAction={() => this.menuActionsHandler('unsubscribable')} />
                {isUpdating && active ?
                    <Preloader height="160px" /> :
                    <Fragment>
                        <CardContent className={card__header}>
                            <Grid item>
                                <Grid>
                                    <div className={card__header__text}>
                                        <div className={card__title}>
                                            <Link
                                                className={String(card__buttonClasses.toString().replace(',', ' '))}
                                                onClick={() => this.businessSelector(id)}>
                                                {name ? (name.split('').length > 25 ? `${name.substr(0, 20)}...` : name) : 'No Name'}
                                            </Link>
                                            {plan ?
                                                <Typography className={card__badge}>
                                                    {trial ? <span>Trial</span> : (
                                                        Number(plan.price) > 0 ? <span>Premium</span> : <span>Basic</span>
                                                    )}
                                                </Typography>
                                                : ''}
                                        </div>
                                    </div>
                                    <div className={card__menu}>
                                        <PopupState variant="popover">
                                            {popupState => (
                                                <Fragment>
                                                    <IconButton {...bindTrigger(popupState)} className={card__menu__icon}>
                                                        <MoreHorizOutlined />
                                                    </IconButton>
                                                    <Menu {...bindMenu(popupState)}>
                                                        <MenuItem className={card__dropdown__item} onClick={() => this.menuActionsHandler('edit', popupState)}>
                                                            Edit
                                                </MenuItem>
                                                        <MenuItem className={card__dropdown__item} onClick={() => this.menuActionsHandler('changePlan', popupState)}>
                                                            Change Plan
                                                </MenuItem>
                                                        {plan && Number(plan.price) > 0 ?
                                                            <MenuItem className={card__dropdown__item} onClick={() => this.menuActionsHandler('unsubscribable', popupState)}>
                                                                Unsubscribe
                                                </MenuItem>
                                                            : ''}
                                                        <MenuItem className={card__dropdown__item} onClick={() => this.menuActionsHandler('deletable', popupState)}>
                                                            Delete
                                                </MenuItem>
                                                    </Menu>
                                                </Fragment>
                                            )}
                                        </PopupState>
                                    </div>
                                </Grid>
                                {rating !== null ? (rating ?
                                    <Grid className={card__rating__container}>
                                        <Typography className={card__rating__number}>
                                            {this.checkRatingIsInt(rating) ? `${rating}.0` : rating}
                                        </Typography>
                                        <Rating
                                            emptySymbol={<StarBorder className={card__rating__stars} />}
                                            fullSymbol={<StarIcon className={card__rating__stars} />}
                                            fractions={rating}
                                            initialRating={rating}
                                            readonly />
                                    </Grid> :
                                    <Grid className={card__rating__container}>
                                        <Typography className={card__rating__number}>
                                            <span className={card__rating__null}>No Rating</span>
                                        </Typography>
                                    </Grid>) :
                                    <Grid className={card__rating__container}>
                                        <div className={card__rating__number}>
                                            <Preloader float size="15px" />
                                        </div>
                                        <Rating
                                            emptySymbol={<StarBorder className={String([card__rating__stars, card__rating__deactive]).replace(',', ' ')} />}
                                            fullSymbol={<StarIcon className={String([card__rating__stars, card__rating__deactive]).replace(',', ' ')} />}
                                            fractions={0}
                                            initialRating={0}
                                            readonly />
                                    </Grid>}
                            </Grid>
                        </CardContent>
                        <CardContent className={card__description}>
                            <Typography className={card__paragraph}>
                                <strong>Send from: </strong>
                                {senderName}
                            </Typography>
                            <Typography className={card__paragraph}>
                                <strong>Created on: </strong>
                                {createdTime.length ? createdTime[0] : createdOn}
                            </Typography>
                        </CardContent>
                    </Fragment>}
            </Card>
        )
    }
}

const mapStateToProps = ({ businesses, plans }) => {
    return {
        count: businesses.count,
        isUpdating: businesses.isUpdating,
        businesses: businesses.list,
        plans: plans.list
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        plansFetcher,
        businessUpdater,
        businessRemover,
        currentBusinessFetcher
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessCard);