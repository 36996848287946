
import React, { Component } from 'react';
import {
    Paper,
    Typography,
    FormControl,
    InputLabel,
    Input,
    Select, 
    MenuItem,
    Checkbox,
    ListItemText,
    TextField
} from '@material-ui/core';
import { UploadProgress, FormInput } from '../index';
import styles from './SearchableFilter.module.scss';
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';

class SearchableFilter extends Component {

    state = {
        contacts: [],
        names: [
            {
                id: '01',
                name: 'Oliver Hansen',
                email: 'oliver@gmail.com'
            },
            {
                id: '02',
                name: 'Van Henry',
                email: 'van@gmail.com'
            },
            {
                id: '03',
                name: 'April Tucker',
                email: 'april@gmail.com'
            },
            {
                id: '04',
                name: 'Ralph Hubbard',
                email: 'ralph@gmail.com'
            },
            {
                id: '05',
                name: 'Omar Alexander',
                email: 'omar@gmail.com'
            },
            {
                id: '06',
                name: 'Carlos Abbott',
                email: 'carlos@gmail.com'
            }
        ]
    }

    handleChange = e => {
        if (e.target.value.includes("selectAll")) {
            this.setState({
                contacts: this.state.names
            })
        } else {
            this.setState({
                contacts: e.target.value
            });
        }
    };

    render() {

        const { contacts, names } = this.state;

        const { active, label, width } = this.props;

        return (
            <div className={styles.listZoneBase}>
                <div className={styles.listZone}>
                    <FormControl className={width || styles.contactListformControl}>
                        <InputLabel htmlFor="select-multiple-checkbox">{label || 'Select from menu'}</InputLabel>
                        <Select
                            multiple
                            value={this.state.contacts}
                            onChange={this.handleChange}
                            input={<Input id="select-multiple-checkbox" />}
                            renderValue={selected => selected.map(item => item.name).join(', ')} >
                            <MenuItem value="" className={styles.contactListformControlInput}>
                                <FormInput
                                // value={mobile}
                                label="Search..."
                                events={(e) => this.inputHandler(e, 'mobile') }/>
                            </MenuItem>
                            <MenuItem value="selectAll">
                                <Checkbox className={styles.ContactListCheckbox} checked={contacts === names} />
                                <div className={styles.contactRow}>
                                    <ListItemText primary="Select all Contacts" className={styles.contactName} />
                                </div>
                            </MenuItem>
                            {names.map(item => (
                                <MenuItem key={item.id} value={item}>
                                    <Checkbox className={styles.ContactListCheckbox} checked={contacts.indexOf(item) !== -1} />
                                    <div className={styles.contactRow}>
                                        <ListItemText primary={item.name} className={styles.contactName} />
                                        <ListItemText primary={item.email} className={styles.contactEmail} />
                                    </div>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
        );
    }
}

export default SearchableFilter;