
import React, { Component } from 'react';
import { FormControl, InputLabel, Input, Select, MenuItem, Checkbox, ListItemText } from '@material-ui/core';
import { FormInput } from '../index';
import styles from './TimeSearchableFilter.module.scss';
import timezoneObj from '../../../data/timezone.js';

class TimeSearchableFilter extends Component {

    state = {
        searchValue: '',
        matched: '',
        contacts: '',
        names: Object.keys(timezoneObj).slice(0, 19),
        bottomIndex: 20
    }

    itemChecker = (item) => {
        this.setState({
            contacts: item
        });
    }

    inputHandler = (e) => {
        this.setState({
            searchValue: e.target.value,
            bottomIndex: 20
        }, () => this.lazyLoad());
    }

    lazyLoad = (number = 20) => {
        const matched = Object.keys(timezoneObj).filter(item => {
            //const searchArr = e.target.value.toLowerCase().split(' ');
            if (item.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1) {
                return item;
            }
        });
        this.setState({
            names: matched.slice(0, number - 1),
            bottomIndex: number
        })
    }

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop <= 3 * Number(e.target.clientHeight);
        if (bottom) {
            this.lazyLoad(this.state.bottomIndex + 20);
        }
    }

    render() {

        const { contacts, names, searchValue, matched } = this.state;
        const { active, label, width, value, events } = this.props;

        let itemsToShow = names;

        if (matched.length) {
            itemsToShow = matched;
        }

        return (
            <div className={styles.listZoneBase}>
                <div className={styles.listZone}>
                    <FormControl className={width || styles.contactListformControl}>
                        <InputLabel htmlFor="select-multiple-checkbox">{label || 'Select from menu'}</InputLabel>
                        <Select
                            onScroll={this.handleScroll}
                            multiple={false}
                            value={this.state.contacts || value || ''}
                            onChange={events}
                            onBlur={() => this.setState({
                                names: Object.keys(timezoneObj).slice(0, 19),
                                bottomIndex: 20
                            })}
                            input={<Input id="select-multiple-checkbox" />}
                            renderValue={selected => selected} >
                            <MenuItem value="" className={styles.contactListformControlInput}>
                                <FormInput
                                    label="Search..."
                                    value={searchValue}
                                    events={(e) => this.inputHandler(e)} />
                            </MenuItem>
                            {itemsToShow.map((item, i) => {
                                return (
                                    <MenuItem key={item + i} value={item}>
                                        <Checkbox className={styles.ContactListCheckbox} onClick={() => this.itemChecker(item)} checked={contacts.indexOf(item) !== -1} />
                                        <div className={styles.contactRow} onClick={() => this.itemChecker(item)}>
                                            <ListItemText primary={item} className={styles.contactName} />
                                        </div>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </div>
            </div>
        );
    }
}

export default TimeSearchableFilter;
