import React, { Component } from 'react';
import { PageHeader, SingleContactZone, ContactDropZone, Preloader } from '../../common';
import { Grid, Card, CardContent, FormControlLabel, Typography, Radio, Button, Tooltip, Zoom, Link } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { customerCreator, customerBulkCreator } from "../../../actions/customerActions";
import { messageFetcher } from '../../../actions/messageActions';
import styles from './NewContact.module.scss';

class NewContact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            singleContact: {
                first_name: '',
                last_name: '',
                mobile: '',
                email: '',
            },
            csvContacts: [],
            contactMode: 'SINGLE',
            uploadSteps: 0,
            hasError: []
        }
        document.title = `${global.appName} | ${this.props.title}`;
    }

    componentWillReceiveProps = (props) => {
        document.title = `${global.appName} | ${this.props.title}`;
    }

    contactHandler = (e) => {
        this.setState({
            contactMode: e.target.value
        })
    }

    tabHandler = (event, tabValue) => {
        this.setState({
            tabValue
        })
    }

    inputHandler = (fieldName, fieldLabel, e, validationType) => {
        const inputValue = e.target ? e.target.value : e;
        this.setState({
            ...this.state,
            singleContact: {
                ...this.state.singleContact,
                [fieldName]: inputValue
            }
        }, () => this.inputErrorHandler(fieldName, fieldLabel, inputValue, validationType))
    }

    inputErrorHandler = (fieldName, fieldLabel, inputValue, validationType) => {
        const { messageFetcher } = this.props;
        const hasError = [];
        validationType.map(validationItem => {
            switch (validationItem.type) {
                case 'required':
                    if(!inputValue.length) {
                        messageFetcher('warning', `${fieldLabel} Could not be empty!`);
                        hasError.push({ type: validationItem.type, field: fieldName });
                    }
                    break;

                    case 'phone':
                        const numberRegex = /[+]?(\d{1,4}(\s|-)?)?(((\(\d{2,4}\)|\d{2,4})(\s|-)?){3}|(\d{5,10}))/;
                            if (!numberRegex.test(inputValue)) {
                                messageFetcher('warning', `Please enter a valid ${fieldLabel}!`);
                                hasError.push({ type: validationItem.type, field: fieldName });
                            }
                        break;

                        case 'email':
                            const emailRegex = /^[a-zA-Z0-9.!#$%&’+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/;
                            if(!emailRegex.test(inputValue)) {
                                hasError.push({ type: validationItem.type, field: fieldName });
                            }
                        break;

                        case 'minLength':
                            if(inputValue.length < validationItem.value) {
                                messageFetcher('warning', `Min characters for ${fieldLabel} is ${validationItem.value}`);
                                hasError.push({ type: validationItem.type, field: fieldName });
                            } 
                            break;

                        case 'maxLength':
                            if(inputValue.length > validationItem.value) {
                                messageFetcher('warning', `Max characters for ${fieldLabel} is ${validationItem.value}`);
                                hasError.push({ type: validationItem.type, field: fieldName });
                            } 
                            break;          
            
                default:
                    break;
            }
        })
        const oldHasError = this.state.hasError.filter(errorItem => errorItem.field !== fieldName)
        this.setState({ hasError: [...oldHasError, ...hasError] })
    }

    customerCreatorHandler = (currentBusiness) => {
        if (this.state.contactMode === 'SINGLE') {
            const {
                first_name,
                last_name,
                mobile,
                email
            } = this.state.singleContact;

            const data = { first_name, last_name, mobile, email };
            this.setState({
                ...this.state,
                singleContact: {
                    first_name: '',
                    last_name: '',
                    mobile: '',
                    email: ''
                }
            }, () => this.props.customerCreator(currentBusiness.id, data, this.props.count + 1));
        } else {
            const data = this.state.csvContacts;
            this.setState({
                ...this.state,
                csvContacts: []
            }, () => this.props.customerBulkCreator(currentBusiness.id, data, this.props.count, this.onUploadProgress));
        }
    }

    onUploadProgress = (progress) => {
        this.setState({
            ...this.state,
            uploadSteps: Math.round((progress.loaded * 100) / progress.total)
        });
    }

    render() {

        const { section__card, section__submit, requestField__help } = styles;

        const { first_name, last_name, mobile, email } = this.state.singleContact;

        const { contactMode, uploadSteps, hasError, singleContact } = this.state;

        const { currentBusiness, isLoading, isPosting } = this.props;

        if (!Object.keys(currentBusiness).length) return <Preloader inWindow />;

        return (
            <Grid container spacing={16}>
                <Grid item md={12}>
                    <PageHeader
                        title="Add New Customer"
                        description="Please enter the information of the customer on the left or upload a CSV file on the right to add new customer(s)." />
                </Grid>
                <Grid item md={12}>
                    <Card className={section__card}>
                        <CardContent>
                            <Grid container spacing={8}>
                                <Grid item md={6}>
                                    <FormControlLabel
                                        value="SINGLE"
                                        control={<Radio color="primary" />}
                                        label="Single customer add"
                                        labelPlacement="end"
                                        onClick={this.contactHandler}
                                        checked={contactMode === 'SINGLE'} />
                                    <Typography className={requestField__help}>
                                        You can multi check the select box
                                    </Typography>
                                    <Grid item md={12}>
                                        <SingleContactZone
                                            isLoading={isPosting}
                                            active={contactMode === 'SINGLE'}
                                            action={(fieldName, fieldLabel, e, validationType) => this.inputHandler(fieldName, fieldLabel, e, validationType)}
                                            hasError={hasError}
                                            firstName={first_name}
                                            lastName={last_name}
                                            mobile={mobile}
                                            email={email}
                                            messageFetcher={this.props.messageFetcher}
                                            isAgent={false} />
                                    </Grid>
                                </Grid>
                                <Grid item md={6}>
                                    <FormControlLabel
                                        value="CSV"
                                        control={<Radio color="primary" />}
                                        label="Upload CSV File"
                                        labelPlacement="end"
                                        onClick={this.contactHandler}
                                        checked={contactMode === 'CSV'} />
                                    <Typography className={requestField__help}>
                                    The CSV file should be exactly like
                                    <Link href={`${process.env.PUBLIC_URL}/static/panel/files/templates/csv-example-template.csv`}>Download example</Link>.
                                    </Typography>
                                    <Grid item md={12}>
                                        <ContactDropZone
                                            uploadSteps={uploadSteps}
                                            confirmable={true}
                                            active={contactMode === 'CSV'}
                                            events={(csvContacts) => this.setState({ csvContacts })} />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Button
                                        className={section__submit}
                                        variant="contained"
                                        color="primary"
                                        disabled={(!first_name.length || !last_name.length || !email.length) || hasError.length ? true : false}
                                        onClick={() => this.customerCreatorHandler(currentBusiness)}>
                                        Add Customer
                                    </Button>
                                </Grid>
                                <Typography className={requestField__help}>
                                    * You can send review requests by create a
                                    <Link to="#" onClick={this.tabHandler}>New Request or Campaign</Link>
                                </Typography>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        )

    }
}

const mapStateToProps = ({ businesses, customers }) => {
    return {
        currentBusiness: businesses.currentBusiness,
        count: customers.count,
        isLoading: customers.isLoading,
        isPosting: customers.isPosting
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        customerCreator,
        customerBulkCreator,
        messageFetcher
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(NewContact);